import { Box, Divider, Modal, TextField, Typography } from "@mui/material";
//import { API } from "aws-amplify";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";
import { useState } from "react";
import { toast } from "react-toastify";
import { formatDistanceToNow } from 'date-fns';
import { Check } from "@mui/icons-material";
import axios from "axios";


const SessionDetails = ({ user, fileData, quickRecap, setQuickRecap, setCallActions, actionsArray, labels, summaries, clientName, coachName, clientShared }) => {
    const [updatedAction, setUpdatedAction] = useState({});
    const [initialAction, setInitialAction] = useState({});
    const [updatedQuickRecap, setUpdatedQuickRecap] = useState({});
    const [openEditActionModal, setOpenEditActionModal] = useState(false);
    const [openEditQuickRecapModal, setOpenEditQuickRecapModal] = useState(false);

    const [activeTab, setActiveTab] = useState("editText");
    const handleCloseEditAction = () => {
        setOpenEditActionModal(false)
        setInitialAction({});
        setActiveTab("editText");
      }
      const handleCloseEditQuickRecap = () => {
        setOpenEditQuickRecapModal(false)
      }

      const editActionModal = () => {
        const handleTabChange = (tab) => {
          setActiveTab(tab);
        };
        let originalAction = "";
        let sortedEdits = [];
        if(initialAction && initialAction.edits && initialAction.edits[0]){
          sortedEdits = initialAction.edits
        ? [...initialAction.edits].sort((a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp))
        : [];
          originalAction = sortedEdits[0].previous_value;
        }
        return (
          <Modal
            open={openEditActionModal}
            onClose={handleCloseEditAction}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                background: "white",
                border: "2px solid white",
                boxShadow: 24,
                paddingX: "50px",
                paddingY: "30px",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "20px",
                margin: "0 auto",
                maxWidth: "calc(100% - 40px)",
              }}
            >
              <Box 
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: {
                    xs: "8px",
                    lg: "32px",
                  }
                }}>
                   <Box display="flex" gap="10px">
                   <Typography
                      sx={{
                        fontFamily: "Lexend",
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "4px",
                        borderBottom: activeTab === "editText" ? "1px solid black" : "none",
                        textTransform: "uppercase",
                        boxShadow: "none",
                        "&:hover": {
                          cursor: "pointer"
                        },
                      }}
                      onClick={() => handleTabChange("editText")}
                   >
                      Edit Action
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "4px",
                        borderBottom: activeTab === "editHistory" ? "1px solid black" : "none",
                        textTransform: "uppercase",
                        boxShadow: "none",
                        "&:hover": {
                          cursor: "pointer"
                        },
                      }}
                      onClick={() => handleTabChange("editHistory")}
                    >
                        View History
                    </Typography>
                  </Box>
                {activeTab === "editHistory" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      height: "220px",
                      overflowY: "auto"
                    }}
                  >
                    {sortedEdits &&
                      sortedEdits
                        .filter(edit => edit && edit.edit_id !== null)
                        .slice() 
                        .reverse() 
                        .map((edit, index) => (
                          <Box key={index} sx={{ gap: "2px" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "black"
                              }}
                            >
                              {`${edit.new_value}`}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#2D2D2D",
                                fontSize: "12px",
                                marginLeft: "10px",
                                marginBottom: "6px"
                              }}
                            >
                              edited by {
                                user?.sub === edit.edited_by_userid
                                  ? "me"
                                  : user?.["custom:is_coachee"] === "true"
                                    ? coachName ?? "Coach"
                                    : (clientShared ?? clientName) || "Client"
                              } {formatDistanceToNow(new Date(edit.edit_timestamp))} ago
                            </Typography>
                            <Divider color="#d3d3d3" />
                          </Box>
                        ))}
                     {initialAction.edits && originalAction && (
                      <Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "black",
                        }}
                      >
                        {originalAction}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2D2D2D",
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}
                      >
                        orginal action
                      </Typography>
                      </Box>
                    )}
                  </Box>
                )}
                {activeTab === "editText" && (
                <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "150%",
                      letterSpacing: "2px",
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold"
                    }}
                  >
                    Action
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={updatedAction.action}
                    InputProps={{
                      inputProps: {
                        style: {
                          color: "black",
                        },
                      },
                      style: {
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={(event) => {
                      setUpdatedAction((prev) => ({
                        ...prev,
                        action: event.target.value,
                      }))
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton 
                    onClick={handleCloseEditAction}
                    label={"Cancel"}
                  />
                  <PositiveActionButton 
                    onClick={saveEditAction}
                    label={"Save Action"}
                    disabled={updatedAction.action === initialAction.action}
                  />
                </Box>
                </>
              )}
              </Box>
            </Box>
          </Modal>
        );
      };
    
      const editQuickRecapModal = () => {
        return (
          <Modal
            open={openEditQuickRecapModal}
            onClose={() => setOpenEditQuickRecapModal(false)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                background: "white",
                border: "2px solid white",
                boxShadow: 24,
                paddingX: "50px",
                paddingY: "30px",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "50%",
                gap: "20px",
                margin: "0 auto",
                maxWidth: "calc(100% - 40px)",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "36px",
                  color: "black",
                  fontWeight: 300
                }}
              >
                Edit Recap
              </Typography>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: {
                  xs: "8px",
                  lg: "32px",
                }
              }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "150%",
                      letterSpacing: "2px",
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold"
                    }}
                  >
                    Recap
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={updatedQuickRecap}
                    InputProps={{
                      inputProps: {
                        style: {
                          color: "black",
                          height: "70px"
                        },
                      },
                      style: {
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={(event) => {
                      setUpdatedQuickRecap(event.target.value);
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton 
                    onClick={handleCloseEditQuickRecap}
                    label={"Cancel"}
                  />
                  <PositiveActionButton 
                    onClick={saveEditQuickRecap}
                    label={"Save Recap"}
                  />
                </Box>
              </Box>
            </Box>
          </Modal>
        );
      };
    
      const saveEditAction = async () => {
        try {
          setOpenEditActionModal(false);
        const { status } = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action`, {
            action_id: updatedAction.action_id,
            action: updatedAction.action,
            initial_action: initialAction.action,
            edited_by_userID: user?.sub
        });
          if (status === "success") {
            toast(`Updated action successfully`, {
              hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
              icon: () => <Check color="success" />,
            });
            setCallActions((prev) =>
              prev.map((prevRow) =>
                prevRow.action_id === updatedAction.action_id
                  ? { ...prevRow, action: updatedAction.action }
                  : prevRow
              )
            );
            setUpdatedAction("");
          }
        } catch (error) {
          console.error("Error updating action:", error);
        }
      };
    
      const saveEditQuickRecap = async () => {
        try {
          setOpenEditActionModal(false);
          const { status } = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-file-recap`, {
              call_id: fileData[0]?.id,
              quick_recap: updatedQuickRecap,
          });
          if (status === "success") {
            toast(`Updated recap successfully`, {
              hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
              icon: () => <Check color="success" />,
            });
            setQuickRecap(updatedQuickRecap);
            setOpenEditQuickRecapModal(false);
          }
        } catch (error) {
          console.error("Error updating action:", error);
          setOpenEditQuickRecapModal(false);
        }
      };
    return(
        <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    gap: "24px",
                    overflowY: "auto",
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgb(207, 207, 207)",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "rgb(207, 207, 207)",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "20px",
                        lineHeight: "1.4",
                        letterSpacing: "-0.03em",
                        color: "#160042",
                      }}
                    >
                      Quick Recap
                    </Typography>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "1.8",
                          fontWeight: "500",
                          color: "#202226",
                        }}
                      >
                        {quickRecap}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2D2D2D",
                          fontSize: "12px",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          setUpdatedQuickRecap(quickRecap);
                          setOpenEditQuickRecapModal(true);
                        }}
                      >
                        Edit
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "20px",
                        lineHeight: "1.4",
                        letterSpacing: "-0.03em",
                        color: "#160042",
                      }}
                    >
                      Actions
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {actionsArray.length > 0 &&
                        actionsArray.map((action, index) => (
                          <Box>
                            <Typography
                              key={action.action_id}
                              sx={{
                                fontSize: "14px",
                                lineHeight: "1.8",
                                fontWeight: "500",
                                color: "#202226",
                              }}
                            >
                              {`${index + 1}. `}
                              {action.action}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#2D2D2D",
                                fontSize: "12px",
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => {
                                setUpdatedAction(action);
                                setInitialAction(action);
                                setOpenEditActionModal(true);
                              }}
                            >
                              Edit
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "20px",
                        lineHeight: "1.4",
                        letterSpacing: "-0.03em",
                        color: "#160042",
                      }}
                    >
                      Summary
                    </Typography>
                    {labels && labels.map((label, index) => (
                      <Box key={`${index}-${label}`}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "1.8",
                            fontWeight: "700",
                            color: "#202226",
                          }}
                        >
                          {label}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "1.8",
                            fontWeight: "500",
                            color: "#202226",
                          }}
                        >
                          {summaries[index]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
        {editActionModal()}
        {editQuickRecapModal()}
        </>
    )
}

export default SessionDetails

