import React from "react";

function DeleteIcon({ color }) {
  if (color === "disabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.49609 8.87097L7.50478 18.871C7.50574 19.9755 8.40195 20.8702 9.50652 20.8692L15.5065 20.864C16.6111 20.8631 17.5057 19.9668 17.5048 18.8623L17.4961 8.86228L19.4961 8.86054L19.5048 18.8605C19.5067 21.0697 17.7174 22.8621 15.5083 22.864L9.50826 22.8692C7.29912 22.8711 5.5067 21.0818 5.50478 18.8727L5.49609 8.87271L7.49609 8.87097Z" fill="#A6A6A6" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.49469 6.87009L8.49382 5.87009C8.49238 4.21323 9.83435 2.86892 11.4912 2.86748L13.4912 2.86574C15.1481 2.8643 16.4924 4.20628 16.4938 5.86314L16.4947 6.86314L19.4947 6.86053C20.047 6.86005 20.4951 7.30737 20.4956 7.85966C20.496 8.41194 20.0487 8.86005 19.4964 8.86053L5.49643 8.87269C4.94414 8.87317 4.49604 8.42585 4.49556 7.87356C4.49508 7.32128 4.9424 6.87318 5.49469 6.8727L8.49469 6.87009ZM10.4938 5.86835C10.4933 5.31607 10.9407 4.86796 11.4929 4.86748L13.4929 4.86574C14.0452 4.86526 14.4933 5.31259 14.4938 5.86487L14.4947 6.86487L10.4947 6.86835L10.4938 5.86835Z" fill="#A6A6A6" />
      </svg>
    )
  } else if (color === "enabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.49609 8.81723L7.50478 18.8172C7.50574 19.9218 8.40195 20.8164 9.50652 20.8155L15.5065 20.8103C16.6111 20.8093 17.5057 19.9131 17.5048 18.8085L17.4961 8.80854L19.4961 8.8068L19.5048 18.8068C19.5067 21.0159 17.7174 22.8084 15.5083 22.8103L9.50826 22.8155C7.29912 22.8174 5.5067 21.0281 5.50478 18.819L5.49609 8.81897L7.49609 8.81723Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.49469 6.81636L8.49382 5.81636C8.49238 4.15951 9.83435 2.8152 11.4912 2.81376L13.4912 2.81202C15.1481 2.81058 16.4924 4.15256 16.4938 5.80941L16.4947 6.80941L19.4947 6.8068C20.047 6.80632 20.4951 7.25365 20.4956 7.80593C20.496 8.35822 20.0487 8.80632 19.4964 8.8068L5.49643 8.81897C4.94414 8.81945 4.49604 8.37212 4.49556 7.81984C4.49508 7.26755 4.9424 6.81945 5.49469 6.81897L8.49469 6.81636ZM10.4938 5.81462C10.4933 5.26234 10.9407 4.81424 11.4929 4.81376L13.4929 4.81202C14.0452 4.81154 14.4933 5.25886 14.4938 5.81115L14.4947 6.81115L10.4947 6.81462L10.4938 5.81462Z" fill="black" />
      </svg>
    )
  } else {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.49609 8.23816L7.50478 18.2382C7.50574 19.3427 8.40195 20.2374 9.50652 20.2364L15.5065 20.2312C16.6111 20.2302 17.5057 19.334 17.5048 18.2295L17.4961 8.22947L19.4961 8.22773L19.5048 18.2277C19.5067 20.4369 17.7174 22.2293 15.5083 22.2312L9.50826 22.2364C7.29912 22.2383 5.5067 20.449 5.50478 18.2399L5.49609 8.2399L7.49609 8.23816Z" fill="url(#paint0_linear_564_6851)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.49469 6.23728L8.49382 5.23728C8.49238 3.58042 9.83435 2.23611 11.4912 2.23467L13.4912 2.23293C15.1481 2.23149 16.4924 3.57347 16.4938 5.23032L16.4947 6.23032L19.4947 6.22772C20.047 6.22724 20.4951 6.67456 20.4956 7.22685C20.496 7.77913 20.0487 8.22723 19.4964 8.22771L5.49643 8.23988C4.94414 8.24036 4.49604 7.79304 4.49556 7.24075C4.49508 6.68847 4.9424 6.24036 5.49469 6.23988L8.49469 6.23728ZM10.4938 5.23554C10.4933 4.68325 10.9407 4.23515 11.4929 4.23467L13.4929 4.23293C14.0452 4.23245 14.4933 4.67978 14.4938 5.23206L14.4947 6.23206L10.4947 6.23554L10.4938 5.23554Z" fill="url(#paint1_linear_564_6851)" />
        <defs>
          <linearGradient id="paint0_linear_564_6851" x1="16.8648" y1="21.691" x2="4.4165" y2="13.8727" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
          <linearGradient id="paint1_linear_564_6851" x1="17.4757" y1="7.99844" x2="12.2663" y2="-0.725917" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default DeleteIcon;