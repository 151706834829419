/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import { Footer } from "scenes/login/Footer";
import { Header } from "components/GlobalHeader/GlobalHeader";
import { useSelector } from "react-redux";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import ClientLimitReached from 'components/ClientLimitReachedModal';
import RetuneIframe from 'components/Retune/VirtualCoachIframe';


const CoachVirtualCoachPage = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);


  return (
    <div>
      {user ? (
        <GlobalPageComponent user={user} accessTo={userData.accessTo}>
          <ClientLimitReached user={user} userData={userData}/>
          <Box
            sx={{
              backgroundColor: "#EFF3F6",
              width: "100%",
              height: "100%",
              padding: {
                xs: "30px 16px",
                md: "32px 40px",
              },
              display: {
                xs: !collapsed ? "none" : "block",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                alignItems: {
                  xs: "flex-start",
                  md: "center",
                },
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "36px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  paddingBottom: "24px",
                }}
              >
                Virtual Coach
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: {
                    xs: "unset",
                    md: "200px",
                  },
                  zIndex: 1100,
                  position: "relative",
                  paddingBottom: "24px",
                  alignSelf: {
                    xs: "center",
                    md: "unset",
                  },
                }}
              >
                <a
                  href="https://ac-media-files.s3.amazonaws.com/guides/Virtual+Coach+Guide+V2.pdf"
                  download
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      display: "flex",
                      width: {
                        xs: "100%",
                        md: "220px",
                      },
                      padding: "10px 8px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      borderRadius: "52px",
                      border: "1px solid black",
                      borderColor: "black",
                      background: "white",
                      opacity: 1,
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                        color: "white",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        color: "black",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        letterSpacing: "2px",
                        lineHeight: "1.5",
                        textTransform: "uppercase",
                      }}
                    >
                      Download User Guide
                    </Typography>
                    {ArrowForwardBlack()}
                  </Button>
                </a>
              </Box>
            </Box>
            <Box
              width={{ xs: "100%", lg: "100%" }}
              height={{ xs: "unset", lg: "85vh" }}
              justifyContent="center"
              display="flex"
              alignItems="center"
              flexDirection={{ xs: "column-reverse", md: "row" }}
              marginTop={{ xs: "0px", lg: "0px" }}
            >
              <RetuneIframe user={user} userData={userData} />
            </Box>
          </Box>
        </GlobalPageComponent>
      ) : (
        <Header typeOfNav="virtualcoach" hideGlobalSearchbar={true} />
      )}

      {!user && (
        <Footer />
      )}
    </div>
  );
};

export default CoachVirtualCoachPage;

