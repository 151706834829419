import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import Dashboard from "components/Icons/Dashboard.svg";
import Client from "components/Icons/Client.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCollapsed } from "slices/LayoutSlice";
import Actions from "components/Icons/Actions.svg";
import VirtualCoach from "components/Icons/VirtualCoach.svg";
import Logo from "./logo";
import LaraIcon from "components/Icons/LaraIcon.svg";
import CheckListIcon from "components/Icons/CheckList.svg";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import AssignmentIcon from '@mui/icons-material/Assignment';


const Item = ({
  title,
  to,
  icon,
  hoverIcon,
  selected,
  setSelected,
  mouseOver,
  setMouseOver,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    setSelected(to);
    dispatch(setCollapsed(true)); // Collapse the sidebar
  };

  const sidebarTextStyle = { fontSize: "16px", fontWeight: 400 };

  return (
    <MenuItem
      active={selected === to}
      style={{
        color: "#93989A",
        background: selected === to ? "#F5F7FF" : "white",
      }}
      onMouseOver={() => setMouseOver(to)}
      onMouseLeave={() => setMouseOver("")}
      icon={mouseOver === to || selected === to ? hoverIcon : icon}
      onClick={handleClick} // Collapse the sidebar on item click
    >
      <Typography
        fontWeight={500}
        color={mouseOver === to || selected === to ? "black" : "#93989A"}
        sx={sidebarTextStyle}
      >
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};


const Sidebar = ({ accessTo }) => {
  const [selected, setSelected] = useState("Dashboard");
  const [mouseOver, setMouseOver] = useState("Dashboard");
  const [user, setUser] = useState(null);
  const collapsed = useSelector((state) => state.layout.collapsed);
  const location = useLocation();

  // Update selected state based on the current location pathname
  useEffect(() => {
    const path = location.pathname;
    setSelected(path);
  }, [location]);

  useEffect(() => {
    const updateUserState = async () => {
      try {
        await getCurrentUser()
        const currentUser = await fetchUserAttributes();
        setUser(currentUser);
      } catch (error) {
        setUser(null);
      }
    };

    const authListener = (data) => {
      const { payload } = data;
      if (payload.event === "signOut") {
        updateUserState();
      }
    };

    const removeListener = Hub.listen("auth", authListener);

    updateUserState();

    // Return a cleanup function to remove the listener
    return () => {
      removeListener();
    };
  }, []);

  const isClient = () => {
    return user?.["custom:is_coachee"] === "true";
  };

  return (
    <Box
      sx={{
        display: {
          xs: "flex",
          lg: "flex",
        },
        backgroundColor: "white",
        margin: {
          lg: "20px 0 20px 20px",
        },
        position: {
          lg: "sticky",
          xs: "fixed",
        },
        top: {
          lg: "20px",
          xs: "82px",
        },
        left: {
          xs: !collapsed ? "0" : "-100vw",
        },
        transition: "all 0.3s",
        maxHeight: {
          lg: "calc(100vh - 40px)",
        },
        WebkitTransform: "translateZ(0)",
        borderRadius: {
          lg: "40px",
        },
        zIndex: {
          xs: "99",
        },
      }}
    >
      <Box
        width={{ xs: "100vw", sm: "100vw", lg: "auto" }}
        sx={{
          borderRadius: {
            lg: "40px",
          },
          "& .pro-sidebar": {
            paddingTop: 0,
            width: {
              xs: "100% !important",
              lg: "270px !important",
            },
            height: {
              xs: "100vh",
              lg: "100%",
            },
            backgroundColor: "#fff",
            borderRadius: "40px",
          },
          "& .pro-sidebar-layout": {
            height: "100%",
          },
          "& .pro-sidebar-inner": {
            background: "white",
          },
          "& .pro-sidebar .pro-menu": {
            height: "100%",
          },
          "& .pro-sidebar .pro-menu > ul": {
            height: "100%",
          },
          "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item": {
            xs: {
              padding: "18px 30px",
            },
            lg: {
              padding: "8px 35px 8px 20px",
            },
            color: "#93989A",
          },
          "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper":
          {
            xs: {
              marginRight: "28px",
            },
            lg: {
              marginRight: "10px",
            },
          },
          "& .pro-inner-item:hover": {
            color: "black !important",
            background: "#F5F7FF",
          },
          "& .svg:hover": {
            color: "black !important",
          },
          overflow: "hidden",
        }}
      >
        <ProSidebar
          width="270px"
          style={{
            display: { xs: collapsed ? "none" : "flex", lg: "flex" },
            borderRadius: {
              lg: "40px",
            },
          }}
        >
          <Box
            sx={{
              margin: "30px 0px 20px 0",
              display: { xs: "none", sm: "none", lg: "flex" },
              justifyContent: "center",
            }}
          >
            <Box>
              <Logo user={user} accessTo={accessTo} style={{ transform: "scale(0.4)" }} />
            </Box>
          </Box>
          <Menu>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: { xs: "auto", lg: "270px" },
                height: "100%",
                maxHeight: {
                  xs: "calc(100vh - 120px)",
                  lg: "unset",
                },
              }}
            >
              <Box>
                <Item
                  title="Dashboard"
                  to={isClient() && accessTo === "client_portal" ? "/platform/coachee" : "/platform/coach"}
                  icon={Dashboard({ color: "#93989A" })}
                  hoverIcon={Dashboard({ color: "black" })}
                  selected={selected}
                  setSelected={setSelected} // Pass the setSelected function
                  mouseOver={mouseOver}
                  setMouseOver={setMouseOver}
                />
                {accessTo !== 'client_portal' && (
                  <Item
                    title="Leadership Center"
                    to="/platform/coach/leadership-center"
                    icon={Client({ color: "#93989A" })}
                    hoverIcon={Client({ color: "black" })}
                    selected={selected}
                    setSelected={setSelected} // Pass the setSelected function
                    mouseOver={mouseOver}
                    setMouseOver={setMouseOver}
                  />
                )}
                <Item 
                  title="LARA Analytics"
                  to={
                    isClient() && accessTo === "client_portal"
                      ? "/platform/coachee/lara-analytics"
                      : "/platform/coach/lara-analytics"
                  }
                  icon={LaraIcon({ color: "#93989A"})}
                  hoverIcon={LaraIcon({ color: "black" })}
                  selected={selected}
                  setSelected={setSelected} // Pass the setSelected function
                  mouseOver={mouseOver}
                  setMouseOver={setMouseOver}
                />
                <Item
                  title="Goals & Actions"
                  to={
                    isClient() && accessTo === "client_portal"
                      ? "/platform/coachee/actions"
                      : "/platform/coach/actions"
                  }
                  icon={Actions({ color: "#93989A" })}
                  hoverIcon={Actions({ color: "black" })}
                  selected={selected}
                  setSelected={setSelected} // Pass the setSelected function
                  mouseOver={mouseOver}
                  setMouseOver={setMouseOver}
                />
                <Item 
                  title="Assessments"
                  to={
                    isClient() && accessTo === "client_portal"
                      ? "/platform/coachee/assessments"
                      : "/platform/coach/assessments"
                  }
                  // icon={<AssignmentIcon size={30} color={ "#93989A"} />}
                  icon={<AssignmentIcon size={30} sx={{color: "#93989A", fontSize: "30px"}} />}
                  hoverIcon={<AssignmentIcon size={30} sx={{color: "black", fontSize: "30px"}}/>}
                  selected={selected}
                  setSelected={setSelected} // Pass the setSelected function
                  mouseOver={mouseOver}
                  setMouseOver={setMouseOver}
                />
                <Item
                  title="Virtual Coach"
                  to={
                    isClient() && accessTo === "client_portal"
                      ? "/platform/coachee/virtualcoach"
                      : "/platform/coach/virtualcoach"
                  }
                  icon={VirtualCoach({ color: "#93989A" })}
                  hoverIcon={VirtualCoach({ color: "black" })}
                  selected={selected}
                  setSelected={setSelected} // Pass the setSelected function
                  mouseOver={mouseOver}
                  setMouseOver={setMouseOver}
                />
              </Box>
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </Box>
  );
};

export default Sidebar;
