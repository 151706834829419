import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Topbar from 'scenes/Layout/Topbar';
import Sidebar from 'scenes/Layout/Sidebar';
import ClientHasInactiveCoachModal from 'components/ClientHasInactiveCoach/ClientHasInactiveCoachModal';

const GlobalPageComponent = ({ children, user, accessTo }) => {

  const defaultStyle = {
    display: "flex",
    flexDirection: {xs:"column", lg:"row"},
    width: '100%',
    height: '100%',
    background: '#EFF3F6',
    overflowY: "auto"
  };

  return (
    <Box sx={defaultStyle} id="GlobalPageComponent">
        <ClientHasInactiveCoachModal />
        <Topbar user={user}/>
        <Sidebar user={user} accessTo={accessTo} />
        {children}
    </Box>
  );
};

GlobalPageComponent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export default GlobalPageComponent;