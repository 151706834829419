import { useCallback, useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import axios from 'axios';
export function useGetCoachesForUser(user_id) {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCoaches = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const coachesResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-coaches`, {
        params: { user_id }
      });
      setCoaches(coachesResponse?.data?.coaches);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [user_id]);

  useEffect(() => {
    if (user_id) {
      getCoaches();
    }
  }, [user_id, getCoaches]);

  return { coaches, loading, error };
}