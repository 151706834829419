import React from "react";

function Client({ color }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 15C17.5376 15 20 12.5376 20 9.5C20 6.46243 17.5376 4 14.5 4C11.4624 4 9 6.46243 9 9.5C9 12.5376 11.4624 15 14.5 15Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M23 24C23 19.0305 18.971 15 13.999 15C9.02698 15 5 19.0305 5 24" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  );
}

export default Client;
