import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  Button,
  Tabs,
  Tab,
} from "@mui/material";

const getPieChartColor = (score) => {
  if (score >= 1 && score <= 20) return "#DA0000"; // Red
  if (score >= 21 && score <= 40) return "#F90"; // Orange
  if (score >= 41 && score <= 60) return "#FFD600"; // Yellow
  if (score >= 61 && score <= 70) return "#A2D831"; // Light Blue
  if (score >= 71 && score <= 80) return "#70CD27"; // Green
  if (score >= 81 && score <= 95) return "#4BC500"; // Green
  if (score >= 95 && score <= 100) return "#37AB00"; // Green
  return "#D3D3D3"; // Grey for undefined or zero
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      style={{ position: "relative" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: "20px", width: "100%" }}>{children}</Box>
      )}
    </div>
  );
};

const Circle = ({ id, color, radius, fill, strokeDashoffset, strokeWidth }) => {
  const r = radius;
  const circ = 2 * Math.PI * r;

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill={fill ?? "transparent"}
      stroke={color ? color : `url(#${id})`}
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
      style={{
        transition: "stroke-dashoffset 1s ease-out",
        filter: "drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.05))",
      }}
    ></circle>
  );
};

const Text = ({ score, textSize }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={textSize}
      fontFamily="Poppins"
      fontWeight={275}
    >
      {isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
    </text>
  );
};

const Pie = ({ score, radius, textSize, fillStopColor, isOverall }) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(
    2 * Math.PI * radius
  );
  const gradientId = `gradient-${Math.random()}`;
  const fillGradientId = `fill-gradient-${Math.random()}`;

  useEffect(() => {
    const circ = 2 * Math.PI * radius;
    const strokescore = ((100 - score) * circ) / 100;
    setStrokeDashoffset(strokescore);
  }, [score, radius]);

  const color = getPieChartColor(score);
  const strokeWidth = "6px";

  return (
    <svg width={200} height={200}>
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="#9C44C5" />
          <stop offset="40%" stopColor="#FF5E00" />
          <stop offset="60%" stopColor="#C4E7F7" />
          <stop offset="100%" stopColor="#568FF3"/>
        </linearGradient>
        <linearGradient id={fillGradientId}>
          <stop
            offset="0%"
            stopColor={fillStopColor ?? color}
            stopOpacity=".2"
          />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g
        transform={
          isOverall ? `rotate(90 ${"100 100"})` : `rotate(-90 ${"100 100"})`
        }
      >
        <Circle
          color="lightgrey"
          radius={radius}
          strokeDashoffset={0}
          strokeWidth={strokeWidth}
          fill={`url(#${fillGradientId})`}
        />
        <Circle
          id={gradientId}
          radius={radius}
          strokeDashoffset={strokeDashoffset}
          strokeWidth={strokeWidth}
          color={isOverall ? `url(#${gradientId})` : color}
        />
      </g>
      {isNaN(score) ? (
        <Text score="-" textSize={textSize} />
      ) : (
        <Text score={score} textSize={textSize} />
      )}
    </svg>
  );
};

const renderSectionContent = (section, role, laraData) => {
  const isCoach = role === "coach";

  // Coach data resides directly under laraData, while coachee data resides under laraData.coachees
  const sectionData = isCoach ? laraData?.coach : laraData?.client;

  // Helper functions to get data fields
  const getStrengthField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_strengths`]
      : sectionData?.[`${section}_strengths`];
  const getWeaknessField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_weaknesses`]
      : sectionData?.[`${section}_weaknesses`];
  const getImprovementField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_area_of_improvement`]
      : sectionData?.[`${section}_area_of_improvement`];
  const getDetailsField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_details`]
      : sectionData?.[`${section}_details`];

  // Rendering bullet point text for each section
  const renderText = (label, text) => (
    <>
      <Typography
        sx={{
          display: "flex",
          width: "100%",
          color: "#000",
          fontFamily: "Lexend",
          fontSize: 16,
          fontWeight: 700,
          letterSpacing: "2px",
          marginBottom: "8px",
          marginLeft: "170px",
        }}
      >
        {label}
      </Typography>
      <ul>
        <li>
          <Typography
            sx={{
              display: "flex",
              color: "#8B8B8B",
              fontFamily: "Lexend",
              fontSize: 16,
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "2px",
              marginBottom: "16px",
              marginLeft: "170px",
            }}
          >
            {text ? text : `Not enough response ${label.toLowerCase()} data available.`}
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <Box sx={{ padding: "10px", width: "85%" }}>
      <ul>
        <li>{renderText("Strengths", getStrengthField())}</li>
        <li>{renderText("Weaknesses", getWeaknessField())}</li>
        <li>{renderText("Improvements", getImprovementField())}</li>
        <li>{renderText("Details", getDetailsField())}</li>
      </ul>
    </Box>
  );
};

const LaraSessionPage = ({ downloadTranscript, laraData }) => {
  console.log("lara data in component: ", laraData)
  const [tabIndex, setTabIndex] = useState(0);
  const [fullReportOpen, setFullReportOpen] = useState(true);
  const [openSections, setOpenSections] = useState(null); // Store only the currently open section

  const calculateOverallScore = useCallback(
    (listenScore, affirmScore, respondScore, askScore) => {
      return !isNaN(listenScore) &&
        !isNaN(affirmScore) &&
        !isNaN(respondScore) &&
        !isNaN(askScore)
        ? ((parseFloat(listenScore) +
            parseFloat(affirmScore) +
            parseFloat(respondScore) +
            parseFloat(askScore)) /
            4) *
            20
        : 0;
    },
    []
  );

  const handleToggle = (section) => {
    // Close the section if it's already open, otherwise open the clicked section and close others
    setOpenSections((prevState) => (prevState === section ? null : section));
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    setFullReportOpen(true);
    setOpenSections(null); // Reset the open section when switching tabs
  };
  const generateCoacheeSummary = useCallback(() => {
    // Ensure laraData is available for the coachee
    if (!laraData || !laraData.client) {
      return "No coachee summary is available for your report.";
    }
  
    // Dynamically extracting the coachee name, or use default if missing
    const coacheeName = laraData.client?.coachee_name || "Yours";
  
    // Check if the coachee communication report is available
    const coacheeCommunicationReport = laraData.client?.communication_report;
  
    // Return the coachee communication report if available
    if (coacheeCommunicationReport) {
      return coacheeCommunicationReport;
    }
  
    // If no coachee communication report is available, display a fallback message
    return `No communication report is available for ${coacheeName}.`;
  }, [laraData]);
  
  const generateCoachSummary = useCallback(() => {
    // Ensure laraData is available for the coach
    if (!laraData || !laraData.coach) {
      return "No coach summary is available.";
    }
  
    // Dynamically extracting the coach name, or use default if missing
    const coachName = laraData.coach.coach_name || "Yours";
  
    // Check if the coach communication is available
    const coachCommunication = laraData?.coach.coach_communication;
  
    // Return the coach communication if available
    if (coachCommunication) {
      return coachCommunication;
    }
  
    // If no coach communication is available, display a fallback message
    return `No communication report is available.`;
  }, [laraData]);
  
  

  return (
    <Box
      sx={{ width: "100%", marginTop: { xs: "0px", sm: "50px", md: "0px" } }}
    >
      <Typography
        sx={{
          fontSize: 14,
          color: "#474747",
          fontFamily: "Poppins",
          fontWeight: 400,
          textDecoration: "underline",
          textAlign: "left",
          cursor: "pointer",
          wordWrap: "break-word",
          marginRight: "auto",
          paddingleft: "68px",
        }}
        component="span"
        onClick={() => downloadTranscript()}
      >
        Download transcript here
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            color: "#B0B0B0",
            fontSize: 12,
            fontFamily: "Lexend",
            fontWeight: 700,
            textTransform: "uppercase",
            lineHeight: "18px",
            letterSpacing: 2,
            wordWrap: "break-word",
            marginLeft: "0px",
          }}
        >
          overall score
        </Typography>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="client-coach-tabs"
          TabIndicatorProps={{ style: { backgroundColor: "black" } }}
          sx={{
            marginLeft: "10px",
            "& .MuiTab-textColorPrimary": {
              color: "black",
              fontFamily: "Lexend",
            },
            "& .MuiTab-root.MuiButtonBase-root": {
              fontSize: "12px",
              fontWeight: 700,
              textTransform: "uppercase",
              lineHeight: "18px",
              letterSpacing: 2,
              marginLeft: "10px",
            },
          }}
        >
          <Tab label="Coachee" />
          <Tab label="Coach" />
        </Tabs>
      </Box>

      {/* Coachee Tab */}
      <CustomTabPanel value={tabIndex} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingRight: "20px",
              minWidth: "230px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Pie
                score={
                  laraData?.client
                    ? calculateOverallScore(
                        laraData?.client?.listen_score,
                        laraData?.client?.affirm_score,
                        laraData?.client?.respond_score,
                        laraData?.client?.ask_score
                      )
                    : 0
                }
                radius={70}
                textSize={"54px"}
                fillStopColor={"#62C1EB"}
                isOverall={true} // Added to distinguish the overall score pie chart
              />
            </Box>
            <Typography
              sx={{
                color: "#B0B0B0",
                fontSize: 12,
                fontFamily: "Lexend",
                fontWeight: 700,
                textTransform: "uppercase",
                lineHeight: "18px",
                letterSpacing: 2,
                wordWrap: "break-word",
                marginLeft: "0px",
                textAlign: "center",
                paddingBottom: "10px",
                marginTop: "50px", //change this to make space between the pie chart and the text
              }}
            >
              score analysis
            </Typography>
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "column", maxWidth: "740px" }}
          >
            <Typography
              sx={{
                color: "#160042",
                fontFamily: "Poppins",
                fontSize: 41,
                fontWeight: 300,
                lineHeight: "48px",
                letterSpacing: "-0.82px",
              }}
            >
              My LARA Communication Report
            </Typography>
            <Typography
              sx={{
                color: "#160042",
                fontFamily: "Lexend",
                fontSize: 20,
                fontWeight: 400,
                lineHeight: "28px",
                letterSpacing: "-0.6px",
                marginTop: "10px",
              }}
            >
              Your Call Summary
            </Typography>
            <Typography
              sx={{
                color: "#8F8F8F",
                fontFamily: "Lexend",
                fontSize: 20,
                fontWeight: 400,
                lineHeight: "28px",
                letterSpacing: "-0.6px",
                marginTop: "10px",
                maxWidth: "1072px",
              }}
            >
              {generateCoacheeSummary()}
            </Typography>
          </Box>

          <Button
            sx={{
              fontSize: 10,
              color: "black",
              fontFamily: "Lexend",
              fontWeight: 700,
              textTransform: "uppercase",
              lineHeight: "15px",
              letterSpacing: 2,
              wordWrap: "break-word",
              padding: "10px 20px",
              minWidth: "unset",
              marginLeft: "auto",
              marginRight: "22px",
              borderRadius: "52px",
              border: "2px solid #000",
              display: "flex",
              width: "221px",
              height: "35px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
            }}
            onClick={() => downloadTranscript()}
          >
            Download Full Report
          </Button>
        </Box>

        {fullReportOpen && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {["listen", "affirm", "respond", "ask"].map((section, index) => (
              <Box
                key={section}
                sx={{ maxWidth: "100%", marginBottom: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Pie
                      score={
                        parseFloat(laraData?.client?.[`${section}_score`]) * 20
                      }
                      radius={70} //changes the size of the pie chart
                      color={getPieChartColor(
                        parseFloat(laraData?.client?.[`${section}_score`]) * 20
                      )}
                      textSize={"54px"} //changes the size of the text
                      fillStopColor={"#62C1EB"}
                    />
                    <Typography
                      sx={{
                        color: "#160042",
                        fontSize: 41,
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        lineHeight: "48px",
                        wordWrap: "break-word",
                        marginLeft: "15px",
                      }}
                    >
                      {section.charAt(0).toUpperCase() + section.slice(1)}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{
                      width: "70.689px",
                      height: "70.689px",
                      flexShrink: 0,
                    }}
                    onClick={() => handleToggle(section)}
                  >
                    {openSections === section ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="71"
                        height="71"
                        viewBox="0 0 71 71"
                        fill="none"
                      >
                        <circle
                          cx="35.3447"
                          cy="35.3448"
                          r="34.9337"
                          transform="rotate(-90 35.3447 35.3448)"
                          fill="#0076BA"
                          stroke="white"
                          strokeWidth="0.82197"
                        />
                        <path
                          d="M34.5218 46.8535C34.5218 47.3075 34.8898 47.6755 35.3437 47.6755C35.7977 47.6755 36.1657 47.3075 36.1657 46.8535L34.5218 46.8535ZM35.925 23.2571C35.604 22.9361 35.0835 22.9361 34.7625 23.2571L29.5315 28.4881C29.2106 28.8091 29.2106 29.3296 29.5315 29.6506C29.8525 29.9716 30.373 29.9716 30.694 29.6506L35.3438 25.0008L39.9935 29.6506C40.3145 29.9716 40.835 29.9716 41.156 29.6506C41.477 29.3296 41.477 28.8091 41.156 28.4881L35.925 23.2571ZM36.1657 46.8535L36.1657 23.8384L34.5218 23.8384L34.5218 46.8535L36.1657 46.8535Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="71"
                        height="71"
                        viewBox="0 0 71 71"
                        fill="none"
                      >
                        <circle
                          cx="35.3448"
                          cy="35.3447"
                          r="34.9337"
                          transform="rotate(90 35.3448 35.3447)"
                          stroke="black"
                          strokeWidth="0.82197"
                        />
                        <path
                          d="M36.1677 23.8359C36.1677 23.382 35.7997 23.014 35.3457 23.014C34.8917 23.014 34.5237 23.382 34.5237 23.8359L36.1677 23.8359ZM34.7645 47.4323C35.0855 47.7533 35.6059 47.7533 35.9269 47.4323L41.1579 42.2013C41.4789 41.8803 41.4789 41.3599 41.1579 41.0389C40.8369 40.7179 40.3165 40.7179 39.9955 41.0389L35.3457 45.6886L30.6959 41.0389C30.3749 40.7179 29.8545 40.7179 29.5335 41.0389C29.2125 41.3599 29.2125 41.8803 29.5335 42.2013L34.7645 47.4323ZM34.5237 23.8359L34.5237 46.8511L36.1677 46.8511L36.1677 23.8359L34.5237 23.8359Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Box>
                <Collapse in={openSections === section}>
                  <Box sx={{ marginTop: "-20px" }}>
                    <Typography
                      sx={{
                        color: "#292929",
                        fontSize: 20,
                        fontFamily: "Lexend",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        padding: "10px",
                        marginLeft: "210px",
                      }}
                    >
                      {laraData?.client?.[`${section}_report`] ||
                        "No data available."}
                    </Typography>
                    {renderSectionContent(section, "client", laraData)}
                  </Box>
                  {/* <Box sx={{ borderTop: "2px solid #CED9E0", marginY: "10px" }}></Box> */}
                </Collapse>
                <Box sx={{ borderTop: "2px solid #CED9E0", marginY: "10px" }} />
              </Box>
            ))}
          </Box>
        )}
      </CustomTabPanel>

      {/* Coach Tab */}
      <CustomTabPanel value={tabIndex} index={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingRight: "20px",
              minWidth: "230px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Pie
                score={
                  laraData?.coach
                    ? calculateOverallScore(
                        laraData?.coach?.coach_listen_score,
                        laraData?.coach?.coach_affirm_score,
                        laraData?.coach?.coach_respond_score,
                        laraData?.coach?.coach_ask_score
                      )
                    : 0
                }
                radius={70}
                textSize={"54px"}
                fillStopColor={"#62C1EB"}
                isOverall={true} // Added to distinguish the overall score pie chart
              />
            </Box>
            <Typography
              sx={{
                color: "#B0B0B0",
                fontSize: 12,
                fontFamily: "Lexend",
                fontWeight: 700,
                textTransform: "uppercase",
                lineHeight: "18px",
                letterSpacing: 2,
                wordWrap: "break-word",
                marginLeft: "0px",
                textAlign: "center",
                paddingBottom: "10px",
                marginTop: "50px", //change this to make space between the pie chart and the text
              }}
            >
              score analysis
            </Typography>
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "column", maxWidth: "740px" }}
          >
            <Typography
              sx={{
                color: "#160042",
                fontFamily: "Poppins",
                fontSize: 41,
                fontWeight: 300,
                lineHeight: "48px",
                letterSpacing: "-0.82px",
              }}
            >
              My LARA Communication Report
            </Typography>
            <Typography
              sx={{
                color: "#160042",
                fontFamily: "Lexend",
                fontSize: 20,
                fontWeight: 400,
                lineHeight: "28px",
                letterSpacing: "-0.6px",
                marginTop: "10px",
              }}
            >
              Your Call Summary
            </Typography>
            <Typography
              sx={{
                color: "#8F8F8F",
                fontFamily: "Lexend",
                fontSize: 20,
                fontWeight: 400,
                lineHeight: "28px",
                letterSpacing: "-0.6px",
                marginTop: "10px",
                maxWidth: "1072px",
              }}
            >
              {generateCoachSummary()}
            </Typography>
          </Box>

          <Button
            sx={{
              fontSize: 10,
              color: "black",
              fontFamily: "Lexend",
              fontWeight: 700,
              textTransform: "uppercase",
              lineHeight: "15px",
              letterSpacing: 2,
              textDecoration: "none",
              wordWrap: "break-word",
              padding: "10px 20px",
              minWidth: "unset",
              marginLeft: "auto",
              marginRight: "22px",
              borderRadius: "52px",
              border: "2px solid #000",
              display: "flex",
              width: "221px",
              height: "35px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
            }}
            onClick={() => downloadTranscript()}
          >
            Download Full Report
          </Button>
        </Box>

        {fullReportOpen && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {["listen", "affirm", "respond", "ask"].map((section, index) => (
              <Box
                key={section}
                sx={{ maxWidth: "100%", marginBottom: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Pie
                      score={
                        parseFloat(laraData?.coach?.[`coach_${section}_score`]) * 20
                      }
                      radius={70} //changes the size of the pie chart
                      color={getPieChartColor(
                        parseFloat(laraData?.coach?.[`coach_${section}_score`]) * 20
                      )}
                      textSize={"54px"} //changes the size of the text
                      fillStopColor={"#62C1EB"}
                    />
                    <Typography
                      sx={{
                        color: "#160042",
                        fontSize: 41,
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        lineHeight: "48px",
                        wordWrap: "break-word",
                        marginLeft: "15px",
                      }}
                    >
                      {section.charAt(0).toUpperCase() + section.slice(1)}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{
                      width: "70.689px",
                      height: "70.689px",
                      flexShrink: 0,
                    }}
                    onClick={() => handleToggle(section)}
                  >
                    {openSections === section ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="71"
                        height="71"
                        viewBox="0 0 71 71"
                        fill="none"
                      >
                        <circle
                          cx="35.3447"
                          cy="35.3448"
                          r="34.9337"
                          transform="rotate(-90 35.3447 35.3448)"
                          fill="#0076BA"
                          stroke="white"
                          strokeWidth="0.82197"
                        />
                        <path
                          d="M34.5218 46.8535C34.5218 47.3075 34.8898 47.6755 35.3437 47.6755C35.7977 47.6755 36.1657 47.3075 36.1657 46.8535L34.5218 46.8535ZM35.925 23.2571C35.604 22.9361 35.0835 22.9361 34.7625 23.2571L29.5315 28.4881C29.2106 28.8091 29.2106 29.3296 29.5315 29.6506C29.8525 29.9716 30.373 29.9716 30.694 29.6506L35.3438 25.0008L39.9935 29.6506C40.3145 29.9716 40.835 29.9716 41.156 29.6506C41.477 29.3296 41.477 28.8091 41.156 28.4881L35.925 23.2571ZM36.1657 46.8535L36.1657 23.8384L34.5218 23.8384L34.5218 46.8535L36.1657 46.8535Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="71"
                        height="71"
                        viewBox="0 0 71 71"
                        fill="none"
                      >
                        <circle
                          cx="35.3448"
                          cy="35.3447"
                          r="34.9337"
                          transform="rotate(90 35.3448 35.3447)"
                          stroke="black"
                          strokeWidth="0.82197"
                        />
                        <path
                          d="M36.1677 23.8359C36.1677 23.382 35.7997 23.014 35.3457 23.014C34.8917 23.014 34.5237 23.382 34.5237 23.8359L36.1677 23.8359ZM34.7645 47.4323C35.0855 47.7533 35.6059 47.7533 35.9269 47.4323L41.1579 42.2013C41.4789 41.8803 41.4789 41.3599 41.1579 41.0389C40.8369 40.7179 40.3165 40.7179 39.9955 41.0389L35.3457 45.6886L30.6959 41.0389C30.3749 40.7179 29.8545 40.7179 29.5335 41.0389C29.2125 41.3599 29.2125 41.8803 29.5335 42.2013L34.7645 47.4323ZM34.5237 23.8359L34.5237 46.8511L36.1677 46.8511L36.1677 23.8359L34.5237 23.8359Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Box>
                <Collapse in={openSections === section}>
                  <Typography
                    sx={{
                      color: "#8F8F8F",
                      fontSize: 20,
                      fontFamily: "Lexend",
                      fontWeight: 400,
                      lineHeight: "28px",
                      wordWrap: "break-word",
                      padding: "10px",
                      marginLeft: "210px",
                    }}
                  >
                    {laraData?.coach?.[`coach_${section}_report`] ||
                      "No data available."}
                  </Typography>
                  {renderSectionContent(section, "coach", laraData)}
                  {/* <Box sx={{ borderTop: "2px solid #CED9E0", marginY: "10px" }}></Box> */}
                </Collapse>
                <Box sx={{ borderTop: "2px solid #CED9E0", marginY: "10px" }} />
              </Box>
            ))}
          </Box>
        )}
      </CustomTabPanel>
    </Box>
  );
};

export default LaraSessionPage;
