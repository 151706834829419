import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate, Link } from "react-router-dom"; // Import navigate function
import "./SignUp.css";
import { CircularProgress, InputLabel, List, ListItem } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import VerifyUser from "scenes/VerifyUser/VerifyUser";
import VerifyCode from "scenes/VerifyCode/VerifyCode";
import Lottie from "react-lottie";
import * as animationData from "data/dashboardAnimation.json";
import Logo from "scenes/Layout/logo";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { confirmSignUp, signUp } from "aws-amplify/auth"

// Create Account Component Styling
export const themeOptions = {
  palette: {
    // mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "rgba(26, 32, 44, 1)",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
  },
  // Add an image URL to the headerImage property
  headerImage: "", // Replace with the URL of your chosen image
};

export const theme = createTheme(themeOptions);

export default function ClientSignUp() {
  const navigate = useNavigate(); // Get the navigate function from React Router
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [token_data, setTokenData] = useState({
    email: "",
    coach_id: "",
    firstName: "",
    lastName: "",
    invite_id: ""
  });
  const [isVerified, setIsVerified] = useState(false);
  const [info, setInfo] = useState("");
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedFileURL, setSelectedFileURL] = useState(null);

  const dispatch = useDispatch();

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: token_data?.firstName,
      lastName: token_data?.lastName,
      email: token_data?.email,
      password: "",
      agreedToTerms: false,
      verificationCode: "",
      isSignupStep: true,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: async (e) => {
      if (values.isSignupStep) {
        // Handle signup step
        const { password } = values;
        const { email, coach_id, firstName, lastName, invite_id } = token_data;
        try {
          setLoading(true);
          // Sign up the user using Amplify Auth API
          const signUpResponse = await signUp({
            username: email, // Use email as the username
            password,
            options: {
              userAttributes: {
                email: email,
                given_name: firstName,
                family_name: lastName,
                "custom:is_coachee": "true",
                "custom:invite_id": invite_id
              }
            },
          });
          setFieldValue("isSignupStep", false);
          console.log("sign up response: ", signUpResponse)
          await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/client-signup`, {
            params: {
              ...values,
              firstName,
              lastName,
              email,
              coach_id,
              info: info,
              client_id: signUpResponse.userId,
              old_client_id: queryParams.get("client_id"),
              // picture: s3Key,
            },
          });
        } catch (error) {
          // Handle sign-up errors
          toast.error(error.message);
          console.error("Error signing up:", error);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          // Verify the code using Amplify Auth API
          const { isSignUpComplete, nextStep } = await confirmSignUp({
            username: token_data.email,
            confirmationCode: values.verificationCode
          });
          // Authenticate the user after verification
          // await Auth.signIn(token_data.email, values.password);
          // await dispatch(
          //   signIn({ username: token_data.email, password: values.password })
          // ).unwrap();
          navigate("/authenticating");
        } catch (error) {
          console.error("Error verifying code:", error);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    (async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/verify-token`, {
        params: {
          token: queryParams.get("token"),
        },
      });
      console.log("response for token: ", response)
      if (response?.data?.decoded) {
        const {
          client_email,
          coach_id,
          info,
          client_first_name,
          client_last_name,
          invite_id
        } = response.data.decoded;
        setInfo(info);
        setFieldValue("email", client_email);
        setFieldValue("firstName", client_first_name);
        setFieldValue("lastName", client_last_name);
        setTokenData({
          email: client_email,
          coach_id: coach_id,
          firstName: client_first_name,
          lastName: client_last_name,
          invite_id: invite_id
        });
        console.log("token data set: ", token_data)
        setIsVerified(true);
      }
    })();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            background: "#EFF3F6",
            overflowY: "auto",
          }}
        >
          {!isVerified && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "black",
                textAlign: "center",
              }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width="50%"
                height="auto"
                style={{
                  width: "50%",
                  height: "auto",
                }}
              />
              <Typography
                sx={{
                  fontSize: "24px",
                  marginTop: "20px",
                }}
              >
                Token verifying...
              </Typography>
            </Box>
          )}
          {isVerified && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box
                display="flex"
                style={{
                  alignItems: "center",
                  width: "100%",
                  marginTop: "40px",
                  textAlign: "center",
                  justifyContent: "flex-start"
                }}
              >
                <Logo />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Typography
                  sx={{
                    color: {
                      xs: "#070728",
                      md: "black",
                    },
                    fontSize: {xs:"24px", md: "48px"},
                    fontWeight: 300,
                    fontFamily: "Poppins",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Welcome to your Portal
                </Typography>
              </Box>
              </Box>
              <ThemeProvider theme={theme}>
                <Container
                  component="main"
                  className="body"
                  sx={{ padding: 0 }}
                >
                  <CssBaseline />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      marginBottom: "50px",
                      marginLeft: { lg: "20px" },
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: 0,
                        margin: { xs: "0px 20px", lg: "-10px" },
                        minHeight: "calc(100vh - 198px)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "transparent",
                        borderRadius: "10px",
                        width: { xs: "100%", lg: "70%" },
                        zIndex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          width: "100%",
                          flexDirection: "column",
                          gap: "12px"
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            lineHeight: "1.17",
                            letterSpacing: "-0.02em",
                            color: "#22272F",
                          }}
                        >
                            Begin your journey with your coach today!
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            lineHeight: "1.17",
                            letterSpacing: "-0.02em",
                            color: "#22272F",
                          }}
                        >
                          Gain access to innovative features such as:
                          <List>
                            <ListItem>- Virtual Coach</ListItem>
                            <ListItem>- Analysis on coaching sessions</ListItem>
                            <ListItem>- Action tracking</ListItem>
                            <ListItem>- Goal tracking</ListItem>
                          </List>
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "48px",
                            lineHeight: "1.17",
                            letterSpacing: "-0.02em",
                            color: "#22272F",
                          }}
                        >
                          {values.isSignupStep && "Create Account"}
                        </Typography>
                      </Box>
                      <form onSubmit={handleSubmit}>
                        <Grid marginTop={2} container spacing={2}>
                          {values.isSignupStep && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <InputLabel
                                  htmlFor="component-helper"
                                  sx={{
                                    color: "#454F5F",
                                    fontSize: "12px",
                                    lineHeight: "1.33",
                                    fontWeight: "500",
                                    textTransform: "uppercase",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  First Name
                                </InputLabel>
                                <TextField
                                  name="firstName"
                                  required
                                  fullWidth
                                  id="firstName"
                                  disabled
                                  error={
                                    touched.firstName &&
                                    Boolean(errors.firstName)
                                  }
                                  {...getFieldProps("firstName")}
                                  InputProps={{
                                    style: {
                                      color: "black",
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      color: "black",
                                    },
                                  }}
                                  sx={{
                                    color: "#22272F",
                                    background: "white",
                                    borderRadius: "16px",
                                    width: "100%",
                                    border: "1px solid #C4CAD4",
                                    "&:before, &:after": {
                                      display: "none",
                                    },
                                    "& fieldset": {
                                      border: "none !important",
                                    },
                                  }}
                                />
                                {touched.firstName && errors.firstName && (
                                  <Typography variant="caption" color="error">
                                    {errors.firstName}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <InputLabel
                                  htmlFor="component-helper"
                                  sx={{
                                    color: "#454F5F",
                                    fontSize: "12px",
                                    lineHeight: "1.33",
                                    fontWeight: "500",
                                    textTransform: "uppercase",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  Last Name
                                </InputLabel>
                                <TextField
                                  required
                                  fullWidth
                                  id="lastName"
                                  name="lastName"
                                  disabled
                                  error={
                                    touched.lastName && Boolean(errors.lastName)
                                  }
                                  {...getFieldProps("lastName")}
                                  InputProps={{
                                    style: {
                                      color: "black",
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      color: "black",
                                    },
                                  }}
                                  sx={{
                                    color: "#22272F",
                                    background: "white",
                                    borderRadius: "16px",
                                    width: "100%",
                                    border: "1px solid #C4CAD4",
                                    "&:before, &:after": {
                                      display: "none",
                                    },
                                    "& fieldset": {
                                      border: "none !important",
                                    },
                                  }}
                                />
                                {touched.lastName && errors.lastName && (
                                  <Typography variant="caption" color="error">
                                    {errors.lastName}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="component-helper"
                                  sx={{
                                    color: "#454F5F",
                                    fontSize: "12px",
                                    lineHeight: "1.33",
                                    fontWeight: "500",
                                    textTransform: "uppercase",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  Email
                                </InputLabel>
                                <TextField
                                  required
                                  fullWidth
                                  id="email"
                                  name="email"
                                  disabled
                                  error={touched.email && Boolean(errors.email)}
                                  {...getFieldProps("email")}
                                  InputProps={{
                                    style: {
                                      color: "black",
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      color: "black",
                                    },
                                  }}
                                  sx={{
                                    color: "#22272F",
                                    background: "white",
                                    borderRadius: "16px",
                                    width: "100%",
                                    border: "1px solid #C4CAD4",
                                    "&:before, &:after": {
                                      display: "none",
                                    },
                                    "& fieldset": {
                                      border: "none !important",
                                    },
                                  }}
                                />
                                {touched.email && errors.email && (
                                  <Typography variant="caption" color="error">
                                    {errors.email}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="component-helper"
                                  sx={{
                                    color: "#454F5F",
                                    fontSize: "12px",
                                    lineHeight: "1.33",
                                    fontWeight: "500",
                                    textTransform: "uppercase",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  Password
                                </InputLabel>
                                <Box
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    error={
                                      touched.password &&
                                      Boolean(errors.password)
                                    }
                                    {...getFieldProps("password")}
                                    InputProps={{
                                      style: {
                                        color: "black",
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        color: "black",
                                      },
                                    }}
                                    sx={{
                                      color: "#22272F",
                                      background: "white",
                                      borderRadius: "16px",
                                      width: "100%",
                                      border: "1px solid #C4CAD4",
                                      "&:before, &:after": {
                                        display: "none",
                                      },
                                      "& fieldset": {
                                        border: "none !important",
                                      },
                                    }}
                                  />
                                  {showPassword === true ? (
                                    <VisibilityOffIcon
                                      color="#454F5F"
                                      sx={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "16px",
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowPassword(false);
                                        const passwordElement =
                                          document.getElementById("password");
                                        passwordElement.setAttribute(
                                          "type",
                                          "password"
                                        );
                                      }}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      color="#454F5F"
                                      sx={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "16px",
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowPassword(true);
                                        const passwordElement =
                                          document.getElementById("password");
                                        passwordElement.setAttribute(
                                          "type",
                                          "text"
                                        );
                                      }}
                                    />
                                  )}
                                </Box>
                                {touched.password && errors.password && (
                                  <Typography variant="caption" color="error">
                                    {errors.password}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.agreedToTerms}
                                      color="primary"
                                      InputProps={{
                                        style: {
                                          color: "black",
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          color: "black",
                                        },
                                      }}
                                      sx={{
                                        "& fieldset": {
                                          borderColor: "black !important",
                                        },
                                        "& svg": {
                                          color: "black",
                                        },
                                      }}
                                      onChange={() => {
                                        setFieldValue(
                                          "agreedToTerms",
                                          !values.agreedToTerms
                                        );
                                      }}
                                    />
                                  }
                                  label={
                                    <span
                                      style={{
                                        color: "black",
                                        marginRight: "10px",
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      I agree to the
                                      <Link
                                        to="/terms-signup"
                                        style={{
                                          textDecoration: "",
                                          color: "black",
                                        }}
                                      >
                                        Terms of Use
                                      </Link>
                                    </span>
                                  }
                                />
                              </Grid>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{
                                  width: "100%",
                                  padding: "18px 0",
                                  background: "white",
                                  border: "1px solid black",
                                  borderRadius: "43px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  boxShadow: "none",
                                  transition: "all 0.3s",
                                  "&:hover": {
                                    background: "black",
                                    boxShadow: "none",
                                    "& *": {
                                      color: "white",
                                    },
                                  },
                                  "&:disabled": {
                                    background: "#d3d3d3",
                                    color: "rgba(0, 0, 0, 0.26)",
                                    border: "none",
                                    cursor: "not-allowed",
                                  },
                                  marginLeft: "10px",
                                }}
                                disabled={
                                  !values.agreedToTerms ||
                                  !values.password ||
                                  !!errors.email
                                }
                              >
                                {loading ? (
                                  <CircularProgress
                                    size={20}
                                    sx={{
                                      marginRight: "5px",
                                      marginBottom: "-3px",
                                      color: "black",
                                    }}
                                  />
                                ) : (
                                  <Typography
                                    sx={{
                                      fontFamily: "Montserrat",
                                      color: "black",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      letterSpacing: "0.4px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {values.isSignupStep ? "Sign Up" : "Verify"}
                                  </Typography>
                                )}
                              </Button>
                            </>
                          )}
                          {!values.isSignupStep && (
                            <VerifyCode
                              email={values.email}
                              password={values.password}
                              setShowVerifyCode={setShowVerifyCode}
                            />
                          )}
                        </Grid>
                      </form>
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          lg: "flex",
                        },
                        marginLeft: "70px",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: animationData,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="100%"
                        height="auto"
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    </Box>
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}
