import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import CreateBigFiveAssessment from "hooks/assessmentHooks/theBigFiveQueries/useCreateBigFiveAssessment";
import AssignAssessmentModal from "components/Assessments/AssignAssessmentModal";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import AssessmentsTable from "components/Assessments/AssessmentsTable";
import {_IndividualAssessmentsFilter} from "components/Assessments/IndividualAssessmentsFilter"
import GeneralBigFiveScore from "components/Assessments/TheBigFive/GeneralBigFiveScore";
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const CoachAssessments = ({ user, userData }) => {
    const [selectedAssessment, setSelectedAssessment] = useState("The Big Five Personality Test");
    const [teamData, setTeamData] = useState([]);
    const [individualData, setIndividualData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openAssignToStudent, setOpenAssignToStudent] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [clientStatusArray, setClientStatusArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState("none");
    const [coacheeData, setCoacheeData] = useState([]);
    const [coachData, setCoachData] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [viewAssessment, setViewAssessment] = useState({})

    const navigate = useNavigate();

    const handleOpenAssignToStudentModal = () => {
        setOpenAssignToStudent(true);
      };
    

    const handleChange = (event) => {
        setSelectedAssessment(event.target.value);
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
    
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
          >
            {value === index && <Box>{children}</Box>}
          </div>
        );
      };
    
    const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
    };

    const collapsed = useSelector((state) => state.layout.collapsed);

    const fetchData = async () => {
        setLoading(true);
        try {
            const userId = user?.sub;
    
            // Fetch assessments where the user is the coachee (user_id: user.sub)
            const { data: coacheeAssessments } = await resourceClient.models.assessments.list({
                filter: {
                    user_id: { eq: userId },  // User is the coachee
                },
            });
    
            // Fetch assessments where the user is the coach (coach_id: user.sub)
            const { data: coachAssessments } = await resourceClient.models.assessments.list({
                filter: {
                    coach_id: { eq: userId },  // User is the coach
                },
            });

    
            // Remove assessments where the user is both the coach and the coachee
            const filteredCoachData = coachAssessments.filter(assessment => assessment.user_id !== userId);
    
            // Function to compare assessments based on updatedAt, fallback to createdAt if updatedAt is missing
            const compareByDate = (a, b) => {
                const dateA = new Date(a.updatedAt || a.createdAt);
                const dateB = new Date(b.updatedAt || b.createdAt);
                return dateB - dateA;  // Sort in descending order, latest date first
            };
    
            // Sort coachee and coach data
            const sortedCoacheeData = coacheeAssessments.sort(compareByDate);
            const sortedCoachData = filteredCoachData.sort(compareByDate);
    
            // Extract unique user_ids from both coachee and coach data
            const uniqueUserIds = [
                ...new Set([
                    ...sortedCoacheeData.map(assessment => assessment.coach_id),  // From coachee assessments
                    ...sortedCoachData.map(assessment => assessment.user_id)     // From coach assessments
                ])
            ];

            console.log("unique: ", uniqueUserIds)
    
            // Fetch user names using the unique user_ids
            if (uniqueUserIds.length > 0) {
                const { data: userNames } = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-name-by-user-id`, {
                    userIds: uniqueUserIds
                });
    
                // Create a map of user_id to full_name for easier lookup
                const userNamesMap = userNames.reduce((acc, user) => {
                    acc[user.user_id] = user.name;
                    return acc;
                }, {});
    
                // Attach coach_name to coachee assessments
                const coacheeDataWithNames = sortedCoacheeData.map(assessment => ({
                    ...assessment,
                    user_name: userNamesMap[assessment.user_id] || 'Unknown Coachee'
                }));
    
                // Attach user_name to coach assessments
                const coachDataWithNames = sortedCoachData.map(assessment => ({
                    ...assessment,
                    user_name: userNamesMap[assessment.user_id] || 'Unknown Coachee'
                }));
    
                // Update the state with assessments having attached names
                setCoacheeData(coacheeDataWithNames);
                setCoachData(coachDataWithNames);
            } else {
                // If no unique user IDs, set sorted data without names
                setCoacheeData(sortedCoacheeData || []);
                setCoachData(sortedCoachData || []);
            }

            console.log("coachee data: ", coacheeData)
    
        } catch (error) {
            console.error("Error fetching client data:", error);
        } finally {
            setLoading(false);
        }
    };    


    const fetchClientData = async() => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-by-coach-test`,
                {
                  params: {
                    coach_id: user.sub,
                  },
                }
              );
          
              const { client_data = [], client_status_objects = [] } = response.data || {};
          
              if (client_data.length === 0 && client_status_objects.length === 0) {
                setLoading(false);
                setClientData([]);
                setClientStatusArray([]);
                return;
              }
          
              // Process client data
              const processedClientData = client_data.map((row) => ({
                ...row,
                avatar: row.avatar
                  ? `https://d7joen7tb0xwx.cloudfront.net/public/${row.avatar}`
                  : null,
              }));
          
              const processedClientStatusArray = client_status_objects.map((row) => ({
                ...row,
                avatar: row.avatar
                  ? `https://d7joen7tb0xwx.cloudfront.net/public/${row.avatar}`
                  : null,
              }));
          
              setClientData(processedClientData);
              setClientStatusArray(processedClientStatusArray);
        } catch (error) {
            console.log("error in fetching coachees: ", error)
        }
    }
    
    useEffect(() => {
        if (user) {
            fetchData();
            fetchClientData();
        }
    }, [user]);    
    
      

    // Filter the coach or coachee data based on the selected user ID
    const filteredCoachData = selectedUserId === "none" 
    ? coachData 
    : coachData.filter((assessment) => assessment.user_id === selectedUserId);

    const filteredCoacheeData = selectedUserId === "none"
    ? coacheeData
    : coacheeData.filter((assessment) => assessment.user_id === selectedUserId);

    const coacheeDataOptions = [
        { key: "Name", value: "none" }, // Default option
        ...filteredCoacheeData // Ensure you're using filteredCoacheeData for coachee options
          .map((assessment) => ({
            key: assessment.user_name || "Unknown", // Display name
            value: assessment.user_id,                      // Use user_id as the value
          }))
          .filter((item, index, self) =>
            self.findIndex((t) => t.value === item.value) === index // Ensure uniqueness by user_id
          ),
    ];

    const coachDataOptions = [
        { key: "Name", value: "none" }, // Default option
        ...filteredCoachData // Ensure you're using filteredCoacheeData for coachee options
          .map((assessment) => ({
            key: assessment.user_name || "Unknown", // Display name
            value: assessment.user_id,                      // Use user_id as the value
          }))
          .filter((item, index, self) =>
            self.findIndex((t) => t.value === item.value) === index // Ensure uniqueness by user_id
          ),
    ];
      

    return (
        <GlobalPageComponent user={user} accessTo={userData.accessTo}>
            <ClientLimitReached user={user} userData={userData} />
                <AssignAssessmentModal
                    user={user}
                    userData={userData}
                    open={openAssignToStudent}
                    clientData={clientData}
                    assessment={selectedAssessment}
                    fetchData={fetchData}
                    setOpenAssignToStudent={setOpenAssignToStudent}
                />
                <Box
                    sx={{
                    backgroundColor: "#EFF3F6",
                    width: "100%",
                    height: "100%",
                    padding: {
                        xs: "16px",
                        md: "32px 40px",
                    },
                    display: {
                        xs: !collapsed ? "none" : "block",
                    },
                    overflowY: "auto",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                            }}
                        >
                            Assessments
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            height: "88vh",
                        }}
                    >
                        <Box
                            sx={{
                                background: "white",
                                borderRadius: "40px",
                                padding: "24px",
                                display: "flex",
                                flexDirection: {xs: "column", md: "row"},
                                width: "100%",
                                alignItems: "center",
                                gap: {xs: "16px", md: "0px"}
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    width: {xs:"90%", md: "40%"}
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#8F8F8F",
                                        fontSize: "18px",
                                        fontFamily: "Lexend",
                                        fontWeight: 400,
                                    }}
                                >
                                    The assessments page is your portal into managing the teams and the individual coachees that you coach.
                                    You can assign tests, check the scores, and even compare results!
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem color="#EFF3F6" sx={{marginLeft: "30px"}}/>
                            <Box
                                sx={{
                                    width: {xs: "100%", md:"40%"},
                                    display: "flex", // Use flexbox for alignment
                                    justifyContent: "center", // Center horizontally
                                    alignItems: "center", // Center vertically
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex", // Flexbox to center content
                                        justifyContent: "center", // Horizontal centering
                                        alignItems: "center", // Vertical centering
                                        height: "75px", // Set a height to center content vertically (optional, can adjust)
                                        width: "70%"
                                        }}
                                >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "black",
                                            fontSize: "18px",
                                            fontFamily: "Lexend",
                                            fontWeight: 400,
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        {selectedAssessment ? selectedAssessment : "Please select an assessment"}
                                    </Typography>
                                    {/* <FormControl sx={{ width: 150 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedAssessment ?? ""}
                                            onChange={handleChange}
                                            open={open}              // Controls the open state of Select
                                            onOpen={handleOpen}       // Set open to true
                                            onClose={handleClose}     // Set open to false
                                            displayEmpty
                                            autoWidth
                                            renderValue={(selected) => {
                                                return selected ? "Change" : "Select";
                                            }}
                                            inputProps={{
                                                MenuProps: {
                                                    MenuListProps: {
                                                        sx: {
                                                            backgroundColor: 'white',
                                                            color: "black",
                                                        }
                                                    }
                                                }
                                            }}
                                            IconComponent={(props) => (
                                                <Box
                                                    onClick={open ? handleClose : handleOpen}  // Handle open/close on icon click
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {open ? <KeyboardArrowUpIcon sx={{ color: "black" }} /> : <KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                                </Box>
                                            )}
                                            sx={{
                                                color: "black", // Text color
                                                height: "32px", // Adjust height here
                                                padding: "0 6px", // Adjust padding
                                                fontSize: "14px", // Optional: Adjust font size to match height
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Border color
                                                    borderRadius: "40px", // Custom border radius
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Hover border color
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Focused border color
                                                },
                                            }}
                                        >
                                            <MenuItem value="The Big Five Personality Test">The Big Five Personality Test</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" flexItem color="#EFF3F6" sx={{marginLeft: "0px"}}/>

                            <Box
                                sx={{
                                    width: {xs: "100%", md:"40%"},
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: {xs: "12px", md:"8px"},
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                        width: "60%"
                                    }}
                                >
                                    <Button
                                        sx={{
                                            background: "white",
                                            border: "1px solid black",
                                            padding: "10px",
                                            color: "black",
                                            borderRadius: "50px",
                                            width: "60%",
                                            "&:disabled": {
                                                background: "#d3d3d3", 
                                                color: "#B7B7B7",
                                                border: "none",
                                                cursor: "not-allowed",
                                            },
                                        }}
                                        disabled={selectedAssessment === "" || !selectedAssessment}
                                        onClick={() =>
                                            navigate(`/platform/coach/assessments/${selectedAssessment}/${user.sub}`)
                                        }
                                        >
                                        <Typography
                                            sx={{
                                                fontFamily: "Lexend",
                                                fontSize: "10px",
                                                fontWeight: "700",
                                                textTransform: "uppercase",
                                                letterSpacing: 2,
                                            }}
                                        >   
                                            Take Test
                                        </Typography>
                                    </Button>
                                    <Button
                                        sx={{
                                            background: "white",
                                            border: "1px solid black",
                                            padding: "10px",
                                            color: "black",
                                            borderRadius: "50px",
                                            width: "60%",
                                            "&:disabled": {
                                                background: "#d3d3d3", 
                                                color: "#B7B7B7",
                                                border: "none",
                                                cursor: "not-allowed",
                                            },
                                        }}
                                        disabled={
                                            !selectedAssessment || // Check if the selected assessment is valid
                                            !user?.sub || // Check if user is authenticated and has a user_id
                                            filteredCoacheeData.length === 0 // Check if there are any assessments available for the coachee
                                        }
                                        onClick={() => {
                                            // Switch to the "Student" tab (index 1)
                                            setTabIndex(1);
                                            
                                            // Set the filter to the current user's user_id
                                            setSelectedUserId(user.sub);
                                          
                                            // Find the first assessment in the filtered coachee data that is marked as completed
                                            const firstCompletedAssessment = filteredCoacheeData.find(assessment => assessment.completed) || null;
                                            
                                            // Set the viewAssessment state to the first completed assessment if it exists
                                            if (firstCompletedAssessment) {
                                              setViewAssessment(firstCompletedAssessment);
                                            }
                                          }}
                                          
                                        >
                                        <Typography
                                            sx={{
                                                fontFamily: "Lexend",
                                                fontSize: "10px",
                                                fontWeight: "700",
                                                textTransform: "uppercase",
                                                letterSpacing: 2,
                                            }}
                                        >   
                                            My Results
                                        </Typography>
                                    </Button>
                                </Box>
                                <Button
                                    sx={{
                                        background: "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                                        padding: "10px",
                                        borderRadius: "50px",
                                        width: "60%",
                                        "&:disabled": {
                                            background: "#d3d3d3", 
                                            color: "#B7B7B7",
                                            border: "none",
                                            cursor: "not-allowed",
                                          },
                                    }}
                                    disabled={teams.length === 0}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Lexend",
                                            fontSize: "10px",
                                            fontWeight: "700",
                                            color: "white",
                                            textTransform: "uppercase",
                                            letterSpacing: 2
                                        }}
                                    >   
                                        Assign to a Team
                                    </Typography>
                                </Button>
                                <Button
                                    sx={{
                                        background: "white",
                                        border: "1px solid black",
                                        color: "black",
                                        padding: "10px",
                                        borderRadius: "50px",
                                        width: "60%",
                                        "&:disabled": {
                                            background: "#d3d3d3", 
                                            color: "#B7B7B7",
                                            border: "none",
                                            cursor: "not-allowed",
                                          },
                                    }}
                                    disabled={selectedAssessment === "" || !selectedAssessment}
                                    onClick={handleOpenAssignToStudentModal}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Lexend",
                                            fontSize: "10px",
                                            fontWeight: "700",
                                            textTransform: "uppercase",
                                            letterSpacing: 2
                                        }}
                                    >   
                                        Assign to a Coachee
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: "8px",
                            width: "100%",
                            height: "100%",
                            flexGrow: 1,
                        }}
                    >
                        <Box
                            sx={{
                                background: "white",
                                display: "flex",
                                flexDirection: "column",
                                width: { xs: "100%", md: "50%" },
                                borderRadius: "40px",
                                padding: "24px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "black",
                                        fontSize: "28px",
                                        fontFamily: "Poppins",
                                        fontWeight: 300,
                                        wordWrap: "break-word"
                                    }}
                                >
                                    Individuals
                                </Typography>
                                {/* INSERT FILTER HERE */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px"
                                    }}
                                >
                                <Tabs value={tabIndex} onChange={handleTabChange}>
                                    <Tab sx={tabStyle} label="Coach" {...a11yProps(0)} />
                                    <Tab sx={tabStyle} label="Coachee" {...a11yProps(0)} />
                                </Tabs>
                                <_IndividualAssessmentsFilter
                                    value={selectedUserId}
                                    handleChange={setSelectedUserId} // Update selectedUserId state when filter changes
                                    items={tabIndex === 0 ? coachDataOptions : coacheeDataOptions}
                                />
                                </Box>
                            </Box>
                            {coachData || coacheeData ? (
                                <Box
                                    sx={{
                                        height: "100%",
                                        flexGrow: 1, // Ensures the table grows and fills available height
                                        overflow: "hidden", // Prevents content overflow outside the box
                                    }}
                                >
                                    <TabPanel value={tabIndex} index={0}>
                                    <AssessmentsTable 
                                        user={user}
                                        individualData={filteredCoachData}  // Filtered coach data
                                        loading={loading} 
                                        setViewAssessment={setViewAssessment} 
                                        viewAssessment={viewAssessment}
                                    />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={1}>
                                    <AssessmentsTable
                                        user={user} 
                                        individualData={filteredCoacheeData}  // Filtered coachee data
                                        loading={loading} 
                                        setViewAssessment={setViewAssessment} 
                                        viewAssessment={viewAssessment}
                                    />
                                    </TabPanel>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "black",
                                            fontSize: "18px",
                                            fontFamily: "Poppins",
                                            fontWeight: 300,
                                            width: "60%"
                                        }}
                                    >
                                        {loading ? <CircularProgress /> : 'Please assign the assessment to a coachee using the button on the top section.'}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                background: "white",
                                display: "flex",
                                flexDirection: "column",
                                width: { xs: "100%", md: "50%" },
                                borderRadius: "40px",
                                padding: "24px",
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                <Typography
                                    sx={{
                                        color: "black",
                                        fontSize: "28px",
                                        fontFamily: "Poppins",
                                        fontWeight: 300,
                                        wordWrap: "break-word"
                                    }}
                                >
                                    Results
                                </Typography>
                                {/* INSERT FILTER HERE */}
                            </Box>
                                <Box
                                    sx={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        marginTop: "50px"
                                    }}
                                >
                                    <Box sx={{display: "flex", width: "100%", flexDirection: "column", overflow: "auto"}}>
                                        <GeneralBigFiveScore assessmentData={viewAssessment}/>
                                    </Box>
                                    <Box sx={{display: "flex", flexDirection: "column", gap: "20px", alignItems: "center", width: "100%", textAlign: "center"}}>
                                        <Typography
                                            sx={{
                                                color: 'black', fontSize: 18, fontFamily: 'Lexend', fontWeight: 400, width: "60%"
                                            }}
                                        >
                                            {viewAssessment && viewAssessment.id 
                                                ? ''
                                                : 'The chart above will showcase your score’s overview, after you complete a test or selecting one from the list.'
                                            }
                                            
                                        </Typography>
                                        {viewAssessment && viewAssessment.id && (
                                            <Button
                                                sx={{
                                                    background: "white",
                                                    border: "1px solid black",
                                                    color: "black",
                                                    padding: "10px",
                                                    borderRadius: "50px",
                                                    width: "40%",
                                                    "&:disabled": {
                                                        background: "#d3d3d3", 
                                                        color: "#B7B7B7",
                                                        border: "none",
                                                        cursor: "not-allowed",
                                                    },
                                                }}
                                                onClick={() => navigate(`/platform/coach/assessments/view/individual-breakdown/${viewAssessment.id}/${viewAssessment.user_id}`)}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Lexend",
                                                        fontSize: "10px",
                                                        fontWeight: "700",
                                                        textTransform: "uppercase",
                                                        letterSpacing: 2
                                                    }}
                                                >
                                                    Expand Score
                                                </Typography>
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </GlobalPageComponent>
    )
}

export default CoachAssessments;

const tabStyle = {
    color: "black",
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 1.5,
    fontFamily: "Lexend",
    borderBottom: "1px solid #E8E8E8",
    "&.Mui-selected": {
      borderBottom: "1px solid #000",
    },
  };