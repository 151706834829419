import { generateClient } from "aws-amplify/data";
import { v4 as uuidv4 } from 'uuid';

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const CreateBigFiveAssessment = async ({ user, team_id, coach_id }) => {
    const id = uuidv4();
    //first check if one exists
    const { data, errors } = await resourceClient.models.assessments.listAssessmentsByUser_id({
        variables: {
            input: {
                user_id: user.Sub,
                filter: {
                    test_type: {
                        eq: "The Big Five Personality Test"
                    }
                }
            }
        }
    });
    
    console.log("Data: ", data);
    console.log("Errors: ", errors);

    if(data){
        return data;
    }else{
        //create new test
        const { data, errors } = await resourceClient.models.assessments.createAssessments({
            input: {
                id: id, // Use the generated UUID as the ID
                user_id: user.Sub,
                test_type: "The Big Five Personality Test",
                created_at: new Date().toISOString(), // Example: adding the current date/time
                completed: false
            }
        });
    }
};

export default CreateBigFiveAssessment;
