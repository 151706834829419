import { useState, useCallback } from 'react';
import { generateClient } from 'aws-amplify/data';

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({authMode: "userPool"});

export const useStripeCheckoutVirtualCoach = () => {
  const [productId, setProductIdVirtualCoach] = useState("price_1OXaQyLKxpQPkYt5x7DAHQl0");
  const [loadingStripeCheckoutVirtualCoach, setLoadingStripeCheckoutVirtualCoach] = useState(false);
  const [errorWithStripeCheckoutVirtualCoach, setErrorWithStripeCheckoutVirtualCoach] = useState(null);

  const handleStripeCheckoutVirtualCoach = useCallback(async () => {
    setLoadingStripeCheckoutVirtualCoach(true);
    const stringifiedInput = JSON.stringify({ selected_product_id: productId });
    try {
      // const response = await API.graphql({
      //   query: fetchStripeCheckout,
      //   authMode: "AMAZON_COGNITO_USER_POOLS",
      //   variables: {
      //     input: stringifiedInput,
      //   },
      // });
      const { data, errors } = await resourceClient.queries.fetchStripeCheckout({
        input: stringifiedInput,
      });

      if (errors && errors.length > 0) {
        console.error("GraphQL query returned errors:", errors);
        setErrorWithStripeCheckoutVirtualCoach(new Error("Failed to initiate Stripe checkout."));
        return;
      }


      const session = JSON.parse(data);
      window.location.href = session.sessionId; // Redirect to Stripe Checkout
    } catch (error) {
      console.error("Error running AlterCall checkout session:", error);
      setErrorWithStripeCheckoutVirtualCoach(error);
    } finally {
      setLoadingStripeCheckoutVirtualCoach(false);
    }
  }, [productId]);

  return { handleStripeCheckoutVirtualCoach, setProductIdVirtualCoach, loadingStripeCheckoutVirtualCoach, errorWithStripeCheckoutVirtualCoach };
};
