import React from 'react';
import { Box, Button, Divider, Typography } from "@mui/material";

import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


const CoachTeamBreakdown = ({ user, userData }) => {
    const navigate = useNavigate();
    const collapsed = useSelector((state) => state.layout.collapsed);
    const tagNames = ["GENERAL", "NEUROTICISM", "EXTRAVERSION", "OPENNESS", "AGREEABLENESS", "CONSCIOUSNESS"]
    const [selectedTag, setSelectedTag] = useState(tagNames[0]);

    const { userId } = useParams(); // Extracting the userId params from the URL
    const score = 7; // Ale, I declared this variable to simply map the coloured circles to showcase score. Feel free to use the database data.

    // Ale, Fetch the user data from the database using the userId here -----

    return (
        <GlobalPageComponent user={user} accessTo={userData.accessTo}>
            <ClientLimitReached user={user} userData={userData} />

            <Box
                sx={{
                    backgroundColor: "#EFF3F6",
                    width: "100%",
                    height: "auto",
                    padding: {
                        xs: "16px",
                        md: "32px 40px",
                    },
                    display: {
                        xs: !collapsed ? "none" : "block",
                    },
                    overflowY: "auto",
                }}
            >
                <Box>

                    <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                        }}
                    >
                        Assessments
                    </Typography>

                    <Box
                        sx={{
                            background: "white",
                            borderRadius: "40px",
                            padding: "24px",
                            width: "100%",
                            height: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    md: "space-between",
                                },
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                },
                                gap: {
                                    xs: "20px",
                                    md: "0px",
                                },
                                alignItems: "center",
                            }}
                        >

                            <Typography
                                sx={{
                                    color: "black",
                                    fontFamily: "Poppins",
                                    fontSize: "24px",
                                    fontWeight: 300,
                                }}
                            >Team Breakdown</Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: {
                                        xs: 'start',
                                        md: "center",
                                    },
                                    justifyContent: "space-between",
                                    border: "1px solid #D9D9D9",
                                    height: {
                                        xs: "auto",
                                        md: '45px',
                                    },
                                    paddingX: '10px',
                                    paddingY: '10px',
                                    borderRadius: "20px",
                                    flexDirection: {
                                        xs: "column",
                                        md: "row",
                                    },
                                    gap: {
                                        xs: "20px",
                                        md: "1px",
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#E3E3E3",
                                            borderRadius: "50%",
                                            width: "28px",
                                            height: "28px",
                                        }}>
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: "black",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 300,
                                        }} x
                                    >ABC Corporation</Typography>
                                </Box>


                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#E3E3E3",
                                            borderRadius: "50%",
                                            width: "28px",
                                            height: "28px",
                                        }}>

                                        <img
                                            src="image src goes here"
                                            alt='alt here'
                                            style={{
                                                width: '28px',
                                                height: '28px',
                                                borderRadius: '50%'
                                            }}
                                        />

                                    </Box>
                                    <Typography
                                        sx={{
                                            color: "black",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 300,
                                        }} x
                                    >Name Here</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', gap: '8px' }}>
                                    <Box sx={{ display: 'flex', gap: '6px' }}>
                                        <Typography sx={{ color: '#B9B9B9', font: '10px', fontWeight: '500' }}>DATE:</Typography>
                                        <Typography sx={{ color: '#000000', font: '10px', fontWeight: '500' }}>8/29</Typography>
                                    </Box>

                                    <Typography sx={{ color: '#000000', font: '10px', fontWeight: '500' }}>|</Typography>

                                    <Box sx={{ display: 'flex', gap: '6px' }}>
                                        <Typography sx={{ color: '#B9B9B9', font: '10px', fontWeight: '500' }}>TIME:</Typography>
                                        <Typography sx={{ color: '#000000', font: '10px', fontWeight: '500' }}>8:29</Typography>
                                    </Box>
                                </Box>

                            </Box>


                            <Button
                                onClick={() => {
                                    navigate(-1);
                                }}
                                variant='outlined'
                                sx={{
                                    width: '90px',
                                    height: '35px',
                                    borderRadius: '20px',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    border: '1px solid black',
                                }}
                            >Back
                            </Button>

                        </Box>

                        <Divider color="#D9D9D9" sx={{ marginY: '30px' }} />

                        <Box sx={{ paddingX: '30px' }}>

                            <Box
                                sx={{
                                    width: "70%",
                                    marginX: "auto",
                                    marginBottom: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "20px",
                                    flexDirection: {
                                        xs: "column",
                                        md: "row",
                                    }
                                }}
                            >
                                {
                                    tagNames.map((name, index) => <Box key={index}
                                        onClick={() => setSelectedTag(name)}
                                        sx={{
                                            width: '160px',
                                            height: '35px',
                                            borderRadius: "60px",
                                            border: selectedTag === name ? "2px solid #EF406E" : "2px solid #E3E3E3",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",

                                        }}>
                                        <Typography sx={{
                                            color: selectedTag === name ? "#EF406E" : "#E3E3E3",
                                            fontFamily: "Lexend",
                                            fontSize: "10px",
                                            fontWeight: 700,
                                            linHeight: "150%",
                                            letterSpacing: "2px",
                                            textTransform: "uppercase",
                                        }}>
                                            {name}
                                        </Typography>
                                    </Box>)
                                }
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                height: {
                                    xs: "auto",
                                    md: "300px",
                                },
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                }
                            }}>
                                <Box sx={{
                                    flex: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: "#EF406E",
                                                fontFamily: "Lexend",
                                                fontSize: {
                                                    xs: '35px',
                                                    md: "44px"
                                                },
                                                fontWeight: 400,
                                                lineHeight: "160%",
                                            }}
                                        >
                                            {selectedTag}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Typography sx={{
                                            color: "#0C0C0C",
                                            fontFamily: "Lexend",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "160%"
                                        }}>
                                            Openness to Experience describes a dimension of cognitive style that distinguishes imaginative, creative people from down-to-earth, conventional people.
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '50%',
                                            marginY: {
                                                xs: '20px',
                                                md: '0px'
                                            },
                                            width: {
                                                xs: '80px',
                                                md: '142px'
                                            },
                                            height: {
                                                xs: '80px',
                                                md: '142px'
                                            },
                                            backgroundColor: '#EF406E',
                                        }}
                                    >
                                        <Typography sx={{
                                            margin: 0,
                                            padding: 0,
                                            color: "#FFFFFF",
                                            fontFamily: "Lexend",
                                            fontSize: "44px",
                                            fontWeight: 400,
                                            lineHeight: "160%",
                                        }}>93</Typography>
                                    </Box>

                                </Box>

                            </Box>

                            <Box sx={{
                                backgroundColor: '#EFF3F6',
                                width: '100%',
                                height: '7px',
                                borderRadius: '10px',
                                marginY: {
                                    xs: '30px',
                                }
                            }}>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: {
                                    xs: '40px',
                                    md: '20px',
                                },
                                marginTop: '30px',
                            }}>

                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: {
                                        xs: 'auto',
                                        md: '200px'
                                    },
                                    flexDirection: {
                                        xs: 'column',
                                        md: 'row'
                                    },
                                    gap: {
                                        xs: '20px',
                                        md: '0px'
                                    }
                                }}>
                                    <Box sx={{
                                        flex: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        gap: '13px',
                                    }}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#9865CF",
                                                    fontFamily: "Lexend",
                                                    fontSize: "24px",
                                                    fontWeight: 400,
                                                    lineHeight: "160%",
                                                }}
                                            >
                                                Imagination
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography sx={{
                                                color: "#0C0C0C",
                                                fontFamily: "Lexend",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "160%"
                                            }}>
                                                To imaginative individuals, the real world is often too plain and ordinary. High scorers on this scale use fantasy as a way of creating a richer, more interesting world.
                                                Low scorers are on this scale are more oriented to facts than fantasy.
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        paddingX: '20px',
                                        rowGap: '10px',
                                    }}>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            {Array.from(Array(score)).map((item, index) => <Box key={index} sx={{
                                                width: {
                                                    xs: "18px",
                                                    md: '27px'
                                                },
                                                height: {
                                                    xs: "18px",
                                                    md: '27px'
                                                },
                                                borderRadius: '50%',
                                                backgroundColor: '#9865CF',
                                            }}>
                                            </Box>)}

                                            {
                                                Array.from(Array(10 - score)).map((item, index) => <Box key={index} sx={{
                                                    width: {
                                                        xs: "18px",
                                                        md: '27px'
                                                    },
                                                    height: {
                                                        xs: "18px",
                                                        md: '27px'
                                                    },
                                                    borderRadius: '50%',
                                                    backgroundColor: '#EFF3F6',
                                                }}>
                                                </Box>)
                                            }

                                        </Box>

                                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <Typography sx={{
                                                color: "#0C0C0C",
                                                fontFamily: "Lexend",
                                                fontSize: "14px",
                                            }}>How to interpret your score </Typography>
                                            <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.49902 1.99089C6.73298 1.99089 5.97444 2.14177 5.2667 2.43492C4.55897 2.72807 3.91591 3.15775 3.37423 3.69943C2.83256 4.2411 2.40288 4.88417 2.10973 5.5919C1.81657 6.29963 1.66569 7.05817 1.66569 7.82422C1.66569 8.59026 1.81657 9.34881 2.10973 10.0565C2.40288 10.7643 2.83256 11.4073 3.37423 11.949C3.91591 12.4907 4.55897 12.9204 5.2667 13.2135C5.97444 13.5067 6.73298 13.6576 7.49902 13.6576C9.04612 13.6576 10.5299 13.043 11.6238 11.949C12.7178 10.855 13.3324 9.37132 13.3324 7.82422C13.3324 6.27712 12.7178 4.79339 11.6238 3.69943C10.5299 2.60547 9.04612 1.99089 7.49902 1.99089ZM-0.000976562 7.82422C-0.000976562 5.83509 0.7892 3.92744 2.19572 2.52092C3.60225 1.1144 5.5099 0.324219 7.49902 0.324219C9.48815 0.324219 11.3958 1.1144 12.8023 2.52092C14.2088 3.92744 14.999 5.83509 14.999 7.82422C14.999 9.81334 14.2088 11.721 12.8023 13.1275C11.3958 14.534 9.48815 15.3242 7.49902 15.3242C5.5099 15.3242 3.60225 14.534 2.19572 13.1275C0.7892 11.721 -0.000976562 9.81334 -0.000976562 7.82422ZM6.66569 4.49089C6.66569 4.26987 6.75349 4.05791 6.90977 3.90163C7.06605 3.74535 7.27801 3.65755 7.49902 3.65755L7.50736 3.65755C7.72837 3.65755 7.94033 3.74535 8.09661 3.90163C8.25289 4.05791 8.34069 4.26987 8.34069 4.49089C8.34069 4.7119 8.25289 4.92386 8.09661 5.08014C7.94033 5.23642 7.72837 5.32422 7.50736 5.32422L7.49902 5.32422C7.27801 5.32422 7.06605 5.23642 6.90977 5.08014C6.75349 4.92386 6.66569 4.7119 6.66569 4.49089ZM6.67402 11.1576C6.67402 11.3786 6.76182 11.5905 6.9181 11.7468C7.07438 11.9031 7.28634 11.9909 7.50736 11.9909C7.72837 11.9909 7.94033 11.9031 8.09661 11.7468C8.25289 11.5905 8.34069 11.3786 8.34069 11.1576L8.34069 6.99089C8.34069 6.76987 8.25289 6.55791 8.09661 6.40163C7.94033 6.24535 7.72837 6.15755 7.50736 6.15755C7.28634 6.15755 7.07438 6.24535 6.9181 6.40163C6.76182 6.55791 6.67402 6.76987 6.67402 6.99089L6.67402 11.1576Z" fill="#8F8F8F" />
                                            </svg>
                                        </Box>

                                    </Box>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: {
                                        xs: 'auto',
                                        md: '200px'
                                    },
                                    flexDirection: {
                                        xs: 'column',
                                        md: 'row'
                                    },
                                    gap: {
                                        xs: '20px',
                                        md: '0px'
                                    }
                                }}>
                                    <Box sx={{
                                        flex: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        gap: '13px',
                                    }}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#337BE8",
                                                    fontFamily: "Lexend",
                                                    fontSize: "24px",
                                                    fontWeight: 400,
                                                    lineHeight: "160%",
                                                }}
                                            >
                                                Artistic Abilities
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography sx={{
                                                color: "#0C0C0C",
                                                fontFamily: "Lexend",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "160%"
                                            }}>
                                                High scorers on this scale love beauty, both in art and in nature. They become easily involved and absorbed in artistic and natural events. They are not necessarily artistically trained nor talented, although many will be. The defining features of this scale are interest in, and appreciation of natural and artificial beauty. Low scorers lack aesthetic sensitivity and interest in the arts.
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        paddingX: '20px',
                                        rowGap: '10px',
                                    }}>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            {Array.from(Array(score)).map((item, index) => <Box key={index} sx={{
                                                width: {
                                                    xs: "18px",
                                                    md: '27px'
                                                },
                                                height: {
                                                    xs: "18px",
                                                    md: '27px'
                                                },
                                                borderRadius: '50%',
                                                backgroundColor: '#337BE8',
                                            }}>
                                            </Box>)}

                                            {
                                                Array.from(Array(10 - score)).map((item, index) => <Box key={index} sx={{
                                                    width: {
                                                        xs: "18px",
                                                        md: '27px'
                                                    },
                                                    height: {
                                                        xs: "18px",
                                                        md: '27px'
                                                    },
                                                    borderRadius: '50%',
                                                    backgroundColor: '#EFF3F6',
                                                }}>
                                                </Box>)
                                            }

                                        </Box>

                                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <Typography sx={{
                                                color: "#0C0C0C",
                                                fontFamily: "Lexend",
                                                fontSize: "14px",
                                            }}>How to interpret your score </Typography>
                                            <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49902 1.99089C6.73298 1.99089 5.97444 2.14177 5.2667 2.43492C4.55897 2.72807 3.91591 3.15775 3.37423 3.69943C2.83256 4.2411 2.40288 4.88417 2.10973 5.5919C1.81657 6.29963 1.66569 7.05817 1.66569 7.82422C1.66569 8.59026 1.81657 9.34881 2.10973 10.0565C2.40288 10.7643 2.83256 11.4073 3.37423 11.949C3.91591 12.4907 4.55897 12.9204 5.2667 13.2135C5.97444 13.5067 6.73298 13.6576 7.49902 13.6576C9.04612 13.6576 10.5299 13.043 11.6238 11.949C12.7178 10.855 13.3324 9.37132 13.3324 7.82422C13.3324 6.27712 12.7178 4.79339 11.6238 3.69943C10.5299 2.60547 9.04612 1.99089 7.49902 1.99089ZM-0.000976562 7.82422C-0.000976562 5.83509 0.7892 3.92744 2.19572 2.52092C3.60225 1.1144 5.5099 0.324219 7.49902 0.324219C9.48815 0.324219 11.3958 1.1144 12.8023 2.52092C14.2088 3.92744 14.999 5.83509 14.999 7.82422C14.999 9.81334 14.2088 11.721 12.8023 13.1275C11.3958 14.534 9.48815 15.3242 7.49902 15.3242C5.5099 15.3242 3.60225 14.534 2.19572 13.1275C0.7892 11.721 -0.000976562 9.81334 -0.000976562 7.82422ZM6.66569 4.49089C6.66569 4.26987 6.75349 4.05791 6.90977 3.90163C7.06605 3.74535 7.27801 3.65755 7.49902 3.65755L7.50736 3.65755C7.72837 3.65755 7.94033 3.74535 8.09661 3.90163C8.25289 4.05791 8.34069 4.26987 8.34069 4.49089C8.34069 4.7119 8.25289 4.92386 8.09661 5.08014C7.94033 5.23642 7.72837 5.32422 7.50736 5.32422L7.49902 5.32422C7.27801 5.32422 7.06605 5.23642 6.90977 5.08014C6.75349 4.92386 6.66569 4.7119 6.66569 4.49089ZM6.67402 11.1576C6.67402 11.3786 6.76182 11.5905 6.9181 11.7468C7.07438 11.9031 7.28634 11.9909 7.50736 11.9909C7.72837 11.9909 7.94033 11.9031 8.09661 11.7468C8.25289 11.5905 8.34069 11.3786 8.34069 11.1576L8.34069 6.99089C8.34069 6.76987 8.25289 6.55791 8.09661 6.40163C7.94033 6.24535 7.72837 6.15755 7.50736 6.15755C7.28634 6.15755 7.07438 6.24535 6.9181 6.40163C6.76182 6.55791 6.67402 6.76987 6.67402 6.99089L6.67402 11.1576Z" fill="#8F8F8F" />
                                            </svg>
                                        </Box>

                                    </Box>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: {
                                        xs: 'auto',
                                        md: '200px'
                                    },
                                    flexDirection: {
                                        xs: 'column',
                                        md: 'row'
                                    },
                                    gap: {
                                        xs: '20px',
                                        md: '0px'
                                    }
                                }}>
                                    <Box sx={{
                                        flex: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        gap: '13px',
                                    }}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#EF406E",
                                                    fontFamily: "Lexend",
                                                    fontSize: "24px",
                                                    fontWeight: 400,
                                                    lineHeight: "160%",
                                                }}
                                            >
                                                Emotionality
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography sx={{
                                                color: "#0C0C0C",
                                                fontFamily: "Lexend",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "160%"
                                            }}>
                                                Persons high on Emotionality have good access to and awareness of their own feelings. Low scorers are less aware of their feelings and tend not to express their emotions openly.
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        paddingX: '20px',
                                        rowGap: '10px',
                                    }}>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            {Array.from(Array(score)).map((item, index) => <Box key={index} sx={{
                                                width: {
                                                    xs: "18px",
                                                    md: '27px'
                                                },
                                                height: {
                                                    xs: "18px",
                                                    md: '27px'
                                                },
                                                borderRadius: '50%',
                                                backgroundColor: '#EF406E',
                                            }}>
                                            </Box>)}


                                            {
                                                Array.from(Array(10 - score)).map((item, index) => <Box key={index} sx={{
                                                    width: {
                                                        xs: "18px",
                                                        md: '27px'
                                                    },
                                                    height: {
                                                        xs: "18px",
                                                        md: '27px'
                                                    },
                                                    borderRadius: '50%',
                                                    backgroundColor: '#EFF3F6',
                                                }}>
                                                </Box>)
                                            }


                                        </Box>

                                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <Typography sx={{
                                                color: "#0C0C0C",
                                                fontFamily: "Lexend",
                                                fontSize: "14px",
                                            }}>How to interpret your score </Typography>
                                            <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49902 1.99089C6.73298 1.99089 5.97444 2.14177 5.2667 2.43492C4.55897 2.72807 3.91591 3.15775 3.37423 3.69943C2.83256 4.2411 2.40288 4.88417 2.10973 5.5919C1.81657 6.29963 1.66569 7.05817 1.66569 7.82422C1.66569 8.59026 1.81657 9.34881 2.10973 10.0565C2.40288 10.7643 2.83256 11.4073 3.37423 11.949C3.91591 12.4907 4.55897 12.9204 5.2667 13.2135C5.97444 13.5067 6.73298 13.6576 7.49902 13.6576C9.04612 13.6576 10.5299 13.043 11.6238 11.949C12.7178 10.855 13.3324 9.37132 13.3324 7.82422C13.3324 6.27712 12.7178 4.79339 11.6238 3.69943C10.5299 2.60547 9.04612 1.99089 7.49902 1.99089ZM-0.000976562 7.82422C-0.000976562 5.83509 0.7892 3.92744 2.19572 2.52092C3.60225 1.1144 5.5099 0.324219 7.49902 0.324219C9.48815 0.324219 11.3958 1.1144 12.8023 2.52092C14.2088 3.92744 14.999 5.83509 14.999 7.82422C14.999 9.81334 14.2088 11.721 12.8023 13.1275C11.3958 14.534 9.48815 15.3242 7.49902 15.3242C5.5099 15.3242 3.60225 14.534 2.19572 13.1275C0.7892 11.721 -0.000976562 9.81334 -0.000976562 7.82422ZM6.66569 4.49089C6.66569 4.26987 6.75349 4.05791 6.90977 3.90163C7.06605 3.74535 7.27801 3.65755 7.49902 3.65755L7.50736 3.65755C7.72837 3.65755 7.94033 3.74535 8.09661 3.90163C8.25289 4.05791 8.34069 4.26987 8.34069 4.49089C8.34069 4.7119 8.25289 4.92386 8.09661 5.08014C7.94033 5.23642 7.72837 5.32422 7.50736 5.32422L7.49902 5.32422C7.27801 5.32422 7.06605 5.23642 6.90977 5.08014C6.75349 4.92386 6.66569 4.7119 6.66569 4.49089ZM6.67402 11.1576C6.67402 11.3786 6.76182 11.5905 6.9181 11.7468C7.07438 11.9031 7.28634 11.9909 7.50736 11.9909C7.72837 11.9909 7.94033 11.9031 8.09661 11.7468C8.25289 11.5905 8.34069 11.3786 8.34069 11.1576L8.34069 6.99089C8.34069 6.76987 8.25289 6.55791 8.09661 6.40163C7.94033 6.24535 7.72837 6.15755 7.50736 6.15755C7.28634 6.15755 7.07438 6.24535 6.9181 6.40163C6.76182 6.55791 6.67402 6.76987 6.67402 6.99089L6.67402 11.1576Z" fill="#8F8F8F" />
                                            </svg>
                                        </Box>

                                    </Box>
                                </Box>

                            </Box>

                        </Box>

                    </Box>
                </Box>
            </Box>

        </GlobalPageComponent >
    );
};

export default CoachTeamBreakdown;



