import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/material";
import Lottie from "react-lottie";
import * as animationData from "data/lottieAnimation.json";
import ProductChoose from "components/ProductChoose";
import FailedPayment from "components/FailedPayment";
import { generateClient } from "aws-amplify/data";
// import fetchPayments from '../hooks/fetchPayments';

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const AuthenticatingLoader = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [clientHasInactiveCoach, setClientHasInactiveCoach] = useState(false);
  const [isCustomer, setIsCustomer] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!user || !user.sub) return;

    console.log("reached here");

    const fetchUserAttributes = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-user-attributes`,
        {
          params: { user_id: user.sub },
        }
      );

      console.log("response data for user attributes: ", response);
      return response.data.data;
    };

    const fetchPaymentsCall = async (userSub) => {
      try {
        console.log("user id in fetch payments: ", userSub);

        const { data: response, errors } =
          await resourceClient.models.payments.list({
            filter: {
              user_id: {
                eq: userSub,
              },
            },
            limit: 1000,
            selectionSet: [
              "stripe_customer_id",
              "user_id",
              "coaching_platform",
              "coaching_platform_tier",
              "customer",
              "virtual_coach",
            ],
          });

        if (errors) {
          console.error("Errors encountered while fetching payments: ", errors);
          return null;
        }

        console.log("response for payments: ", response);
        return response; // Adjust based on the actual structure of your response
      } catch (error) {
        console.error("Error fetching payments:", error);
        return null;
      }
    };

    const checkClientCoachStatus = async (userSub) => {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-coach-status`,
        {
          params: { user_id: userSub },
        }
      );
      console.log("check client coach status: ", response);
      return response.some((coach) => !coach.is_coach); //change this to when all of the coaches are inactive
    };

    const trackLogin = async (userSub) => {
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_TRACK_LOGIN}mostRecentLogin`,
        {
          userID: userSub,
          loginTime: new Date().toISOString(),
        }
      );
    };

    const checkUserAccess = async () => {
      setIsLoading(true);
      try {
        const userSub = user.sub;
        console.log("user sub: ", userSub);
        await trackLogin(userSub);
        const userAttributes = await fetchUserAttributes();
        setUserData(userAttributes);
        // const payments = await fetchPaymentsF(user.sub)
        const payments = await fetchPaymentsCall(user.sub);
        console.log("payments to check: ", payments);

        if (userAttributes.is_client && userAttributes.access_to === 'client_portal') {
          if (await checkClientCoachStatus(userSub)) {
            setClientHasInactiveCoach(true);
            setIsLoading(false);
            return;
          }
          navigate("/platform/coachee");
          return;
        }
        console.log("not a client");

        if (
          userAttributes.access_to === "platform_professional" ||
          userAttributes.access_to === "platform"
        ) {
          console.log("is coach platform: ", payments[0]);
          const paymentObj = payments[0];
          if (payments && payments.length > 0 && paymentObj.customer) {
            navigate("/platform/coach");
          } else {
            console.log("reached else");
            setIsCustomer(paymentObj?.customer || null);
            setStripeCustomerId(paymentObj?.stripe_customer_id || null);
            console.log("is customer check: ", isCustomer);
            console.log("is customer check: ", stripeCustomerId);
            setIsLoading(false);
          }
          return;
        }

        if (payments.length > 0) {
          const payment = payments[0];
          setStripeCustomerId(payment.stripe_customer_id || null);
          if (payment.coachingPlatform && payment.customer) {
            navigate("/platform/coach");
          } else if (payment.virtual_coach && payment.customer) {
            navigate("/virtualcoach/bot");
          } else {
            setIsCustomer(payment.customer);
            setIsLoading(false);
          }
        } else {
          setIsCustomer(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Failed to check user access or track login:", error);
        setIsCustomer(false);
        setIsLoading(false);
      }
    };

    checkUserAccess();
  }, [user, navigate]);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#EFF3F6",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "black",
            textAlign: "center",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            width="50%"
            height="auto"
          />
        </Box>
      </Box>
    );
  }

  return !isCustomer && stripeCustomerId ? (
    <FailedPayment
      user={user}
      stripeCustomerId={stripeCustomerId}
      userData={userData}
    />
  ) : (
    <ProductChoose clientHasInactiveCoach={clientHasInactiveCoach} />
  );
};

export default AuthenticatingLoader;