import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { PaymentContext } from "./PaymentContext";
import { Hub } from 'aws-amplify/utils'
import { useCheckClientInviteAllowance } from "hooks/useCheckClientInviteAllowance";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { generateClient } from 'aws-amplify/data';
import { getCurrentUser } from "aws-amplify/auth";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({authMode: "userPool"});

export const PaymentProvider = ({ children }) => {
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const { checkClientInviteAllowance } = useCheckClientInviteAllowance();
  // const { user } = useAuthenticator((context) => [context.user]);
  const [user, setUser] = useState({})

  const fetchUser = async () => {
    try {
      const userObj = await getCurrentUser();
      setUser(userObj)
    } catch (error) {
      console.log("error")
    }
  }

  useEffect(() => {
    if(!user){
      fetchUser();
    }
}, [user]);

  const fetchUserAttributes = async (userID) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-user-attributes`, {
        params: { user_id: userID }
      });

      const userAttributesResponse = response.data;
      console.log("user attributes in: ", userAttributesResponse)
      

      if (!userAttributesResponse) {
        return;
      }

      if (userAttributesResponse?.data?.access_to === 'client_portal') {
        const isCoachActiveResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-coach-status`, {
            params: {
                user_id: userID
            }
        });

        const coachStatuses = isCoachActiveResponse.data;
        const hasInactiveCoach = coachStatuses.some(coach => !coach.is_coach);
        setUserData(prevData => ({
          ...prevData,
          clientHasInactiveCoach: hasInactiveCoach
        }));
      }

      setUserData(prevData => ({
        ...prevData,
        accessTo: userAttributesResponse.data.access_to || null,
        isVirtualCoach: userAttributesResponse.data.is_virtual_coach || null,
        isClient: userAttributesResponse.data.is_client || null,
        isCoach: userAttributesResponse.data.is_coach || null,
        isProfessional: userAttributesResponse.data.is_professional || null,
        coachingTier: userAttributesResponse.data.coaching_tier || null,
        oldUserId: userAttributesResponse.data.old_user_id || null
      }));

      console.log("user data payment prov: ", userData)
      
    } catch (error) {
      console.error("Error fetching user attributes:", error);
    }
  };

  const fetchPayments = async (userID) => {
    try {

      const userObj = await getCurrentUser()
      console.log("user object: ", userObj)
      await fetchUserAttributes(userObj.userId);

      const { data: payments, errors } = await resourceClient.models.payments.list({
        filter: {
          user_id: {
            eq: userObj.userId
          }
        },
        limit: 1000,
        selectionSet: [
          'stripe_customer_id',
          'user_id',
          'coaching_platform',
          'coaching_platform_tier',
          'customer',
          'virtual_coach'
        ]
      });

      console.log("payments: ", payments);

      const clientCounter = await checkClientInviteAllowance(userObj.userId);


      if (payments && payments[0]) {
        const payment = payments[0];
        console.log("check in case: ", payment)
        setUserData(prevData => ({
          ...prevData,
          paymentInfo: {
            stripeCustomerId: payment?.stripe_customer_id || null,
            allowAccessToPlatform: (payment?.coaching_platform && payment?.customer) || null,
            allowAccessToBot: (payment?.virtual_coach && payment?.customer) || null,
            isCustomer: payment?.customer || null,
            coachingPlatformTier: payment?.coaching_platform_tier || null,
          },
          isProfessionalTier: payment?.coaching_platform_tier === "personal" || null,
          isCoachLevel1: payment?.coaching_platform_tier === "tier1" || null,
          isCoachLevel2: payment?.coaching_platform_ier === "tier2" || null,
          isCoachLevel3: payment?.coaching_platform_tier === "tier3" || null,
          isClientCountLimitReached: clientCounter ? clientCounter?.clientCount > clientCounter?.clientTierAllowance : false
        }));
      } else {
        setUserData(prevData => ({
          ...prevData,
          paymentInfo: {
            allowAccessToBot: false,
            allowAccessToPlatform: false,
          },
          isPersonalTier: false,
        }));
      }

        console.log("user payment data: ", userData);
      } catch (error) {
        console.log("Error fetching payments:", error);
      }
    };

  useEffect(() => {
      fetchPayments(user?.userId);
  }, [user]);

  useEffect(() => {
    if (user && (location.pathname === "/platform/coach" || location.pathname === "/platform/coachee" || location.pathname === "/virtualcoach/bot")) {
      fetchPayments(user?.userId);
    }
  }, [user, location.pathname]);

  useEffect(() => {
    const hubListener = (data) => {
      if (data.payload.event === "signdIn" || data.payload.event === "signdInWithRedirect") {
        fetchPayments(data.payload.data.attributes.sub);
      } else if (data.payload.event === "signedOut") {
        // Clear the context or set to default values
        setUserData({});
      }
    };

    const removeListener = Hub.listen("auth", hubListener);

    return () => {
      // Clean up the listener by calling the returned function
      removeListener();
    };
  }, []);

  return (
    <PaymentContext.Provider value={{userData}}>
      {children}
    </PaymentContext.Provider>
  );
};