import { BorderColor, Delete, Undo } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import TrophyIcon from "assets/TrophyIcon";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CompletedActionToolBar = ({ user, selectedCompleted, selectedCompletedIds, setUpdatedAction, setInitialAction, setOpenEditActionModal, setOpenAddActionsToGoalModal, setOpenUndoActionModal, setOpenDatePicker, setOpenDeleteActionModal }) => {
    return(
        <>
            <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                    position: "relative",
                    flexWrap: {
                      xs: "wrap",
                      md: "unset",
                    },
                    '& > :nth-of-type(n)': {
                      flexBasis: {
                        xs: "calc(50% - 24px)",
                        md: "unset",
                      },
                    },
                  }}
                >
                      <Box 
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          marginLeft: "16px",
                          "&:hover": {
                            background: "#F7F7F7",
                            cursor: (selectedCompleted.length === 1 ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => {
                          selectedCompleted.length === 1 && setUpdatedAction(selectedCompletedIds[0]);
                          selectedCompleted.length === 1 && setInitialAction(selectedCompletedIds[0]);
                          selectedCompleted.length === 1 && setOpenEditActionModal(true);
                        }}
                      >
                        <BorderColor
                          sx={{
                            fill: (selectedCompleted.length === 1 ? "black" : "#B6B6B6")
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "1.66",
                            color: (selectedCompleted.length === 1 ? "black" : "#B6B6B6"),
                          }}
                        >
                          Edit
                        </Typography>
                      </Box>
                      <Box 
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#F7F7F7",
                            cursor: (selectedCompleted.length > 0 ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => selectedCompleted.length > 0 && setOpenAddActionsToGoalModal(true)}
                      >
                        <TrophyIcon
                          fill={selectedCompleted.length > 0 ? "black" : "#B6B6B6"}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "1.66",
                            color: (selectedCompleted.length > 0 ? "black" : "#B6B6B6"),
                          }}
                        >
                          Assign to Goal
                        </Typography>
                      </Box>
                      <Box 
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#F7F7F7",
                            cursor: (selectedCompleted.length > 0 ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => selectedCompleted.length > 0 && setOpenUndoActionModal(true)}
                      >
                        <Undo
                          sx={{
                            fill: (selectedCompleted.length > 0 ? "black" : "#B6B6B6")
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "1.66",
                            color: (selectedCompleted.length > 0 ? "black" : "#B6B6B6"),
                          }}
                        >
                          Mark as To-Do
                        </Typography>
                      </Box>
                  <Box 
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      "&:hover": {
                        background: "#F7F7F7",
                        cursor: (selectedCompleted.length > 0 ? "pointer" : "not-allowed"),
                      },
                    }} 
                    onClick={() => selectedCompleted.length > 0 && setOpenDatePicker(true)}
                  >
                    <CalendarMonthIcon
                      sx={{
                        fill: (selectedCompleted.length > 0 ? "black" : "#B6B6B6")
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.66",
                        color: (selectedCompleted.length > 0 ? "black" : "#B6B6B6"),
                      }}
                    >
                      Assign Due Date
                    </Typography>
                  </Box>
                  {user?.["custom:is_coachee"] !== "true" && (
                      <>
                      <Box 
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#F7F7F7",
                            cursor: (selectedCompleted.length > 0 ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => selectedCompleted.length > 0 && setOpenDeleteActionModal(true)}
                      >
                        <Delete
                          sx={{
                            fill: (selectedCompleted.length > 0 ? "black" : "#B6B6B6")
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "1.66",
                            color: (selectedCompleted.length > 0 ? "black" : "#B6B6B6"),
                          }}
                        >
                          Delete
                        </Typography>
                      </Box>
                      </>
                    )}
                </Box>
        </>
    )
}

export default CompletedActionToolBar