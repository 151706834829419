import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as Yup from "yup";
import LoginForm from "scenes/login/LoginForm";
import Logo from "scenes/Layout/logo";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
  },
  headerImage: "",
};

export const theme = createTheme(themeOptions);

const googleClientId =
  "498612229346-ev4aor25mos6714nqq8hq8u1r3p9hnsq.apps.googleusercontent.com";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name Required"),
  lastName: Yup.string().required("Last Name Required"),
  email: Yup.string().email("Invalid Email Format").required("Email Required"),
  password: Yup.string().required("Password Required"),
  verificationCode: Yup.string().when("isSignUpStep", {
    is: false,
    then: Yup.string().required("Verification Code Required"),
    otherwise: Yup.string().notRequired(),
  }),
  agreedToTerms: Yup.boolean().oneOf([true], "Must agree to Terms"),
  agreedToDataUsage: Yup.boolean().oneOf([true], "Must agree to Data Usage"),
  isSignUpStep: Yup.boolean(),
});

const SignInPage = ({user, userData}) => {




  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#EFF3F6",
        overflowY: "auto",
      }}
    >
      <Box display="flex" style={{ alignItems: "left", marginRight: "1200px" }}>
        <Logo />
      </Box>
      <ThemeProvider theme={theme}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          style={{
            position: "relative",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              margin: { xs: "0px 20px", lg: "0px 0px 0px 150px" },
              minHeight: "calc(100vh - 198px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "transparent",
              borderRadius: "10px",
              width: "100%",
              zIndex: 1,
            }}
          >
            <LoginForm user={user} userData={userData}/>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default SignInPage;
