import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import avatar from "assets/avatar.png";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import ClientCards from "components/CoachClientCenter/ClientCards";
import ActivityFeed from "components/CoachClientCenter/ActivityFeed";
import AddClientButton from "components/CoachClientCenter/AddClientButton";
import AddClientCenter from "components/CoachClientCenter/AddClientCenter";
import { usePayment } from "hooks/PaymentContext";
import PersonalTierUpgrade from "components/CoachClientCenter/PersonalTierUpgrade";
import ClientLimitReached from "components/ClientLimitReachedModal";
import axios from "axios";

const ClientCenter = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [clientData, setClientData] = useState([]);
  const [clientStatusArray, setClientStatusArray] = useState([]);
  const [clientPicture, setClientPicture] = useState([]);
  const [filterEventItems, setFilterEventItems] = useState([{ key: "Event Type", value: "none" }]);
  const [filterClientItems, setFilterClientItems] = useState([{ key: "Coachee", value: "none" }]);
  const [filterParticipantItems, setFilterParticipantItems] = useState([{ key: "Participant", value: "none" }]);
  const [loading, setLoading] = useState(true);
  const [loadingActivityData, setLoadingActivityData] = useState(false);
  const [menuShow, setMenuShow] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const userId = user?.sub;
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-by-coach-test`,
        {
          params: {
            coach_id: userId,
          },
        }
      );
      console.log("response", response);

      const { client_data = [], client_status_objects = [] } = response.data || {};

      if (client_data.length === 0 && client_status_objects.length === 0) {
        setLoading(false);
        setClientData([]);
        setClientStatusArray([]);
        return;
      }

      const processedClientData = client_data.map((row) => ({
        ...row,
        avatar: row.avatar
          ? `https://d7joen7tb0xwx.cloudfront.net/public/${row.avatar}`
          : null,
      }));

      const processedClientStatusArray = client_status_objects.map((row) => ({
        ...row,
        avatar: row.avatar
          ? `https://d7joen7tb0xwx.cloudfront.net/public/${row.avatar}`
          : null,
      }));

      setClientData(processedClientData);
      setClientStatusArray(processedClientStatusArray);

      const pictureData = [
        ...processedClientData.map((row) => row.avatar),
        ...processedClientStatusArray.map((row) => row.avatar),
      ].filter((avatar) => avatar !== null);

      setClientPicture(pictureData);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchActivityData = async () => {
    setLoadingActivityData(true);
    try {
      const userId = user?.sub;
      const { data: responseData } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/activityFeed`,
        {
          params: { coach_id: userId },
        }
      );
  
      console.log("responseData", responseData);
  
      if (responseData && responseData.length > 0) {
        // Sort the data by date_added in descending order
        const sortedData = responseData.sort(
          (a, b) => new Date(b.date_added) - new Date(a.date_added)
        );
  
        // Format the data to handle potential null or undefined values
        const formattedData = sortedData.map((row) => ({
          action: row.action ?? "N/A",
          actionId: row.action_id ?? "N/A",
          actionStatus: row.action_status ?? "N/A",
          avatar: row.avatar ?? "N/A",
          callId: row.call_id ?? "N/A",
          callName: row.call_name ?? "N/A",
          clientId: row.client_id !== "undefined" ? row.client_id : "N/A",
          coachId: row.coach_id ?? "N/A",
          coachName: row.coach_name ?? "N/A",
          dateAdded: row.date_added ? new Date(row.date_added).toLocaleString() : "N/A",
          eventType: row.event_type?.toString() ?? "N/A",
          id: row.id ?? "N/A",
          participantName: row.participant_name ?? "N/A",
          zoomDate: row.zoom_date ? new Date(row.zoom_date).toLocaleString() : "N/A",
        }));
  
        setActivityData(formattedData);
  
        // Extract unique events, clients, and participants
        const uniqueEvents = [...new Set(sortedData.map((row) => row.event_type?.toString()))];
        const formattedEvents = uniqueEvents
          .filter(event => event !== null && typeof event === 'string') // Ensure event is a string
          .map(event => ({
            key: event.replace(/_/g, ' '), // Replace underscores with spaces
            value: event,
          }));

        console.log("Formatted events:", formattedEvents);
  

      // Extract unique coachees
      const uniqueCoachees = [...new Set(sortedData.map(row => row.coachee))];

      const formattedCoachees = uniqueCoachees
        .filter(coachee => coachee !== null)
        .map(coachee => ({
          key: coachee,
          value: coachee,
        }));
  
        const uniqueParticipants = [...new Set(sortedData.map((row) => row.participant_name))];
        const formattedParticipants = uniqueParticipants
          .filter((participant) => participant !== "N/A")
          .map((participant) => ({
            key: participant,
            value: participant,
          }));
  
        // Update the filter items
        setFilterEventItems([{ key: 'Event Type', value: 'none' }, ...formattedEvents]);
        setFilterClientItems([{ key: 'Coachee', value: 'none' }, ...formattedCoachees]);
        setFilterParticipantItems([{ key: 'Participant', value: 'none' }, ...formattedParticipants]);
  
        // Set the activity data
        setActivityData(sortedData);
      } else {
        setActivityData([]);
      }
    } catch (error) {
      console.error("Error fetching activity data:", error);
    } finally {
      setLoadingActivityData(false);
    }
  };
  



  useEffect(() => {
    fetchClientData();
    fetchActivityData();
  }, []);

  if (userData.accessTo === "platform_professional")
    return (
      <GlobalPageComponent user={user} accessTo={userData.accessTo}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PersonalTierUpgrade />
        </Box>
      </GlobalPageComponent>
    );

  return loading ? (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="EFF3F6"
      >
        <CircularProgress />
      </Box>
    </GlobalPageComponent>
  ) : (
    <>
      <GlobalPageComponent user={user}>
        <ClientLimitReached user={user} userData={userData} />
        <Box
          sx={{
            backgroundColor: "#EFF3F6",
            width: "100%",
            padding: {
              xs: "16px",
              md: "32px 40px",
            },
            display: {
              xs: !collapsed ? "none" : "block",
            },
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "36px",
                fontFamily: "Poppins",
                fontWeight: 300,
              }}
            >
              Leadership Center
            </Typography>
            {clientStatusArray.length !== 0 && (
              <AddClientButton
                user={user}
                loading={loading}
                setLoading={setLoading}
                fetchClientData={fetchClientData}
              />
            )}
          </Box>
          {clientStatusArray.length === 0 ? (
            <AddClientCenter
              user={user}
              loading={loading}
              setLoading={setLoading}
              fetchClientData={fetchClientData}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                flexDirection: {
                  xs: "column",
                  lg: "row-reverse",
                },
                minHeight: "90%",
              }}
            >
              <ClientCards
                user={user}
                clientData={clientData}
                clientStatusArray={clientStatusArray}
                loading={loading}
                setLoading={setLoading}
                fetchClientData={fetchClientData}
                avatar={avatar}
                clientPicture={clientPicture}
              />
              <ActivityFeed
                activityData={activityData}
                avatar={avatar}
                clientPicture={clientPicture}
                filterEventItems={filterEventItems}
                filterClientItems={filterClientItems}
                filterParticipantItems={filterParticipantItems}
                loading={loadingActivityData}
              />
            </Box>
          )}
        </Box>
      </GlobalPageComponent>
    </>
  );
};

export default ClientCenter;
