import React, { useEffect } from 'react';
import { Avatar, Box, Button, CircularProgress, Divider, Typography } from "@mui/material";

import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { generateClient } from "aws-amplify/data";
import domains from 'components/Assessments/TheBigFive/en/index';

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });


const CoachIndividualBreakdown = ({ user, userData }) => {
    const navigate = useNavigate();
    const collapsed = useSelector((state) => state.layout.collapsed);
    const tagNames = ["General", "Neuroticism", "Extraversion", "Openness", "Agreeableness", "Conscientiousness"]
    const [selectedTag, setSelectedTag] = useState(tagNames[0]);
    const [loading, setLoading] = useState(false);
    const [coacheeData, setCoacheeData] = useState([]);
    const [assessmentData, setAssessmentData] = useState([])

    const { testId, userId, teamId, tab } = useParams();
    
    
    useEffect(() => {
        if (tab) {
          setSelectedTag(tab);
        }
      }, [tab]);
      


    const fetchCoacheeData = async () => {
        setLoading(true);
        try {
          let response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-data-test`,
            {
              params: {
                client_id: userId,
                coach_id: user?.sub,
              },
            }
          );
      
          const { data: assessmentRes, erros } = await resourceClient.models.assessments.get({
            id: testId,
          });
      
          if (!response.data || !assessmentRes) {
            setLoading(false);
            return;
          }
      
          // Parse the results field
          const results = JSON.parse(assessmentRes.results);
          setAssessmentData({ ...assessmentRes, results });
      
          const data = response.data.client_data;
          data.avatar = data.avatar
            ? "https://d7joen7tb0xwx.cloudfront.net/public/" + data.avatar
            : null;
          setCoacheeData(data);


          console.log("assesments data: ", assessmentData)
        } catch (error) {
          console.error("Error fetching client data:", error);
        } finally{
            setLoading(false)
        }
      };
      

    useEffect(() => {
        fetchCoacheeData();
    }, [])


        // Find the domain based on the selectedTag
        const selectedDomain = domains.find(
            (domain) => domain.title === selectedTag
        );



        // Get the result for the selected domain
        const domainResult = assessmentData?.results?.[selectedDomain?.domain];

        const scaledScore = (facetScore, count) => {
            const maxScore = count * 5;
            return (facetScore / maxScore) * 10
        }

        const domainColors = {
            G: "#000000",  //General
            N: '#9865CF', // Neuroticism
            O: '#EF406E', // Openness to Experience
            A: '#F3AB54', // Agreeableness 
            E: '#337BE8', // Extraversion 
            C: '#49CA7A'  // Conscientiousness 
          };

          const facetColors = {
            0: '#9865CF',
            1: '#337BE8',
            2: '#EF406E',
            3: '#F3AB54',
            4: '#49CA7A',
            5: '#000000'
          };
          


    const score = 7; // Ale, I declared this variable to simply map the coloured circles to showcase score. Feel free to use the database data.

    // Ale, Fetch the user data from the database using the userId here -----


    return (
        <GlobalPageComponent user={user} accessTo={userData.accessTo}>
            <ClientLimitReached user={user} userData={userData} />

            <Box
                sx={{
                    backgroundColor: "#EFF3F6",
                    width: "100%",
                    height: "auto",
                    padding: {
                        xs: "16px",
                        md: "32px 40px",
                    },
                    display: {
                        xs: !collapsed ? "none" : "block",
                    },
                    overflowY: "auto",
                }}
            >
                <Box>

                    <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                        }}
                    >
                        Assessments
                    </Typography>

                    <Box
                        sx={{
                            background: "white",
                            borderRadius: "40px",
                            padding: "24px",
                            width: "100%",
                            height: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    md: "space-between",
                                },
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                },
                                gap: {
                                    xs: "20px",
                                    md: "0px",
                                },
                                alignItems: "center",
                            }}
                        >

                            <Typography
                                sx={{
                                    color: "black",
                                    fontFamily: "Poppins",
                                    fontSize: "24px",
                                    fontWeight: 300,
                                }}
                            >Individual Breakdown</Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: {
                                        xs: 'start',
                                        md: "center",
                                    },
                                    justifyContent: "space-between",
                                    border: "1px solid #D9D9D9",
                                    height: {
                                        xs: "auto",
                                        md: '45px',
                                    },
                                    paddingX: '10px',
                                    paddingY: '10px',
                                    borderRadius: "20px",
                                    flexDirection: {
                                        xs: "column",
                                        md: "row",
                                    },
                                    gap: {
                                        xs: "20px",
                                        md: "8px",
                                    },
                                }}
                            >
                                {teamId && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: "#E3E3E3",
                                                borderRadius: "50%",
                                                width: "28px",
                                                height: "28px",
                                            }}>
                                        </Box>
                                        <Typography
                                            sx={{
                                                color: "black",
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 300,
                                            }}
                                        >
                                            {"N/A"}
                                        </Typography>
                                    </Box>
                                )}

                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                     <Avatar
                                        sx={{
                                        width: "22px",
                                        height: "22px",
                                        }}
                                        src={''}
                                    />
                                    <Typography
                                        sx={{
                                            color: "black",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 300,
                                        }} x
                                    >
                                        {loading 
                                            ? "" 
                                            : coacheeData?.full_name
                                                ? coacheeData?.full_name
                                                : ""
                                        }
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', gap: '8px' }}>
                                    <Box sx={{ display: 'flex', gap: '6px' }}>
                                    <Typography sx={{ color: '#B9B9B9', font: '10px', fontWeight: '500', textTransform: "uppercase" }}>Date:</Typography>
                                    <Typography sx={{ color: '#000000', font: '10px', fontWeight: '500' }}>
                                        {loading 
                                            ? ""
                                            : assessmentData?.updatedAt 
                                                ? ` ${new Date(assessmentData?.updatedAt).toLocaleDateString()}`
                                                : ""
                                        }
                                    </Typography>
                                    </Box>

                                    <Typography sx={{ color: '#000000', font: '10px', fontWeight: '500' }}>|</Typography>

                                    <Box sx={{ display: 'flex', gap: '6px' }}>
                                        <Typography sx={{ color: '#B9B9B9', font: '10px', fontWeight: '500', textTransform: "uppercase" }}>Time:</Typography>
                                        <Typography sx={{ color: '#000000', font: '10px', fontWeight: '500' }}>
                                            {loading
                                                ? "" 
                                                : assessmentData?.updatedAt
                                                    ? new Date(assessmentData?.updatedAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                                    : ""
                                            }
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>


                            <Button
                                onClick={() => {
                                    navigate(-1);
                                }}
                                variant='outlined'
                                sx={{
                                    width: '90px',
                                    height: '35px',
                                    borderRadius: '20px',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    border: '1px solid black',
                                }}
                            >
                                Back
                            </Button>

                        </Box>

                        <Divider color="#D9D9D9" sx={{ marginY: '30px' }} />

                        <Box sx={{ paddingX: '30px' }}>

                            <Box
                                sx={{
                                    width: "70%",
                                    marginX: "auto",
                                    marginBottom: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "20px",
                                    flexDirection: {
                                        xs: "column",
                                        md: "row",
                                    }
                                }}
                            >
                                {
                                    tagNames.map((name, index) => <Box key={index}
                                        onClick={() => setSelectedTag(name)}
                                        sx={{
                                            width: '160px',
                                            height: '35px',
                                            borderRadius: "60px",
                                            border: selectedTag === name ? `2px solid ${domainColors[selectedDomain?.domain]}` : "2px solid #8F8F8F",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",

                                        }}>
                                        <Typography sx={{
                                            color: selectedTag === name ? domainColors[selectedDomain?.domain] : "#8F8F8F",
                                            fontFamily: "Lexend",
                                            fontSize: "10px",
                                            fontWeight: 700,
                                            linHeight: "150%",
                                            letterSpacing: "2px",
                                            textTransform: "uppercase",
                                            paddingX: "10px"
                                        }}>
                                            {name}
                                        </Typography>
                                    </Box>)
                                }
                            </Box>
                        {assessmentData && assessmentData?.results && (
                            <>
                            {(selectedDomain?.domain && selectedDomain?.domain === 'G') ? (
                                Object.keys(assessmentData.results).map((domainKey) => {
                                    const domainData = assessmentData.results[domainKey] || {}; // Default to empty object if no data
                                    const scoreValue = Number(domainData.score) || 0; // Ensure score is a valid number, default to 0
                                    const scaledScore = Math.round((scoreValue/120) * 100);

                                    // Ensure scaledScore is valid
                                    const filledCircles = Math.max(Math.round(scaledScore / 10), 0); // Ensure it's at least 0
                                    const emptyCircles = Math.max(10 - filledCircles, 0); // Ensure empty circles are at least 0
                            
                                    return (
                                        <Box key={domainKey} sx={{ marginBottom: '20px', display: "flex", gap: "20px", justifyContent: "center", textAlign: "left" }}>
                                          {!loading ? (
                                            <>
                                              <Typography
                                                sx={{
                                                  color: domainColors[domainKey] || '#000',
                                                  fontFamily: "Lexend",
                                                  fontSize: "20px",
                                                  fontWeight: 400,
                                                  marginBottom: '10px',
                                                  minWidth: "200px"
                                                }}
                                              >
                                                {domains.find(d => d.domain === domainKey)?.title || domainKey}
                                              </Typography>
                                      
                                              {/* Display the circles */}
                                              <Box sx={{ display: 'flex', gap: '5px' }}>
                                                {/* Render the filled circles */}
                                                {Array.from(Array(filledCircles)).map((item, subIndex) => (
                                                  <Box
                                                    key={subIndex}
                                                    sx={{
                                                      width: {
                                                        xs: "18px",
                                                        md: '27px'
                                                      },
                                                      height: {
                                                        xs: "18px",
                                                        md: '27px'
                                                      },
                                                      borderRadius: '50%',
                                                      backgroundColor: domainColors[domainKey], // Color based on the domain
                                                    }}
                                                  />
                                                ))}
                                      
                                                {/* Render the empty circles */}
                                                {Array.from(Array(emptyCircles)).map((item, subIndex) => (
                                                  <Box
                                                    key={subIndex}
                                                    sx={{
                                                      width: {
                                                        xs: "18px",
                                                        md: '27px'
                                                      },
                                                      height: {
                                                        xs: "18px",
                                                        md: '27px'
                                                      },
                                                      borderRadius: '50%',
                                                      backgroundColor: '#EFF3F6', // Light gray for empty circles
                                                    }}
                                                  />
                                                ))}
                                              </Box>
                                              {scaledScore !== null && (
                                                    <Typography
                                                        sx={{
                                                            color: "#8F8F8F",
                                                            fontFamily: "Lexend",
                                                            fontSize: "16px"
                                                        }}
                                                    >
                                                        {scaledScore} / 100
                                                    </Typography>
                                                )}
                                            </>
                                          ) : (
                                            <CircularProgress sx={{color: "black"}} />
                                          )}
                                        </Box>
                                      );
                                })
                            ) : (
                            <>
                                <Box 
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        height: {
                                            xs: "auto",
                                            md: "300px",
                                        },
                                        flexDirection: {
                                            xs: "column",
                                            md: "row",
                                        }
                                    }}
                                >
                                    <Box sx={{
                                        flex: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: domainColors[selectedDomain?.domain] || '#000000',
                                                    fontFamily: "Lexend",
                                                    fontSize: {
                                                        xs: '35px',
                                                        md: "44px"
                                                    },
                                                    fontWeight: 400,
                                                    lineHeight: "160%",
                                                }}
                                            >
                                                {selectedDomain?.title || selectedTag}
                                            </Typography>
                                        </Box>

                                        {loading || !selectedDomain?.domain ? (
                                            <>
                                                <CircularProgress sx={{color: "black"}}/>
                                            </>
                                        ) : (
                                            <Box>
                                                <Typography sx={{
                                                    color: "#0C0C0C",
                                                    fontFamily: "Lexend",
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                    lineHeight: "160%"
                                                }}>
                                                    {selectedDomain?.shortDescription || "No description available for this domain."}
                                                </Typography>
                                                <Typography sx={{
                                                    color: "#0C0C0C",
                                                    fontFamily: "Lexend",
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                    lineHeight: "160%"
                                                }}>
                                                    {
                                                        selectedDomain?.results?.find(result => result?.score === assessmentData?.results[selectedDomain?.domain]?.result)?.text
                                                        || "No description available for this result."
                                                    }
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box 
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '50%',
                                                marginY: {
                                                    xs: '20px',
                                                    md: '0px'
                                                },
                                                width: {
                                                    xs: '80px',
                                                    md: '142px'
                                                },
                                                height: {
                                                    xs: '80px',
                                                    md: '142px'
                                                },
                                                backgroundColor: domainColors[selectedDomain?.domain] || "000",
                                            }}
                                        >
                                            <Typography sx={{
                                                margin: 0,
                                                padding: 0,
                                                color: "FFF" || "000",
                                                fontFamily: "Lexend",
                                                fontSize: "44px",
                                                fontWeight: 400,
                                                lineHeight: "160%",
                                            }}>
                                                {selectedDomain?.domain 
                                                    ? Math.round((assessmentData?.results[selectedDomain?.domain]?.score / 120) * 100)
                                                    : ""
                                                }
                                            </Typography>
                                        </Box>

                                    </Box>

                                </Box>

                                <Box 
                                    sx={{
                                        backgroundColor: '#EFF3F6',
                                        width: '100%',
                                        height: '7px',
                                        borderRadius: '10px',
                                        marginY: {
                                            xs: '30px',
                                        }
                                    }}
                                >
                                </Box>

                                <Box 
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: {
                                            xs: '40px',
                                            md: '20px',
                                        },
                                        marginTop: '30px',
                                    }}
                                >
                                    {selectedDomain?.facets?.map((facet, index) => {
                                        const facetData = assessmentData?.results[selectedDomain.domain]?.facet[index + 1] || {}; // Default to empty object if no data
                                        const scoreValue = Number(facetData.score) || 0; // Ensure score is a valid number, default to 0
                                        const countValue = Number(facetData.count) || 1; // Default to 1 to avoid division by 0
                                        const scaledFacetScore = Math.min(Math.max(Math.round((scoreValue / (countValue * 5)) * 100 / 10), 0), 10); // Adjust to scale 0-10

                                        return (
                                            <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: { xs: 'column', md: 'row' },
                                                gap: { xs: '20px', md: '30px' },
                                                marginBottom: '20px', // Add spacing between each facet
                                                flexWrap: 'wrap', // Allow wrapping on small screens
                                            }}
                                            >
                                            {/* Facet Title and Description */}
                                            <Box
                                                sx={{
                                                flex: 2,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                gap: '13px',
                                                minWidth: '250px', // Ensure a minimum width to avoid shrinkage
                                                padding: '10px', // Add some padding for better spacing
                                                }}
                                            >
                                                <Box>
                                                <Typography
                                                    sx={{
                                                    color: '#222222', // Color based on the domain
                                                    fontFamily: "Lexend",
                                                    fontSize: { xs: '18px', md: '24px' }, // Responsive font size
                                                    fontWeight: 400,
                                                    lineHeight: "160%",
                                                    marginBottom: '8px', // Avoid text overlapping
                                                    }}
                                                >
                                                    {facet.title}
                                                </Typography>
                                                </Box>

                                                <Box>
                                                <Typography
                                                    sx={{
                                                    color: "#0C0C0C",
                                                    fontFamily: "Lexend",
                                                    fontSize: { xs: '14px', md: '16px' }, // Responsive font size
                                                    fontWeight: 400,
                                                    lineHeight: "160%",
                                                    overflowWrap: 'break-word', // Handle long text wrapping
                                                    }}
                                                >
                                                    {facet.text}
                                                </Typography>
                                                </Box>
                                            </Box>

                                            {/* Scoring Circles */}
                                            <Box
                                                sx={{
                                                flex: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                paddingX: '20px',
                                                rowGap: '10px',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}> {/* Add flexWrap to handle overflow */}
                                                {/* Render the filled circles */}
                                                {Array.from(Array(scaledFacetScore)).map((item, subIndex) => (
                                                    <Box
                                                    key={subIndex}
                                                    sx={{
                                                        width: { xs: "18px", md: '27px' },
                                                        height: { xs: "18px", md: '27px' },
                                                        borderRadius: '50%',
                                                        backgroundColor: "#3C3C3C", // Circle color
                                                    }}
                                                    />
                                                ))}

                                                {/* Render the empty circles */}
                                                {Array.from(Array(10 - scaledFacetScore)).map((item, subIndex) => (
                                                    <Box
                                                    key={subIndex}
                                                    sx={{
                                                        width: { xs: "18px", md: '27px' },
                                                        height: { xs: "18px", md: '27px' },
                                                        borderRadius: '50%',
                                                        backgroundColor: '#EFF3F6', // Light gray for empty circles
                                                    }}
                                                    />
                                                ))}
                                                </Box>
                                            </Box>
                                            </Box>
                                        );
                                        })}
                                </Box>
                            </>
                        )}
                        </>
                        )}
                        </Box>
                    </Box>
                </Box>
            </Box>

        </GlobalPageComponent >
    );
};

export default CoachIndividualBreakdown;