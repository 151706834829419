const TheBigFiveQuestions = [
  {
    id: '43c98ce8-a07a-4dc2-80f6-c1b2a2485f06',
    text: 'You often find yourself preoccupied with potential challenges or risks that could impact your work or projects',
    keyed: 'plus',
    domain: 'N',
    facet: 1
  },
  {
    id: 'd50a597f-632b-4f7b-89e6-6d85b50fd1c9',
    text: 'You find it easy to connect with new people and establish friendships in both personal and professional settings',
    keyed: 'plus',
    domain: 'E',
    facet: 1
  },
  {
    id: '888dd864-7449-4e96-8d5c-7a439603ea91',
    text: 'You often come up with creative and imaginative ideas in both personal and professional settings',
    keyed: 'plus',
    domain: 'O',
    facet: 1
  },
  {
    id: 'ce2fbbf8-7a97-4199-bda5-117e4ecdf3b6',
    text: 'In team projects or collaborative efforts, you are comfortable relying on others to fulfill their responsibilities',
    keyed: 'plus',
    domain: 'A',
    facet: 1
  },
  {
    id: 'c7f53c3c-2e77-432f-bb71-7470b67d3aa9',
    text: 'When working on a project with a tight deadline, you manage to complete your tasks successfully and meet the project\'s goals',
    keyed: 'plus',
    domain: 'C',
    facet: 1
  },
  {
    id: '48ad12ce-470e-4339-90ac-ea8c43a0103e',
    text: 'In stressful situations or when faced with unexpected challenges at work, you find yourself becoming frustrated or upset quickly',
    keyed: 'plus',
    domain: 'N',
    facet: 2
  },
  {
    id: '458f3957-2359-4077-ade1-34525d633063',
    text: 'You enjoy attending large social gatherings or networking events where you can interact with many people at once',
    keyed: 'plus',
    domain: 'E',
    facet: 2
  },
  {
    id: '58d571e5-d725-4cf8-a438-32c16ee28eb6',
    text: 'You value and support initiatives that promote the role of art and creativity in personal and professional development',
    keyed: 'plus',
    domain: 'O',
    facet: 2
  },
  {
    id: '0cf79e27-e702-45c2-9471-04ac96b58e0e',
    text: 'In collaborative projects or team settings, you sometimes use others’ ideas or resources primarily to advance your own goals',
    keyed: 'minus',
    domain: 'A',
    facet: 2
  },
  {
    id: 'cda1ca17-b599-4561-a6cd-ff9d36062d27',
    text: 'In both your workspace and personal environment, you take pleasure in organizing and tidying up to create a neat and orderly space.',
    keyed: 'plus',
    domain: 'C',
    facet: 2
  },
  {
    id: '5e8550d7-b8ef-4905-950a-f81d735d39e2',
    text: 'At times, you find yourself feeling down or lacking motivation, even when there are no immediate problems or challenges.',
    keyed: 'plus',
    domain: 'N',
    facet: 3
  },
  {
    id: '8af754f2-68e9-48f3-8c5d-2e6633d4472c',
    text: 'In group settings or projects, you naturally step up to take the lead and make decisions when needed',
    keyed: 'plus',
    domain: 'E',
    facet: 3
  },
  {
    id: '0727def6-3d18-4221-bf38-86b58f9f3eed',
    text: 'You often experience your emotions with a high degree of intensity, whether in personal situations or professional interactions',
    keyed: 'plus',
    domain: 'O',
    facet: 3
  },
  {
    id: 'ccf3a5c8-fb50-4bd4-8e7a-22af3d657279',
    text: 'You find great satisfaction in offering assistance and support to others, whether in your personal life or at work',
    keyed: 'plus',
    domain: 'A',
    facet: 3
  },
  {
    id: '73d84e5d-cbf5-47f0-b8cb-4d2159a52e32',
    text: 'When you commit to a task or make a promise, you ensure that you follow through and meet your obligations',
    keyed: 'plus',
    domain: 'C',
    facet: 3
  },
  {
    id: 'b2d9ef74-73f5-4ea8-b00c-7aaca15937df',
    text: 'In social or professional settings, you sometimes struggle to initiate conversations or approach new people',
    keyed: 'plus',
    domain: 'N',
    facet: 4
  },
  {
    id: '48a761ef-438e-409b-ae59-ea2ce8f84414',
    text: 'You frequently find yourself occupied with tasks and activities, leaving little time for additional commitments or interactions',
    keyed: 'plus',
    domain: 'E',
    facet: 4
  },
  {
    id: 'cae55842-8957-4e3b-83b3-ceff98fb9dcf',
    text: 'You enjoy taking on different tasks and exploring new challenges rather than sticking to the same routine',
    keyed: 'plus',
    domain: 'O',
    facet: 4
  },
  {
    id: 'e2028ad3-b128-4f76-be57-398bfe2aff22',
    text: 'You find yourself energized or motivated by engaging in debates or resolving conflicts, rather than avoiding them',
    keyed: 'minus',
    domain: 'A',
    facet: 4
  },
  {
    id: 'b7fc949b-02b6-4cb9-a3e2-dbb3d824b55f',
    text: 'You consistently put in significant effort and dedication to achieve your goals and complete tasks.',
    keyed: 'plus',
    domain: 'C',
    facet: 4
  },
  {
    id: '481efd08-c810-43b1-a952-f8ac9052f96b',
    text: 'You occasionally find yourself indulging in activities or habits excessively, whether it is working long hours or engaging in leisure activities',
    keyed: 'plus',
    domain: 'N',
    facet: 5
  },
  {
    id: '987efee2-899f-4a65-b9b5-1589ef0460d7',
    text: 'You are drawn to and actively seek out activities or experiences that offer a sense of excitement and adventure',
    keyed: 'plus',
    domain: 'E',
    facet: 5
  },
  {
    id: 'e1e804c7-4a1d-498f-8610-f95147af9d1d',
    text: 'You enjoy engaging with complex or thought-provoking content, whether it is in books, articles, or other forms of media',
    keyed: 'plus',
    domain: 'O',
    facet: 5
  },
  {
    id: '71029381-3908-4c68-91e1-e41fb45542a2',
    text: 'You often find yourself feeling that your skills or abilities exceed those of others in your personal or professional circles',
    keyed: 'minus',
    domain: 'A',
    facet: 5
  },
  {
    id: 'f6076eea-56ae-4b46-97f1-5f94a7676c96',
    text: 'You consistently make sure you are well-prepared for tasks and challenges, anticipating potential issues and planning accordingly',
    keyed: 'plus',
    domain: 'C',
    facet: 5
  },
  {
    id: '2f519935-92e8-48ad-9746-4a0f8b38466a',
    text: 'In unexpected or high-pressure situations, you tend to feel overwhelmed or anxious quickly',
    keyed: 'plus',
    domain: 'N',
    facet: 6
  },
  {
    id: '899c3f66-51d0-46ea-963a-6fc36d3b3cb9',
    text: 'You naturally exude a sense of positivity and enthusiasm that uplifts those around you',
    keyed: 'plus',
    domain: 'E',
    facet: 6
  },
  {
    id: '79186f48-e7fa-4df4-b74b-b0627ee244e1',
    text: 'You often find yourself supporting or advocating for policies and leaders that emphasize progressive or liberal values.',
    keyed: 'plus',
    domain: 'O',
    facet: 6
  },
  {
    id: 'fd50e1ca-d9e0-4037-a7a1-a191d4db2d96',
    text: 'You feel a strong sense of compassion and concern for individuals experiencing homelessness and support efforts to address their needs.',
    keyed: 'plus',
    domain: 'A',
    facet: 6
  },
  {
    id: 'bd9eec0a-b68b-472c-8803-7db29c308cdb',
    text: 'You sometimes dive into new projects or decisions quickly, without fully considering the potential consequences or details',
    keyed: 'minus',
    domain: 'C',
    facet: 6
  },
  {
    id: '7f92ab2c-265c-4b84-8c74-09f9bb9d41a7',
    text: 'You often anticipate or worry about the worst possible outcomes in situations, even when things seem to be going well',
    keyed: 'plus',
    domain: 'N',
    facet: 1
  },
  {
    id: 'af55f014-788c-4b6e-92c4-b2b59dc8a28d',
    text: 'You generally feel at ease and confident when interacting with others, whether in casual or formal settings.',
    keyed: 'plus',
    domain: 'E',
    facet: 1
  },
  {
    id: '08ff6dca-02a5-4aeb-aaa4-2ecf2526f143',
    text: 'You find joy and satisfaction in allowing your imagination to explore creative and fantastical scenarios, often losing yourself in vivid and elaborate daydreams',
    keyed: 'plus',
    domain: 'O',
    facet: 1
  },
  {
    id: '6f66cdc0-9044-457b-b40d-501ecae15ee7',
    text: 'You generally assume that people you interact with have positive and sincere motives in their actions and decisions',
    keyed: 'plus',
    domain: 'A',
    facet: 1
  },
  {
    id: 'f110fc66-2e9e-413c-920b-19f05e63d7ac',
    text: 'You consistently perform at a high level and achieve excellent results in your work or personal projects',
    keyed: 'plus',
    domain: 'C',
    facet: 1
  },
  {
    id: '7dab2a37-8635-4fc7-86b7-0abf13c183c9',
    text: 'You quickly become irritated or frustrated when faced with minor inconveniences or disruptions in your daily activities.',
    keyed: 'plus',
    domain: 'N',
    facet: 2
  },
  {
    id: '28ab59a0-e7cd-4fce-94e3-bba2ecc023b6',
    text: 'At social gatherings, you actively engage in conversations with a wide range of people, rather than sticking to familiar faces',
    keyed: 'plus',
    domain: 'E',
    facet: 2
  },
  {
    id: 'b5919f2f-cded-4745-a9ce-c02703cee807',
    text: 'You often recognize and appreciate subtle or unique aspects of beauty in your surroundings that others might overlook.',
    keyed: 'plus',
    domain: 'O',
    facet: 2
  },
  {
    id: '5a5fa975-d024-4ac8-8845-2823f957c21b',
    text: 'You might be tempted to bend or break rules to gain an advantage or achieve your goals, even if it means compromising integrity',
    keyed: 'minus',
    domain: 'A',
    facet: 2
  },
  {
    id: 'adf33f9f-45bd-43e3-af25-4c491176d97f',
    text: 'You frequently leave items in different locations and occasionally forget to return them to their designated places',
    keyed: 'minus',
    domain: 'C',
    facet: 2
  },
  {
    id: 'f0a14e16-d726-47e9-a2c1-647fd3d7d52e',
    text: 'You often find yourself struggling with self-criticism or negative feelings about yourself, impacting your overall self-esteem.',
    keyed: 'plus',
    domain: 'N',
    facet: 3
  },
  {
    id: '0b38e3d3-c15c-454c-b034-f4eb7ae1580a',
    text: 'In group settings, you often take the initiative to guide or influence others to achieve shared goals or complete tasks',
    keyed: 'plus',
    domain: 'E',
    facet: 3
  },
  {
    id: '5631b856-ff34-4f76-a0cd-edc7104c3bfa',
    text: "You have a strong ability to sense and empathize with the emotions and feelings of those around you, even without them explicitly expressing them.",
    keyed: 'plus',
    domain: 'O',
    facet: 3
  },
  {
    id: 'ada867af-4db1-4e3d-a604-2b695c1806e5',
    text: 'You regularly think about and show concern for the well-being and needs of people around you.',
    keyed: 'plus',
    domain: 'A',
    facet: 3
  },
  {
    id: 'c55e3958-00c4-4fc3-9118-47d8f31bfde1',
    text: 'You consistently strive to be honest and transparent in your communication, even when it might be challenging.',
    keyed: 'plus',
    domain: 'C',
    facet: 3
  },
  {
    id: 'acd8fadc-5399-4a67-b5ff-9d1ada049c01',
    text: 'You often feel uncomfortable or anxious about standing out or drawing attention to yourself in social or professional situations',
    keyed: 'plus',
    domain: 'N',
    facet: 4
  },
  {
    id: 'd07b6c67-0d02-4948-a997-bb84ac234cd8',
    text: 'You frequently find yourself engaged in various activities and moving from one task to another, rarely having downtime',
    keyed: 'plus',
    domain: 'E',
    facet: 4
  },
  {
    id: '33b81fd0-7e32-4cd8-a13a-d5f5f754f998',
    text: 'You tend to stick with familiar tasks or routines rather than exploring new or unfamiliar activities.',
    keyed: 'minus',
    domain: 'O',
    facet: 4
  },
  {
    id: 'd9a9a180-29c9-4ec5-8621-2256d411def7',
    text: 'In moments of frustration or high stress, you sometimes raise your voice or express your displeasure strongly with others.',
    keyed: 'minus',
    domain: 'A',
    facet: 4
  },
  {
    id: 'f12c3d9d-1d12-4aa6-ad2e-009cd0651cbb',
    text: "You often go beyond the standard requirements of a task or responsibility, putting in extra effort to exceed expectations",
    keyed: 'plus',
    domain: 'C',
    facet: 4
  },
  {
    id: '9891b7ba-a494-4307-aafe-301d8db506c6',
    text: 'You usually avoid excessive indulgence and maintain moderation in your habits and choices.',
    keyed: 'minus',
    domain: 'N',
    facet: 5
  },
  {
    id: 'f1675af6-88bf-4376-a946-0281e762b39c',
    text: 'You actively look for new and exciting experiences, often seeking out opportunities for adventure and exploration.',
    keyed: 'plus',
    domain: 'E',
    facet: 5
  },
  {
    id: '95a3f20c-f933-4d19-a2c1-a7dbdf63c562',
    text: 'You tend to steer clear of deep or abstract discussions and prefer to focus on more practical or straightforward topics.',
    keyed: 'minus',
    domain: 'O',
    facet: 5
  },
  {
    id: '7df44711-4cd4-4b05-8830-73fcc3ebdab5',
    text: 'You sometimes find yourself overestimating your own abilities and achievements',
    keyed: 'minus',
    domain: 'A',
    facet: 5
  },
  {
    id: '9d3cb5c7-955c-43a4-b6c7-b07ed01dcbd9',
    text: 'You consistently follow through with your plans and take the necessary steps to achieve your goals.',
    keyed: 'plus',
    domain: 'C',
    facet: 5
  },
  {
    id: '13c58810-3864-42ba-aa87-d4166f858756',
    text: 'You often find yourself feeling overwhelmed or stressed by unexpected events or changes in your circumstances.',
    keyed: 'plus',
    domain: 'N',
    facet: 6
  },
  {
    id: '961376e0-16a1-4c14-b059-789e63d11b63',
    text: 'You frequently engage in activities that bring you joy and enjoyment, making sure to have fun in your daily life.',
    keyed: 'plus',
    domain: 'E',
    facet: 6
  },
  {
    id: 'f08e1b27-3673-4898-9cae-896482d0d9f9',
    text: 'You tend to view moral and ethical issues as subjective, believing that there are no absolute standards of right and wrong',
    keyed: 'plus',
    domain: 'O',
    facet: 6
  },
  {
    id: 'c2038c12-7a37-47a8-9983-831bd6692aab',
    text: 'You often feel a deep sense of compassion and empathy for individuals who are experiencing more difficult circumstances than your own.',
    keyed: 'plus',
    domain: 'A',
    facet: 6
  },
  {
    id: '956f3e17-ff17-4af5-a52f-9222b8968106',
    text: 'You make quick decisions without fully considering the potential consequences.',
    keyed: 'minus',
    domain: 'C',
    facet: 6
  },
  {
    id: '4d81238b-5407-47d4-88e5-dc0e38aa14f5',
    text: 'You often feel anxious or fearful about a variety of situations or potential outcomes.',
    keyed: 'plus',
    domain: 'N',
    facet: 1
  },
  {
    id: '9f9166f0-fa94-4c14-a91d-3eecd8395794',
    text: 'You often find yourself avoiding interactions and keeping your distance from others.',
    keyed: 'minus',
    domain: 'E',
    facet: 1
  },
  {
    id: '23a1034f-fab7-4887-a66e-5ef4eaafb25e',
    text: 'You frequently find yourself lost in thought, imagining different scenarios or ideas.',
    keyed: 'plus',
    domain: 'O',
    facet: 1
  },
  {
    id: 'c63e6121-c3ed-40cc-abc2-c1e6ea1e0858',
    text: 'You generally take people at their word and believe what they say',
    keyed: 'plus',
    domain: 'A',
    facet: 1
  },
  {
    id: '02ee1930-36a7-4caa-b10c-c93efb682a44',
    text: 'You manage to handle tasks efficiently and effectively, often navigating challenges with ease.',
    keyed: 'plus',
    domain: 'C',
    facet: 1
  },
  {
    id: 'da8e6ed1-2296-4c58-8fdb-66f2f591989b',
    text: 'You sometimes find yourself losing your temper quickly in frustrating or challenging situations.',
    keyed: 'plus',
    domain: 'N',
    facet: 2
  },
  {
    id: '03c10b30-b88f-4c63-8acc-71251ca24615',
    text: 'You often choose to spend time alone rather than seeking out social interactions.',
    keyed: 'minus',
    domain: 'E',
    facet: 2
  },
  {
    id: '751a04bc-5adf-485a-8ea4-4308406ae85b',
    text: 'You typically do not enjoy reading or engaging with poetry.',
    keyed: 'minus',
    domain: 'O',
    facet: 2
  },
  {
    id: '982e83c2-d34e-48da-9c71-78494ab05c85',
    text: 'You sometimes use others to achieve your own goals or gain an advantage',
    keyed: 'minus',
    domain: 'A',
    facet: 2
  },
  {
    id: 'f4891687-0ff0-47af-a4f6-d1202c8f6676',
    text: 'You often leave items scattered and do not consistently maintain order in your personal space.',
    keyed: 'minus',
    domain: 'C',
    facet: 2
  },
  {
    id: '743d8973-1de1-4485-91b4-8a5cf63e7d44',
    text: 'You frequently experience periods of low mood or sadness, even when there isn’t an obvious reason for it.',
    keyed: 'plus',
    domain: 'N',
    facet: 3
  },
  {
    id: '2452f034-8273-4f71-9122-a40f5ead31ba',
    text: 'You often take the initiative to organize and direct situations or projects to ensure they progress as intended.',
    keyed: 'plus',
    domain: 'E',
    facet: 3
  },
  {
    id: '2a300001-6e05-4c79-b8b5-2ccae4c3d463',
    text: 'You often overlook or are unaware of your own emotional responses in various situations.',
    keyed: 'minus',
    domain: 'O',
    facet: 3
  },
  {
    id: 'cd54bd76-ca9c-4030-b325-bb8d896bcb3f',
    text: 'You sometimes find yourself not fully considering or being aware of how others are feeling in different situations.',
    keyed: 'minus',
    domain: 'A',
    facet: 3
  },
  {
    id: '4e6e3a34-176f-4e6e-8730-1341611f972b',
    text: 'You are able to disregard or bend established rules or guidelines to achieve your objectives.',
    keyed: 'minus',
    domain: 'C',
    facet: 3
  },
  {
    id: '20062533-a33d-4c1e-9cd9-bff868015b3f',
    text: 'You generally feel most at ease and relaxed only when interacting with people you know well, such as close friends.',
    keyed: 'plus',
    domain: 'N',
    facet: 4
  },
  {
    id: 'b2a077d5-1fe0-4b06-ab63-35455e001e54',
    text: 'You stay actively engaged and productive during your spare time, often pursuing various activities or projects.',
    keyed: 'plus',
    domain: 'E',
    facet: 4
  },
  {
    id: '0d2e65ab-95d9-482f-beb4-3239a3a4944a',
    text: 'You often find it challenging to adapt to changes and prefer to stick to familiar routines and environments.',
    keyed: 'minus',
    domain: 'O',
    facet: 4
  },
  {
    id: '0de0f900-cede-4538-9c00-5da4f830b028',
    text: 'You occasionally find yourself using harsh or hurtful language when interacting with others, especially during disagreements',
    keyed: 'minus',
    domain: 'A',
    facet: 4
  },
  {
    id: 'a9c97d6b-6721-4150-8d84-64ef3082f164',
    text: 'You tend to complete the minimum requirements of a task or job, doing just enough to meet expectations without going beyond',
    keyed: 'minus',
    domain: 'C',
    facet: 4
  },
  {
    id: '9f2e7f90-0ca5-4ed0-9fe5-e060238a9b5e',
    text: 'You find it easy to stay disciplined and avoid giving in to temptations or distractions.',
    keyed: 'minus',
    domain: 'N',
    facet: 5
  },
  {
    id: '7dd6cf2d-5c14-48c2-8ae5-633a7a596c71',
    text: 'You find excitement in taking risks or engaging in spontaneous actions, even if they involve a degree of recklessness.',
    keyed: 'plus',
    domain: 'E',
    facet: 5
  },
  {
    id: 'fecc35f7-681e-4889-a404-4a973a3dfef0',
    text: 'You often struggle to grasp or relate to complex or abstract concepts and prefer more concrete information',
    keyed: 'minus',
    domain: 'O',
    facet: 5
  },
  {
    id: '1d686958-6fe7-432f-85e6-186b99e4e232',
    text: 'You often hold a strong and positive view of your own abilities and achievements',
    keyed: 'minus',
    domain: 'A',
    facet: 5
  },
  {
    id: 'c7db0ed8-df7d-49bf-942f-59e46ef743c4',
    text: 'You sometimes find yourself engaging in activities that do not contribute to your goals or productivity, leading to a sense of wasted time.',
    keyed: 'minus',
    domain: 'C',
    facet: 5
  },
  {
    id: 'b7e0e393-9b21-4e0d-adf3-8f28fb5b9d87',
    text: "You often feel overwhelmed or incapable of effectively managing tasks or challenges that come your way.",
    keyed: 'plus',
    domain: 'N',
    facet: 6
  },
  {
    id: '79d956e8-1118-402a-a0e2-9380af18243e',
    text: 'You have a strong enthusiasm and positive outlook toward life, finding joy and fulfillment in your daily experiences',
    keyed: 'plus',
    domain: 'E',
    facet: 6
  },
  {
    id: '96ba77b2-1a44-4dfd-95f9-ae4d1f714460',
    text: 'You often support or align with policies and leaders that emphasize traditional values and conservative principles',
    keyed: 'minus',
    domain: 'O',
    facet: 6
  },
  {
    id: '77f54ab4-0fba-4efb-8700-066c7490eb87',
    text: "You generally feel indifferent or detached when it comes to addressing or engaging with other people's personal issues or concerns",
    keyed: 'minus',
    domain: 'A',
    facet: 6
  },
  {
    id: 'a354cf7c-8d11-46ac-acc5-da90d2048637',
    text: 'You often make decisions or start new projects quickly, sometimes without taking the time to fully assess the situation or plan ahead',
    keyed: 'minus',
    domain: 'C',
    facet: 6
  },
  {
    id: '43b03992-3f32-4ed1-a6f8-5d6d3e7ed246',
    text: 'You frequently become stressed or anxious in response to challenging situations or unexpected changes.',
    keyed: 'plus',
    domain: 'N',
    facet: 1
  },
  {
    id: '41702602-08e4-4e2b-9a19-291d9efc581a',
    text: 'You tend to maintain a certain level of emotional or physical distance from others, avoiding deeper connections or interactions.',
    keyed: 'minus',
    domain: 'E',
    facet: 1
  },
  {
    id: '935a7413-abac-4f54-9169-d1fbd39da752',
    text: 'You enjoy immersing yourself in deep thinking or daydreaming, often spending time reflecting on various ideas and scenarios.',
    keyed: 'plus',
    domain: 'O',
    facet: 1
  },
  {
    id: '432dbde8-8756-4ff0-80d5-f47018235139',
    text: 'You often find it difficult to fully trust others and tend to be cautious or skeptical about their intentions',
    keyed: 'minus',
    domain: 'A',
    facet: 1
  },
  {
    id: '5727c93f-317b-4af1-a686-77fc9fbc5033',
    text: 'You have a clear understanding of how to effectively accomplish tasks and achieve your goals, often knowing the best approach to take',
    keyed: 'plus',
    domain: 'C',
    facet: 1
  },
  {
    id: 'd32bd062-4eb2-401b-99b2-e7afea39ca9b',
    text: 'You generally remain calm and composed, even in situations that might irritate or frustrate others.',
    keyed: 'minus',
    domain: 'N',
    facet: 2
  },
  {
    id: '9a47184f-6046-4e68-a61b-3d9b357b86ea',
    text: 'You tend to steer clear of large gatherings or crowded places, preferring quieter or more intimate settings',
    keyed: 'minus',
    domain: 'E',
    facet: 2
  },
  {
    id: '87c5b27e-59a8-4c48-8ba8-f5413d735693',
    text: 'You generally do not find visiting art museums or engaging with art exhibitions to be enjoyable or stimulating',
    keyed: 'minus',
    domain: 'O',
    facet: 2
  },
  {
    id: '11b20adb-abed-4363-894c-3dd823ae0540',
    text: "You sometimes unintentionally or intentionally interfere with or hinder the progress of others' plans or projects",
    keyed: 'minus',
    domain: 'A',
    facet: 2
  },
  {
    id: '50418d86-712c-45d9-adc4-ea0231c93cf5',
    text: 'You frequently leave your belongings in different locations rather than returning them to their proper places',
    keyed: 'minus',
    domain: 'C',
    facet: 2
  },
  {
    id: 'f40e421f-6c24-4be2-bd9f-28d33358d8c6',
    text: 'You generally feel at ease and confident in your own company, without feeling the need to seek external validation.',
    keyed: 'minus',
    domain: 'N',
    facet: 3
  },
  {
    id: '8791f37b-686f-47c3-9db7-74c009951321',
    text: 'You often prefer to follow others direction or guidance rather than taking the initiative to lead or make decisions yourself',
    keyed: 'minus',
    domain: 'E',
    facet: 3
  },
  {
    id: '4fd25155-9cc2-4cd6-8852-3e0ca2d5e95d',
    text: "You sometimes find it challenging to relate to or comprehend why others become emotional in various situations",
    keyed: 'minus',
    domain: 'O',
    facet: 3
  },
  {
    id: 'b68af20d-24f9-4c27-85cc-fe0858994888',
    text: 'You often find yourself too preoccupied with your own tasks and responsibilities to make time for interacting or supporting others',
    keyed: 'minus',
    domain: 'A',
    facet: 3
  },
  {
    id: '54423933-0ebb-44a7-bdd9-2a9b100c70f2',
    text: 'You occasionally fail to follow through on commitments or promises you have made to others',
    keyed: 'minus',
    domain: 'C',
    facet: 3
  },
  {
    id: '7317848c-3e1b-422f-bb16-02efc504f677',
    text: 'You remain unaffected and comfortable even in challenging or uncomfortable social situations.',
    keyed: 'minus',
    domain: 'N',
    facet: 4
  },
  {
    id: '7d93e1ca-46e8-4a30-9623-42a80c9b420c',
    text: 'You prefer to approach tasks and activities at a relaxed pace, avoiding unnecessary stress or urgency.',
    keyed: 'minus',
    domain: 'E',
    facet: 4
  },
  {
    id: 'a7f43928-8982-4ed5-8656-7a80346fe979',
    text: 'You tend to stick with traditional methods and practices rather than exploring new or unconventional approaches.',
    keyed: 'minus',
    domain: 'O',
    facet: 4
  },
  {
    id: '17910a55-a64a-4ed0-8b46-293e2fa2fe03',
    text: 'You sometimes find yourself seeking revenge or trying to get even with others when you feel wronged',
    keyed: 'minus',
    domain: 'A',
    facet: 4
  },
  {
    id: '3890bb43-2695-4b8d-b289-ee10d11cc884',
    text: 'You typically invest only a minimal amount of time and effort into your work, often doing just the bare minimum required.',
    keyed: 'minus',
    domain: 'C',
    facet: 4
  },
  {
    id: '49a85680-53aa-4208-86b5-dccc7a6f8e37',
    text: 'You are effective at managing and resisting cravings or desires, maintaining control over your impulses.',
    keyed: 'minus',
    domain: 'N',
    facet: 5
  },
  {
    id: '10f90fa9-649c-4631-ac4c-3dd3f751597d',
    text: 'You sometimes engage in spontaneous or unconventional behavior, embracing a sense of unpredictability and excitement',
    keyed: 'plus',
    domain: 'E',
    facet: 5
  },
  {
    id: 'b86de003-c3c4-4cc8-9385-5ac8a0142c34',
    text: 'You generally prefer to avoid discussions that are abstract or theoretical, favoring more practical or concrete topics instead.',
    keyed: 'minus',
    domain: 'O',
    facet: 5
  },
  {
    id: '80c1d149-7050-481a-9953-aefb441642e7',
    text: 'You occasionally find yourself highlighting or drawing attention to your own strengths and positive qualities in conversations',
    keyed: 'minus',
    domain: 'A',
    facet: 5
  },
  {
    id: '51403620-968c-42fa-a772-65ba5ad8396f',
    text: 'You often struggle to initiate tasks or projects, finding it challenging to begin work on new responsibilities.',
    keyed: 'minus',
    domain: 'C',
    facet: 5
  },
  {
    id: '88a3c2fe-3aa4-4f46-9322-da656332268a',
    text: 'You are able to stay composed and maintain your focus even in high-pressure or stressful situations',
    keyed: 'minus',
    domain: 'N',
    facet: 6
  },
  {
    id: 'e7b31bdc-5f6b-40ec-ba91-f5919b0f170e',
    text: 'You tend to focus on the positive aspects of situations and maintain an optimistic outlook, even when faced with challenges',
    keyed: 'plus',
    domain: 'E',
    facet: 6
  },
  {
    id: '580b08d1-3c94-46e9-9d07-d6d80c698127',
    text: 'You support policies and approaches that advocate for strict measures and strong responses to criminal behavior',
    keyed: 'minus',
    domain: 'O',
    facet: 6
  },
  {
    id: '48bee420-60c0-45cd-be43-3893dbc1969a',
    text: 'You often find yourself avoiding thoughts or concerns about individuals in need or those facing hardships',
    keyed: 'minus',
    domain: 'A',
    facet: 6
  },
  {
    id: 'ea3327ea-3529-4be4-8e2d-2174731ae4d7',
    text: 'You make decisions or take actions spontaneously, without fully considering the potential consequence',
    keyed: 'minus',
    domain: 'C',
    facet: 6
  }
]
  
  export default TheBigFiveQuestions