import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, useFormik } from "formik";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { uploadData } from 'aws-amplify/storage';
import { updateUserAttribute } from "aws-amplify/auth";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "slices/UserSlice";
import { usePayment } from "hooks/PaymentContext";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import { useNavigate } from "react-router-dom";
import vector from "assets/Vector2.png";
import avatar from "assets/avatar.png";
import ArrowForward from "components/Icons/ArrowForward.svg";
import Topbar from "scenes/Layout/Topbar";
import Sidebar from "scenes/Layout/Sidebar";
import Logo from "scenes/Layout/logo";
import PlatformFAQ from "components/FAQs/PlatformFAQ";
import VirtualCoachFAQ from "components/FAQs/VirtualCoachFAQ";
import { Check, KeyboardArrowDown } from "@mui/icons-material";
import ClientFAQ from "components/FAQs/ClientFAQ";
import { useStripeCheckoutCoachingPlatform } from "hooks/useStripeCheckoutCoachingPlatform";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import { useSignOut } from "hooks/useSignOut";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";
import axios from "axios";

const Settings = ({ user, userData }) => {
  const handleSignOut = useSignOut();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openRemoveCoachFromClient, setOpenRemoveCoachFromClient] = useState(false)
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [picture, setPicture] = useState(null);
  const fileInputRef = useRef(null);
  const [coachesForUser, setCoachesForUser] = useState({});
  const [pictureChanged, setPictureChanged] = useState(false)
  const [selectedCoach, setSelectedCoach] = useState('');
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const CustomIcon = (props) => (
    <KeyboardArrowDown {...props} style={{ color: 'black' }} />
  );

  const handleSelectChange = (event) => {
    setSelectedCoach(event.target.value);
    setConfirmDisabled(event.target.value === '');
  };
  const [
    loadingStripeCheckoutCoachingPlatformPersonal,
    setLoadingStripeCheckoutCoachingPlatformPersonal,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatformPersonalAnnual,
    setLoadingStripeCheckoutCoachingPlatformPersonalAnnual,
  ] = useState(false);

  const {
    handleStripeCheckoutCoachingPlatform,
    setProductIdCoachingPlatform,
    // loadingStripeCheckoutCoachingPlatform,
    errorWithStripeCheckoutCoachingPlatform,
  } = useStripeCheckoutCoachingPlatform();

  const handleCoachingPlatformClick = async (coachingPlatformProductId) => {
    await handleStripeCheckoutCoachingPlatform(coachingPlatformProductId);
  };

  const handleCloseDeactivateModal = () => {
    setOpenDeactivateModal(false)
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleCloseRemoveCoachFromClientModal = () => {
    setSelectedCoach('');
    setOpenRemoveCoachFromClient(false);
  }
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchUserData = async () => {
    if (!user?.sub) {
      setPicture(null);
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-avatar`, {
        params: {
          user_id: user?.sub
        },
      });

      let coachesResponse = {};
      if(userData.isClient === true){
        coachesResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-coaches`, {
          params: {
            user_id: user?.sub
          }
        });
      }
  
      setCoachesForUser(coachesResponse.coaches);
      if (response.avatarKey) {
        const pictureUrl = "https://d7joen7tb0xwx.cloudfront.net/public/" + response.avatarKey;
        setPicture(pictureUrl);
      } else {
        setPicture(null); // or set to a default image
      }
    } catch (error) {
      console.error("Failed to fetch user picture:", error);
      setPicture(null); // or set to a default image
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [user, userData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setSelectedFile(file);
      setSelectedFileURL(fileURL);
      setPicture(fileURL); // Update the picture state to the selected image
    }
  };

  const initializeStripeButton = () => {
    // Ensure the container element exists before manipulating its children
    const container = document.getElementById("stripe-button-container");
    if (container) {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }

      const stripeBuyButton = document.createElement("stripe-buy-button");
      stripeBuyButton.setAttribute(
        "buy-button-id",
        "buy_btn_1OPUqNLKxpQPkYt58OxWqnpz"
      );
      stripeBuyButton.setAttribute(
        "publishable-key",
        "pk_live_FzD1wPAz3grYLKCC40GnQZNk00sDrNzpuN"
      );
      container.appendChild(stripeBuyButton);
    }
  };

  const removeCallData = useCallback(async () => {
    await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/deauth`,{}, {
      params: {
        coach_id: user?.sub,
      },
    });

    // Open a new tab with the Zoom Marketplace link
    const newTab = window.open(
      "https://marketplace.zoom.us/apps/OOPlcbPIRKOA69ss96IJ5A",
      "_blank"
    );
    if (newTab) {
      newTab.focus();
    } else {
      console.error("Failed to open new tab.");
    }

    setOpenRemoveModal(false);
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: user?.given_name,
      lastName: user?.family_name,
      email: user?.email,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const s3Key = `public/avatar/${uuid()}-${selectedFile.name}`;
        if (selectedFile && user) {
          if (user?.["custom:is_coachee"] === "true") {
            await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-client-avatar`, {
              params: {
                client_id: user?.sub,
                s3Key: s3Key,
              },
            });
          } else {
            await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-coach-avatar`, {
              params: {
                coach_id: user?.sub,
                s3Key: s3Key,
              },
            });
          }
          // await Storage.put(s3Key, selectedFile, {
          //   contentType: "image/",
          //   level: "public",
          // });

          const storeProfilePicture = await uploadData({
            path: s3Key,
            data: selectedFile,
            options: {
              bucket: {
                bucketName: 'amplify-reactadmin-admin--profilepicturesbucket46b-gfsfrqjifprr',
                region: 'us-east-1'
              },
              contentType: selectedFile.type,
              level: 'public'
            }
          });
          if(storeProfilePicture){
            console.log('Profile Picture Uploaded Successfully');
          }
        }
        await updateUserAttribute({
          userAttribute: {
            attributeKey: "custom:is_coachee",
            value: "true"
          },
        });
      } catch (error) {
        console.log("error submitting changes", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleDeactivate = async () => {
    try {
      setOpenDeactivateModal(false);
      await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/deactivate-account`, {
        params : {
          user_id: user?.sub,
          stripe_customer_id: userData.paymentInfo.stripeCustomerId
        },
      });
      handleSignOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleDelete = async () => {
    try {
      setOpenDeactivateModal(false);
      await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-account`, {
        params: {
          user_id: user?.sub,
          stripe_customer_id: userData.paymentInfo.stripeCustomerId
        },
      });
      navigate("/sign-in");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleRemoveCoachFromClient = async (coach_id) => {
    try {
      setOpenRemoveCoachFromClient(false);
      
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/remove-coach-from-client`, {
        params: {
          user_id: user?.sub,
          coach_id: coach_id
        }
      });
      navigate("/authenticating");
    } catch (error) {
      console.error("Error removing client from coach:", error);
    }
  }; 

  const padding = userData.accessTo === 'virtual_coach' ? "0px 40px 32px" : "32px 40px";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#EFF3F6",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <Topbar user={user} pictureChanged={pictureChanged} />
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {userData.accessTo === 'platform' || userData.accessTo === 'platform_professional' || userData.accessTo === 'client_portal' ? (
            <Sidebar accessTo={userData.accessTo}/>
          ) : null}
          <Box
            sx={{
              backgroundColor: "#EFF3F6",
              width: "100%",
              padding: {
                xs: "30px 16px",
                md: padding,
              },
              display: {
                xs: !collapsed ? "none" : "block",
              },
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#EFF3F6",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "12px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                {userData.accessTo === 'virtual_coach' && (
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    <Logo />
                  </Box>
                )}
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "36px",
                    fontFamily: "Poppins",
                    fontWeight: 300,
                    paddingBottom: "24px",
                  }}
                >
                  Settings
                </Typography>
              </Box>
              {userData.accessTo === 'virtual_coach' && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: {
                      xs: "unset",
                      md: "200px",
                    },
                    zIndex: 1100,
                    position: "relative",
                    paddingBottom: "24px",
                    alignSelf: {
                      xs: "center",
                      md: "unset",
                    },
                  }}
                >
                  <Button
                    sx={{
                      display: " flex",
                      width: {
                        xs: "100%",
                        lg: "220px",
                      },
                      padding: "10px 8px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: " 10px",
                      borderRadius: "52px",
                      border: "1px solid black",
                      borderColor: "black",
                      background: "white",
                      opacity: 1,
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                        color: "white",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={() => navigate("/virtualcoach/bot")}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        color: "black",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        letterSpacing: "2px",
                        lineHeight: "1.5",
                        textTransform: "uppercase",
                      }}
                    >
                      Access Virtual Coach
                    </Typography>
                    {ArrowForwardBlack()}
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                display: {
                  xs: "flex",
                  md: "none",
                },
                marginBottom: "8px",
                borderRadius: "40px",
                background:
                  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "56px",
                  padding: "40px 0 40px 40px",
                  boxSizing: "content-box",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "22px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "300",
                      color: "white",
                    }}
                  >
                    Need Support?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "1.5",
                      letterSpacing: "0.3px",
                    }}
                  >
                    Reach out to our support team by submitting a form.
                  </Typography>
                </Box>
                <Button
                  sx={{
                    display: " flex",
                    width: {
                      xs: "100%",
                      md: "218px",
                    },
                    paddingX: "20px",
                    paddingY: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "43px",
                    background: "white",
                    transition: "all 0.3s",
                    "&:hover": {
                      cursor: "pointer",
                      background: "black",
                      borderColor: "black",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                  }}
                  onClick={() => navigate("/support")}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      color: "black",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      letterSpacing: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    Contact Support
                  </Typography>
                </Button>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 145px)",
                  marginTop: "5px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  component="img"
                  src={vector}
                  alt="vector"
                  width={{ xs: "380px" }}
                  sx={{
                    justifyContent: "end",
                    position: "absolute",
                    marginLeft: "450px",
                    bottom: -5,
                    zIndex: "0",
                    display: {xs:"none", md:"inline"}
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                padding: "30px",
                background: "#EFF3F6",
                borderRadius: "40px",
              }}
            >
              <Grid container spacing={5}>
                {/* Left Side - Form */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    padding: "30px",
                    background: "white",
                    borderRadius: "40px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "56px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "22px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "32px",
                          fontWeight: "300",
                          color: "black",
                        }}
                      >
                        Information
                      </Typography>
                      <form onSubmit={formik.handleSubmit}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "22px",
                            maxWidth: {
                              md: "340px",
                            },
                            marginLeft: { xs: "0px", md: "25px" },
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {/* Profile Picture Upload */}
                              <label htmlFor="raised-button-file">
                                <Box
                                  sx={{
                                    background:
                                      "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                    padding: "3px",
                                    borderRadius: "50%",
                                    width: "100px",
                                    height: "100px",
                                    marginBottom: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      background: "white",
                                      padding: "4px",
                                      borderRadius: "50%",
                                      width: "94px",
                                      height: "94px",
                                    }}
                                  >
                                    <Avatar
                                      sx={{
                                        width: "86px",
                                        height: "86px",
                                      }}
                                      src={picture || avatar}
                                    />
                                  </Box>
                                </Box>
                              </label>
                              <Box
                                sx={{
                                  background:
                                    "linear-gradient(315deg, #D8659B 0%, #03093A 60%, #242F8C 100%)",
                                  padding: "1px",
                                  borderRadius: "43px",
                                  cursor: "pointer",
                                }}
                              >
                                <Button
                                  sx={{
                                    borderRadius: "43px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "white",
                                    color: "black",
                                    paddingY: "10px",
                                    paddingX: "20px",
                                    transition: "all 0.3s",
                                    "&:hover": {
                                      background: "#F5F5F5",
                                    },
                                  }}
                                  onClick={() => fileInputRef.current.click()}
                                >
                                  <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={handleImageChange}
                                    ref={fileInputRef}
                                  />
                                  <Typography
                                    sx={{
                                      fontFamily: "Lexend",
                                      fontSize: "10px",
                                      letterSpacing: "2px",
                                      lineHeight: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Add a profile photo
                                  </Typography>
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontWeight: "bold",
                                fontSize: "12px",
                                lineHeight: "150%",
                                letterSpacing: "2px",
                                color: "black",
                                textTransform: "uppercase",
                              }}
                            >
                              First Name
                            </Typography>
                            <TextField
                              name="firstName"
                              required
                              fullWidth
                              value={formik.values.firstName}
                              {...formik.getFieldProps("firstName")}
                              id="firstName"
                              autoFocus
                              InputProps={{
                                style: {
                                  color: "black",
                                  borderRadius: "40px",
                                  backgroundColor: "transparent",
                                  "&:WebkitAutofill": {
                                    "-webkit-box-shadow":
                                      "0 0 0 100px transparent inset",
                                    "-webkit-text-fill-color": "black",
                                  },
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "black",
                                },
                              }}
                              sx={{
                                "& fieldset": {
                                  borderColor: "black !important",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontWeight: "bold",
                                fontSize: "12px",
                                lineHeight: "150%",
                                letterSpacing: "2px",
                                color: "black",
                                textTransform: "uppercase",
                              }}
                            >
                              Last Name
                            </Typography>
                            <TextField
                              name="lastName"
                              required
                              fullWidth
                              value={formik.values.lastName}
                              {...formik.getFieldProps("lastName")}
                              id="lastName"
                              InputProps={{
                                style: {
                                  color: "black",
                                  borderRadius: "40px",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "black",
                                },
                              }}
                              sx={{
                                "& fieldset": {
                                  borderColor: "black !important",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontWeight: "bold",
                                fontSize: "12px",
                                lineHeight: "150%",
                                letterSpacing: "2px",
                                color: "black",
                                textTransform: "uppercase",
                              }}
                            >
                              Email
                            </Typography>
                            <TextField
                              name="email"
                              required
                              fullWidth
                              value={formik.values.firstName}
                              {...formik.getFieldProps("email")}
                              id="email"
                              InputProps={{
                                readOnly: true,
                                style: {
                                  color: "black",
                                  backgroundColor: "transparent",
                                  borderRadius: "40px",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "black",
                                },
                              }}
                              sx={{
                                "& fieldset": {
                                  borderColor: "black !important",
                                },
                              }}
                            />
                          </Box>
                          <Box>
                            <Button
                              type="submit"
                              sx={{
                                paddingX: "30px",
                                paddingY: "10px",
                                display: "flex",
                                color: "white",
                                background: "#50B500",
                                borderRadius: "43px",
                                transition: "all 0.3s",
                                "&:hover": {
                                  outline: "none",
                                  background: "rgba(80, 181, 0, 0.4)",
                                  boxShadow: "none",
                                },
                                boxShadow: "none",
                                width: {
                                  xs: "100%",
                                  md: "unset",
                                },
                              }}
                            >
                              {loading ? (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: "white",
                                  }}
                                />
                              ) : (
                                <>
                                  <Typography
                                    sx={{
                                      fontFamily: "Lexend",
                                      fontWeight: "bold",
                                      fontSize: "10px",
                                      letterSpacing: "2px",
                                      lineHeight: "150%",
                                    }}
                                  >
                                    Save
                                  </Typography>
                                </>
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </form>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "22px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "32px",
                            fontWeight: "300",
                            color: "black",
                          }}
                        >
                          {userData.accessTo === 'client_portal' 
                          ? 'Upgrade'
                          : 'Billing'
                          }
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            color: "black",
                            marginLeft: { xs: "0px", md: "25px" },
                          }}
                        >
                          {userData.accessTo === 'client_portal' 
                          ? 'Upgrade to Professional tier and gain valuable insights on your own calls while continuing your journey with your coach.'
                          : 'We partner with Stripe to manage billing. Please proceed to the link below.'
                          }
                        </Typography>
                      </Box>
                      {userData.accessTo === 'client_portal' ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px"
                        }}
                      >  
                        <Button
                          sx={{
                            display: "flex",
                            width: {
                              xs: "100%",
                              md: "50%",
                            },
                            paddingX: "20px",
                            paddingY: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "43px",
                            background:
                              "linear-gradient(135deg, #070707 0%, #666666 100%)",
                            transition: "all 0.3s",
                            "&:hover": {
                              cursor: "pointer",
                              background:
                                "linear-gradient(135deg, rgba(7, 7, 7, 0.8) 0%, rgba(102, 102, 102, 0.8) 100%)",
                            },
                            marginLeft: { xs: "0px", md: "25px" },
                          }}
                          onClick={() => {
                            setLoadingStripeCheckoutCoachingPlatformPersonal(true)
                            handleCoachingPlatformClick("price_1OyblKLKxpQPkYt5aHNWNzzc");
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "white",
                              fontSize: "10px",
                              fontStyle: "normal",
                              lineHeight: "150%",
                              fontWeight: "bold",
                              letterSpacing: "2px",
                              textTransform: "uppercase",
                            }}
                          >
                            {loadingStripeCheckoutCoachingPlatformPersonal 
                            ? <CircularProgress size={16} sx={{ color: "white" }} />
                            : 'Monthly Rate'
                            }
                          </Typography>
                        </Button>
                        <Button
                        sx={{
                          display: "flex",
                          width: {
                            xs: "100%",
                            md: "50%",
                          },
                          paddingX: "20px",
                          paddingY: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "43px",
                          background:
                            "linear-gradient(135deg, #070707 0%, #666666 100%)",
                          transition: "all 0.3s",
                          "&:hover": {
                            cursor: "pointer",
                            background:
                              "linear-gradient(135deg, rgba(7, 7, 7, 0.8) 0%, rgba(102, 102, 102, 0.8) 100%)",
                          },
                          marginLeft: { xs: "0px", md: "25px" },
                        }}
                        onClick={() => {
                          setLoadingStripeCheckoutCoachingPlatformPersonalAnnual(true)
                          handleCoachingPlatformClick("price_1PE2jFLKxpQPkYt5LyraVYUZ");
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            color: "white",
                            fontSize: "10px",
                            fontStyle: "normal",
                            lineHeight: "150%",
                            fontWeight: "bold",
                            letterSpacing: "2px",
                            textTransform: "uppercase",
                          }}
                        >
                          {loadingStripeCheckoutCoachingPlatformPersonalAnnual 
                          ? <CircularProgress size={16} sx={{ color: "white" }} />
                          : 'Annual Rate'
                          }
                        </Typography>
                      </Button>
                    </Box>
                      ) : (
                        <>
                        <a
                        href="https://billing.stripe.com/p/login/dR6eWJ2bkfuobja000"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Button
                          sx={{
                            display: "flex",
                            width: {
                              xs: "100%",
                              md: "unset",
                            },
                            paddingX: "20px",
                            paddingY: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "43px",
                            background:
                              "linear-gradient(135deg, #070707 0%, #666666 100%)",
                            transition: "all 0.3s",
                            "&:hover": {
                              cursor: "pointer",
                              background:
                                "linear-gradient(135deg, rgba(7, 7, 7, 0.8) 0%, rgba(102, 102, 102, 0.8) 100%)",
                            },
                            marginLeft: { xs: "0px", md: "25px" },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "white",
                              fontSize: "10px",
                              fontStyle: "normal",
                              lineHeight: "150%",
                              fontWeight: "bold",
                              letterSpacing: "2px",
                              textTransform: "uppercase",
                            }}
                          >
                            Manage My Subscription
                          </Typography>
                        </Button>
                      </a>
                      </>
                      )}
                    </Box>


                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "22px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "32px",
                          fontWeight: "300",
                          color: "black",
                        }}
                      >
                        Documentation
                      </Typography>
                      <Button
                        sx={{
                          display: " flex",
                          width: {
                            xs: "100%",
                            md: "220px",
                          },
                          padding: "10px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: " 10px",
                          borderRadius: "52px",
                          border: "1px solid black",
                          borderColor: "black",
                          background: "white",
                          marginTop: "15px",
                          marginLeft: { xs: "0px", md: "25px" },
                          opacity: 1,
                          transition: "all 0.3s",
                          "&:hover": {
                            cursor: "pointer",
                            background: "black",
                            borderColor: "black",
                          },
                          "&:hover *": {
                            color: "white",
                          },
                          "&:hover svg *": {
                            stroke: "white",
                          },
                        }}
                        onClick={() => navigate("/privacy-policy")}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            color: "black",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            letterSpacing: "2px",
                            lineHeight: "1.5",
                            textTransform: "uppercase",
                          }}
                        >
                          Privacy Policy
                        </Typography>
                        {ArrowForwardBlack()}
                      </Button>
                      <Button
                        sx={{
                          display: " flex",
                          width: {
                            xs: "100%",
                            md: "220px",
                          },
                          padding: "10px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: " 10px",
                          borderRadius: "52px",
                          border: "1px solid black",
                          borderColor: "black",
                          background: "white",
                          marginTop: "15px",
                          marginLeft: { xs: "0px", md: "25px" },
                          opacity: 1,
                          transition: "all 0.3s",
                          "&:hover": {
                            cursor: "pointer",
                            background: "black",
                            borderColor: "black",
                          },
                          "&:hover *": {
                            color: "white",
                          },
                          "&:hover svg *": {
                            stroke: "white",
                          },
                        }}
                        onClick={() => navigate("/terms-login")}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            color: "black",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            letterSpacing: "2px",
                            lineHeight: "1.5",
                            textTransform: "uppercase",
                          }}
                        >
                          Terms of use
                        </Typography>
                        {ArrowForwardBlack()}
                      </Button>

                      <Button
                        sx={{
                          display: " flex",
                          width: {
                            xs: "100%",
                            md: "220px",
                          },
                          padding: "10px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: " 10px",
                          borderRadius: "52px",
                          border: "1px solid black",
                          borderColor: "black",
                          background: "white",
                          marginTop: "15px",
                          marginLeft: { xs: "0px", md: "25px" },
                          opacity: 1,
                          transition: "all 0.3s",
                          "&:hover": {
                            cursor: "pointer",
                            background: "black",
                            borderColor: "black",
                          },
                          "&:hover *": {
                            color: "white",
                          },
                          "&:hover svg *": {
                            stroke: "white",
                          },
                        }}
                        onClick={() => navigate("/blogs")}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            color: "black",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            letterSpacing: "2px",
                            lineHeight: "1.5",
                            textTransform: "uppercase",
                          }}
                        >
                          Blogs
                        </Typography>
                        {ArrowForwardBlack()}
                      </Button>


                      <Button
                        sx={{
                          display: " flex",
                          width: {
                            xs: "100%",
                            md: "220px",
                          },
                          padding: "10px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: " 10px",
                          borderRadius: "52px",
                          border: "1px solid black",
                          borderColor: "black",
                          background: "white",
                          marginTop: "15px",
                          marginLeft: { xs: "0px", md: "25px" },
                          opacity: 1,
                          transition: "all 0.3s",
                          "&:hover": {
                            cursor: "pointer",
                            background: "black",
                            borderColor: "black",
                          },
                          "&:hover *": {
                            color: "white",
                          },
                          "&:hover svg *": {
                            stroke: "white",
                          },
                        }}
                        onClick={() => navigate("/resources")}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            color: "black",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            letterSpacing: "2px",
                            lineHeight: "1.5",
                            textTransform: "uppercase",
                          }}
                        >
                          Resources
                        </Typography>
                        {ArrowForwardBlack()}
                      </Button>



                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "22px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "32px",
                          fontWeight: "300",
                          color: "black",
                        }}
                      >
                        Account
                      </Typography>
                      {(userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            marginLeft: { xs: "0px", md: "25px" },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            Your account will no longer be connected to your
                            Zoom account.
                          </Typography>
                          <Button
                            sx={{
                              display: " flex",
                              width: {
                                xs: "100%",
                                md: "220px",
                              },
                              padding: "10px 8px",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "52px",
                              border: "1px solid #BC0000",
                              background: "transparent",
                              transition: "all 0.3s",
                              "& svg *": {
                                stroke: "#BE0808",
                              },
                              "&:hover": {
                                cursor: "pointer",
                                background: "#BC0000",
                              },
                              "&:hover *": {
                                color: "white",
                              },
                              "&:hover svg *": {
                                stroke: "white",
                              },
                            }}
                            onClick={() => setOpenRemoveModal(true)}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                color: "black",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                letterSpacing: "2px",
                                lineHeight: "1.5",
                                textTransform: "uppercase",
                              }}
                            >
                              Remove Zoom
                            </Typography>
                            {ArrowForward()}
                          </Button>
                        </Box>
                      )}
                      {user?.["custom:is_coachee"] === "true" && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            marginLeft: { xs: "0px", md: "25px" },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            You will be removed as a client for your coach.
                          </Typography>
                          <Button
                            sx={{
                              display: " flex",
                              width: {
                                xs: "100%",
                                md: "220px",
                              },
                              padding: "10px 8px",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "52px",
                              border: "1px solid #BC0000",
                              background: "transparent",
                              transition: "all 0.3s",
                              "& svg *": {
                                stroke: "#BE0808",
                              },
                              "&:hover": {
                                cursor: "pointer",
                                background: "#BC0000",
                              },
                              "&:hover *": {
                                color: "white",
                              },
                              "&:hover svg *": {
                                stroke: "white",
                              },
                            }}
                            onClick={() => setOpenRemoveCoachFromClient(true)}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                color: "black",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                letterSpacing: "2px",
                                lineHeight: "1.5",
                                textTransform: "uppercase",
                              }}
                            >
                              Remove from coach
                            </Typography>
                            {ArrowForward()}
                          </Button>
                        </Box>
                      )}
                      {userData.accessTo === 'platform' && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          marginLeft: { xs: "0px", md: "25px" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Your account will be suspended until you re-subscribe.
                        </Typography>
                        <Button
                          sx={{
                            display: " flex",
                            width: {
                              xs: "100%",
                              md: "220px",
                            },
                            padding: "10px 8px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "52px",
                            border: "1px solid #BC0000",
                            background: "transparent",
                            transition: "all 0.3s",
                            "& svg *": {
                              stroke: "#BE0808",
                            },
                            "&:hover": {
                              cursor: "pointer",
                              background: "#BC0000",
                            },
                            "&:hover *": {
                              color: "white",
                            },
                            "&:hover svg *": {
                              stroke: "white",
                            },
                          }}
                          onClick={() => setOpenDeactivateModal(true)}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "black",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              letterSpacing: "2px",
                              lineHeight: "1.5",
                              textTransform: "uppercase",
                            }}
                          >
                            Deactivate Account
                          </Typography>
                          {ArrowForward()}
                        </Button>
                      </Box>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          marginLeft: { xs: "0px", md: "25px" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Your account will be deleted.
                        </Typography>
                        <Button
                          sx={{
                            display: " flex",
                            width: {
                              xs: "100%",
                              md: "220px",
                            },
                            padding: "10px 8px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "52px",
                            border: "1px solid #BC0000",
                            background: "transparent",
                            transition: "all 0.3s",
                            "& svg *": {
                              stroke: "#BE0808",
                            },
                            "&:hover": {
                              cursor: "pointer",
                              background: "#BC0000",
                            },
                            "&:hover *": {
                              color: "white",
                            },
                            "&:hover svg *": {
                              stroke: "white",
                            },
                          }}
                          onClick={() => setOpenDeleteModal(true)}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "black",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              letterSpacing: "2px",
                              lineHeight: "1.5",
                              textTransform: "uppercase",
                            }}
                          >
                            Delete Account
                          </Typography>
                          {ArrowForward()}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* Right Side - Stripe Button */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    padding: "30px",
                    background: "transparent",
                    borderRadius: "40px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "56px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        marginTop: { lg: "-40px", md: "-40px" },
                        borderRadius: "40px",
                        display: {
                          xs: "none",
                          md: "flex",
                        },
                        background:
                          "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "56px",
                          maxWidth: "245px",
                          padding: "40px 0 40px 40px",
                          boxSizing: "content-box",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "22px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "32px",
                              fontWeight: "300",
                              color: "white",
                            }}
                          >
                            Need Support?
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "1.5",
                              letterSpacing: "0.3px",
                            }}
                          >
                            Reach out to our support team by submitting a form.
                          </Typography>
                        </Box>
                        <Button
                          sx={{
                            display: " flex",
                            paddingX: "20px",
                            paddingY: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "43px",
                            background: "white",
                            transition: "all 0.3s",
                            "&:hover": {
                              cursor: "pointer",
                              background: "black",
                              borderColor: "black",
                            },
                            "&:hover *": {
                              color: "white",
                            },
                            "&:hover svg *": {
                              stroke: "white",
                            },
                          }}
                          onClick={() => navigate("/support")}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "black",
                              fontSize: "10px",
                              fontStyle: "normal",
                              lineHeight: "150%",
                              fontWeight: "bold",
                              letterSpacing: "2px",
                              textTransform: "uppercase",
                            }}
                          >
                            Contact Support
                          </Typography>
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          width: "calc(100% - 145px)",
                          marginTop: "5px",
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          component="img"
                          src={vector}
                          alt="vector"
                          width={{ xs: "100%", lg: "240px" }}
                          style={{
                            transform: "scale(1.5)",
                          }}
                          sx={{
                            position: "absolute",
                            marginLeft: "40px"
                          }}
                        />
                      </Box>
                    </Box>

                    {(userData.accessTo === "platform" || userData.accessTo === "platform_professional") &&
                     (<PlatformFAQ accessTo={userData.accessTo}/>)
                     }
                    {(userData.accessTo === "client_portal" || userData.is_client === true) && (
                      <ClientFAQ />
                    )}
                    {userData.accessTo === "virtual_coach" &&  (
                      <VirtualCoachFAQ />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Modal
              open={openRemoveModal}
              onClose={() => setOpenRemoveModal(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  background: "white",
                  border: "2px solid white",
                  boxShadow: 24,
                  padding: "20px 20px 10px",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "20px",
                  margin: "0 auto",
                  maxWidth: "calc(100% - 40px)",
                }}
              >
                <Box>
                  <Typography color="black" fontWeight="400">
                    {`This will delete your calls permanently!`}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-around">
                  <Button
                    sx={{
                      width: "150px",
                      borderRadius: "8px",
                      color: "white",
                      background: "#160042",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      "&:hover": {
                        background: "#10B02A",
                      },
                    }}
                    onClick={removeCallData}
                  >
                    <Check />
                    Yes
                  </Button>
                  <Button
                    sx={{
                      width: "150px",
                      borderRadius: "8px",
                      background: "transparent",
                      color: "#7D7D7D",
                      border: "1px solid #E4E6F1",
                      fontWeight: "bold",
                      "&:hover": {
                        borderColor: "#B52100",
                        color: "#C51919",
                      },
                    }}
                    onClick={() => setOpenRemoveModal(false)}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={openRemoveCoachFromClient}
              onClose={handleCloseRemoveCoachFromClientModal}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  background: "white",
                  border: "2px solid white",
                  boxShadow: 24,
                  padding: "20px 20px 10px",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "20px",
                  margin: "0 auto",
                  maxWidth: "calc(100% - 40px)",
                }}
              >
                <Box>
                  <Typography color="black" fontWeight="400">
                    {`Select the coach you would like to remove. All data with the selected coach will be removed.`}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <Select
                    labelId="select-coach-label"
                    value={selectedCoach}
                    onChange={handleSelectChange}
                    IconComponent={CustomIcon}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: 'white',
                          },
                        },
                      }}
                      sx={{
                        color: "black",
                        background: "white",
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                  >
                    {coachesForUser && coachesForUser.length > 0 && coachesForUser.map((coach) => (
  <MenuItem key={coach.user_id} value={coach.user_id} sx={{color: "black"}}>
    {coach.full_name}
  </MenuItem>
))}
                  </Select>
                </FormControl>
                <Box display="flex" justifyContent="center" gap="15px">
                  <NegativeActionButton 
                    onClick={handleCloseRemoveCoachFromClientModal}
                    label={"Cancel"}
                  />
                  <PositiveActionButton 
                    onClick={() => handleRemoveCoachFromClient(selectedCoach)}
                    label="Confirm"
                    disabled={confirmDisabled}
                  />
                </Box>
              </Box>
            </Modal>
            <Modal
              open={openDeactivateModal}
              onClose={() => setOpenDeactivateModal(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  background: "white",
                  border: "2px solid white",
                  boxShadow: 24,
                  padding: "20px 20px 10px",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "20px",
                  margin: "0 auto",
                  maxWidth: "calc(100% - 40px)",
                }}
              >
                <Box>
                  <Typography color="black" fontWeight="400">
                  Are you sure? Your subscription will end at the end of the current billing period.
                  <br/>
                  You will lose access to the subscription platform at that time.
                  <br/>
                  Any clients will lose access to the client portal.
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" gap="15px">
                  <DeclineButton 
                    onClick={handleCloseDeactivateModal}
                    label={"No"}
                  />
                  <ConfirmButton 
                    onClick={handleDeactivate}
                    label={"Yes"}
                  />
                </Box>
              </Box>
            </Modal>
            <Modal
              open={openDeleteModal}
              onClose={() => setOpenDeleteModal(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  background: "white",
                  border: "2px solid white",
                  boxShadow: 24,
                  padding: "20px 20px 10px",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "20px",
                  margin: "0 auto",
                  maxWidth: "calc(100% - 40px)",
                }}
              >
                <Box>
                  <Typography color="black" fontWeight="400">
                    
                       Are you sure? 
                       <br/>
                       Your account will be deleted immediately and your subscription will be cancelled immediately.
                       <br/>
                       Related data will be kept for 7 days.
                       <br/>
                       Any clients will lose access to the client portal.

                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" gap="15px">
                <DeclineButton 
                    onClick={handleCloseDeleteModal}
                    label={"No"}
                  />
                  <ConfirmButton 
                    onClick={handleDelete}
                    label={"Yes"}
                  />
                </Box>
              </Box>
            </Modal>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
